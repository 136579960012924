import React, { PropsWithChildren, ReactElement, ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { ProfileContainer, useStyles as useDefaultStyles } from '_core/components/DefaultProfileSummary'
import { Wide, Narrow } from '_core/components/layout'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const companyBaseUrl = Paths._companies

const useStyles = makeStyles<{ flip?: boolean } | void>()((theme, props) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: props?.flip ? 'row-reverse' : 'row',
    margin: '0px auto',
    maxWidth: '90%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('phone')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start',
      marginTop: 0,
      position: 'relative',
      minWidth: '32%',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.5px',
        height: '100%',
        background: '#ECEEF0',
        left: props?.flip ? 'inherit' : 100,
        right: props?.flip ? 100 : 'inherit'
      }
    }
  },
  container: {
    marginLeft: props?.flip ? 0 : theme.spacing(3),
    marginRight: props?.flip ? theme.spacing(3) : 0,
    maxWidth: `calc(100% - 80px - ${theme.spacing(3)})`,
    '& > p': {
      paddingTop: '4px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: props?.flip ? 0 : theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      marginLeft: props?.flip ? 0 : theme.spacing(5)
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'start'
    }
  },
  action: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('phone')]: {
      marginRight: theme.spacing(4)
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3)
    },
    '&:last-of-type': {
      marginRight: 0
    }
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px',
    minWidth: '16px'
  },
  line: {
    maxWidth: '100%'
  },
  line2: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '230px'
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 14,
    width: '100%'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    width: 'fit-content',
    maxWidth: '100%'
  }
}))

export const MDByLine = ({ marketData, locationName }: { marketData: boolean; locationName?: string }) => {
  const {
    classes: { titleWrapper, byLineIcon, line }
  } = useStyles()

  return (
    <>
      {(!marketData || (marketData && locationName)) && (
        <Skeleton condition={!marketData}>
          <Tooltip title={locationName}>
            <Box className={titleWrapper}>
              <FontAwesomeIcon icon={['fas', 'location-dot']} className={byLineIcon} />
              <Typography className={line} noWrap>
                {locationName || 'Placeholder name'}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

export const MDCompanyByLine2 = ({
  marketData,
  industry,
  employeeCount
}: { marketData: boolean } & Pick<CompanyMarketDataType, 'employeeCount' | 'industry'>) => {
  const {
    classes: { titleWrapper, byLineIcon, line }
  } = useStyles()
  return (
    <>
      {(!marketData || industry) && (
        <Skeleton condition={!industry}>
          <Tooltip title={industry}>
            <Box className={titleWrapper}>
              <FontAwesomeIcon icon={['fas', 'industry']} className={byLineIcon} />
              <Typography className={line} noWrap>
                {industry || 'Long placeholder'}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!marketData || !!employeeCount) && (
        <Skeleton condition={!employeeCount}>
          <Tooltip title={`${employeeCount}+ employees`}>
            <Box className={titleWrapper}>
              <FontAwesomeIcon icon={['fas', 'users']} className={byLineIcon} />
              <Typography className={line} noWrap>
                {`${employeeCount}+ employees` || 'Placeholder'}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

type ByLine2Props = {
  marketData: PersonMarketDataType | null
  internalCompanyName?: string
  bestJobMatchedUrlText?: string
  bestJobTitleText?: string
  bestJobCompanyMd5?: string
  bestJobIsFormer?: boolean
  bestJobCurrentAsOf?: string
}

export const MDPersonByLine2 = ({
  marketData,
  internalCompanyName,
  bestJobMatchedUrlText,
  bestJobTitleText,
  bestJobCompanyMd5,
  bestJobIsFormer,
  bestJobCurrentAsOf
}: ByLine2Props) => {
  const { id } = useParams<{ id: string }>()
  const {
    classes: { link, byLineIcon, line, line2, titleWrapper },
    cx
  } = useStyles()
  const { jobTitle, jobCompanyName, jobLastChanged, jobCompanyWebsite } = marketData || {}

  const isSameJob =
    (bestJobMatchedUrlText && bestJobMatchedUrlText === jobCompanyWebsite) ||
    (internalCompanyName && internalCompanyName.toLowerCase() === jobCompanyName?.toLowerCase())

  const internalJobIsCurrent = bestJobIsFormer === true ? false : new Date(bestJobCurrentAsOf || 0) > new Date(jobLastChanged || 0)
  const displayedCompanyName = isSameJob ? internalCompanyName : internalJobIsCurrent ? internalCompanyName : jobCompanyName
  const displayedJobTitle = internalJobIsCurrent
    ? isSameJob
      ? bestJobTitleText || jobTitle
      : bestJobTitleText
    : isSameJob
      ? jobTitle || bestJobTitleText
      : jobTitle

  return (
    <>
      {(!marketData || displayedJobTitle) && (
        <Skeleton condition={!marketData} width={110}>
          <Tooltip title={displayedJobTitle}>
            <Box className={titleWrapper}>
              <FontAwesomeIcon icon={['fas', 'user-tie']} className={byLineIcon} />
              <Typography className={line} noWrap>
                {displayedJobTitle}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!marketData ||
        (!isSameJob && !internalJobIsCurrent && jobCompanyName) ||
        ((internalJobIsCurrent || isSameJob) && internalCompanyName && bestJobCompanyMd5)) && (
        <Skeleton condition={!marketData} classes={{ root: line2 }}>
          <Tooltip title={displayedCompanyName}>
            <Box className={titleWrapper}>
              <FontAwesomeIcon icon={['fas', 'building']} className={byLineIcon} />
              <Typography className={cx(line, line2, link)} noWrap>
                {internalJobIsCurrent || isSameJob ? (
                  <Link to={`${companyBaseUrl}/${bestJobCompanyMd5}`}>{internalCompanyName}</Link>
                ) : (
                  <Link
                    to={mergeUrlWithParams(`${Paths._people}/${id}/market-data/company/${jobCompanyName}`, {
                      website: jobCompanyWebsite
                    })}
                  >
                    {jobCompanyName}
                  </Link>
                )}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

const MDSummary = ({ loading, byline, byline2 }: { loading: boolean; byline?: string | JSX.Element; byline2?: JSX.Element }) => {
  return (
    <>
      {(loading || byline) && (
        <Skeleton condition={loading} width="80%" height={20}>
          {byline}
        </Skeleton>
      )}

      {(loading || byline2) && (
        <Skeleton condition={loading} width="70%" height={20}>
          {byline2}
        </Skeleton>
      )}
    </>
  )
}

const MDActions = ({ actions, children }: { actions: JSX.Element[]; children?: ReactNode }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.actions}>
      {actions.map((action: any, index: number) => (
        <Box className={classes.action} key={index}>
          {action}
        </Box>
      ))}
      {children}
    </Box>
  )
}

const MarketDataProfileSummaryLayout = ({
  children,
  buttons,
  actions,
  statusIcons,
  flip
}: PropsWithChildren<Pick<ProfileSummaryProps, 'buttons' | 'actions' | 'statusIcons' | 'flip'>>) => {
  const { classes } = useStyles({ flip })
  const { classes: defaultClasses } = useDefaultStyles({ flip })

  return (
    <>
      <Box className={classes.wrapper}>
        {children}
        <Wide>
          <Box className={defaultClasses.actionsWrapper}>
            {statusIcons}
            {buttons && <Box className={defaultClasses.buttons}>{buttons}</Box>}
          </Box>
        </Wide>
      </Box>
      <Narrow>
        <Box className={defaultClasses.actionsWrapper}>{statusIcons}</Box>
        {buttons && (
          <MDActions actions={actions}>
            <Box>{buttons}</Box>
          </MDActions>
        )}
      </Narrow>
    </>
  )
}

const MarketDataSummary = ({
  title,
  score,
  userKey,
  url,
  budge,
  loading,
  editTagsLink,
  showAllTagsLink,
  profileLink,
  tags,
  byline,
  byline2,
  buttons,
  actions,
  flip,
  statusIcons
}: ProfileSummaryProps) => {
  const { classes } = useStyles({ flip })

  return (
    <MarketDataProfileSummaryLayout buttons={buttons} actions={actions} flip={!!flip} statusIcons={statusIcons}>
      <ProfileContainer
        className={classes.container}
        title={title}
        loading={loading}
        budge={budge}
        editTagsLink={editTagsLink}
        showAllTagsLink={showAllTagsLink}
        profileLink={profileLink}
        score={score}
        userKey={userKey}
        url={url}
        tags={tags}
        flip={flip}
      >
        <MDSummary byline={byline} byline2={byline2} loading={loading} />
        <Wide>
          <MDActions actions={actions} />
        </Wide>
      </ProfileContainer>
    </MarketDataProfileSummaryLayout>
  )
}

export default MarketDataSummary
