import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Badge from '_shared/Badge'
import { IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

import CollapseContainer from '_core/components/CollapseContainer'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'sticky',
    top: 40,
    zIndex: 100,
    background: theme.palette.background.light,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    boxSizing: 'border-box',
    marginTop: `-${theme.spacing(7.5)}`,
    marginLeft: `-${theme.spacing(2.5)}`,
    marginRight: `-${theme.spacing(2.5)}`,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    [theme.breakpoints.up('md')]: {
      top: 59,
      marginTop: 0
    }
  },
  collapseContainer: {
    borderBottom: 0,
    paddingTop: theme.spacing(2)
  }
}))

const AppliedFilters = ({ disabled, filtersCount, children, setHeight }: PropsWithChildren<{
  disabled: boolean;
  filtersCount: number;
  setHeight?: (arg: number) => void
}>) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [collapsed, setCollapsed] = useState(true)
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (!contentRef.current || !setHeight) return
    const resizeObserver = new ResizeObserver(() => {
      const height = contentRef.current?.offsetHeight
      setHeight(height || 0)
    })
    resizeObserver.observe(contentRef.current)
    return () => resizeObserver.disconnect()
  }, [setHeight])

  const toggleCollapsed = () => setCollapsed((prevState) => !prevState)

  return (
    <Box ref={contentRef} className={cx({ [classes.wrapper]: !!filtersCount })}>
      {!!filtersCount && <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography semiBold>Applied filters</Typography>
            <Badge badgeContent={filtersCount} color="primary" size="xs" />
          </Box>
          <IconButton disablePR disablePY onClick={toggleCollapsed} disabled={disabled}>
            <FontAwesomeIcon icon={['far', collapsed ? 'chevron-down' : 'chevron-up']} style={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
        <CollapseContainer collapsed={collapsed} className={classes.collapseContainer}>
          {children}
        </CollapseContainer>
      </>}
    </Box>
  )
}

export default AppliedFilters
