import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'

import { StatusChip, ChipLabel } from '_core/components/Chip'
import ProfileItem from '_core/components/ProfileItem'
import { ErrorStatusIcon, PrimaryStatusIcon, SuccessStatusIcon, WarningStatusIcon, StatusIconProps } from '_core/components/StatusIcon'

import { useStyles } from '_core/hooks/useIntroductionRequestForm'

export type ContactItemProps = {
  name: string
  email: string
  statusLabel: string
  status: string
  querierHasWriteAccess: boolean
  closedOut: boolean
  link: string
}

type Contact = Pick<ContactItemProps, 'name' | 'email' | 'status' | 'statusLabel'>

const ContactItem = (props: ContactItemProps) => {
  const [contact, setContact] = useState<Contact>({
    name: '',
    email: '',
    status: '',
    statusLabel: ''
  })

  useEffect(() => {
    setContact({
      name: props.name,
      email: props.email,
      status: props.status,
      statusLabel: props.statusLabel
    })
  }, [props.name, props.email, props.statusLabel, props.status])

  const { classes } = useStyles()
  const { icon, color } = getStatusIcon(props.status)
  const Label = <ChipLabel label={contact.statusLabel} icon={icon} noWrap />

  return (
    <CardContent>
      <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center" mb={1}>
        <ProfileItem name={contact.name} userKey={contact.email} byline={contact.email} className={classes.cardProfileItem} score="" />
      </Box>
      <Skeleton condition={!contact.statusLabel} width={140} height={24}>
        <StatusChip label={Label} color={color} />
      </Skeleton>
    </CardContent>
  )
}

type StatusIcon = Omit<StatusIconProps, 'icon'>
export const getStatusIcon = (
  status: string
): {
  icon: React.ElementType
  color: 'secondary' | 'error' | 'warning' | 'success'
} => {
  switch (status) {
    case 'AssigneeIntroIdeasSought':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'search']} {...props} />,
        color: 'secondary'
      }
    case 'AssigneeAskingIntroducers':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'search']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerSelected':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'user-plus']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerReachedOut':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'handshake']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerHasFeedback':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'comment']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerWasNotified':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'envelope']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerAgreedToTry':
      return {
        icon: (props: StatusIcon) => <PrimaryStatusIcon icon={['fas', 'thumbs-up']} {...props} />,
        color: 'secondary'
      }
    case 'IntroducerIsUnresponsive':
      return {
        icon: (props: StatusIcon) => <WarningStatusIcon icon={['fas', 'clock']} {...props} />,
        color: 'warning'
      }
    case 'IntroducerFailing':
      return {
        icon: (props: StatusIcon) => <WarningStatusIcon icon={['fas', 'exclamation-triangle']} {...props} />,
        color: 'warning'
      }
    case 'IntroductionSuccess':
      return {
        icon: (props: StatusIcon) => <SuccessStatusIcon icon={['fas', 'circle-check']} {...props} />,
        color: 'success'
      }
    default:
      return {
        icon: (props: StatusIcon) => <ErrorStatusIcon icon={['fas', 'times']} {...props} />,
        color: 'error'
      }
  }
}

export default ContactItem
