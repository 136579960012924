import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import PersonMarketData from '_core/components/market-data/PersonMarketData'
import Topbar from '_core/components/Topbar'

import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type PageProps = {
  getPersonDetails: (name: string, email?: string) => PersonMarketDataType | undefined
}

const SimilarPersonPage = ({ getPersonDetails }: PageProps) => {
  const { similarId: name } = useParams<{ id: string; similarId: string }>()
  const { queryParams } = useSearchQuery<{ email?: string; company?: string }>()
  const { email, company } = queryParams
  const { marketData, setMarketData, getMarketData } = usePersonMarketData(name, email, company)
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader(name)
  }, [name, setMobileHeader])

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  useEffect(() => {
    if (!marketData && name && (email || company)) {
      const details = getPersonDetails(name, email)
      if (details) {
        setMarketData(details)
      } else {
        getMarketData()
      }
    }
  }, [name, marketData, email, company, getMarketData])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <PersonMarketData person={marketData} urlType="people" />
      </Narrow>
    </Page>
  )
}

export default SimilarPersonPage
