import { useState } from 'react'

import { transformCompanyData } from '_core/hooks/useCompanyMarketData'

export type FiltersType = {
  minimumEmployeeCount: string
  maximumEmployeeCount: string
  industries: string[]
  countries: string[]
  companyType: MarketDataCompanyType | ''
}

export type CompaniesType = {
  data: CompaniesMarketDataType[]
  id?: string
  website?: string
  filters?: FiltersType
} & PaginationMarketData

export const transformResponse = (companies: CompaniesMarketDataRes['data']): CompaniesType['data'] => {
  return companies.map((company) => ({
    ...transformCompanyData(company),
    companyMd5: company.companyMd5 || '',
    score: company.score || 0
  }))
}

export const useSimilarCompanies = () => {
  const [similar, setSimilar] = useState<CompaniesType | null>(null)
  const [similarWidget, setSimilarWidget] = useState<CompaniesType | null>(null)

  const getCompanyDetails = (name: string, website?: string): CompaniesMarketDataType | undefined => {
    return [...(similar?.data ? similar.data : []), ...(similarWidget?.data ? similarWidget.data : [])].find(
      (item) => item.name === name || (website && item.website === website)
    )
  }

  return {
    similar,
    similarWidget,
    setSimilar,
    setSimilarWidget,
    getCompanyDetails
  }
}
