import React, { SyntheticEvent, KeyboardEvent, ChangeEvent } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import FormLabel from '_shared/forms/FormLabel'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import MarketDataFieldPicker, { MarketDataFieldType, companyTypeOptions } from '_core/components/MarketDataFieldPicker'

import { FiltersType } from '_core/hooks/useSimilarCompanies'

import { actionButtons } from 'AppTheme'

import Filters from './index'

const useStyles = makeStyles<{ sidepanel: boolean }>()((theme, { sidepanel }) => ({
  container: {
    overflowY: 'visible',
    padding: 0
  },
  wrapper: {
    padding: `0 ${theme.spacing(2)}`,
    minHeight: `calc(100vh - ${sidepanel ? 257 : 229}px)`
  },
  block: {
    paddingBottom: theme.spacing(1.5)
  },
  buttonContainer: actionButtons(theme, 'sticky'),
  divider: {
    padding: theme.spacing(1.5)
  }
}))

type FieldType = Extract<MarketDataFieldType, 'industries' | 'countries'>
export type SimilarCompaniesFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  anchorEl?: HTMLElement | null
  filters?: FiltersType | null
  updateFilters: (updatedFilters: FiltersType) => void
  reset: () => void
  applyFilters: (() => void) | undefined
}

const preventDefault = (e: SyntheticEvent) => (e.target as HTMLInputElement).blur()
const allowOnlyNumbers = (e: KeyboardEvent<HTMLInputElement>) => !/[0-9]/.test(e.key) && e.preventDefault()

const SimilarCompaniesFilters = (props: SimilarCompaniesFiltersType) => {
  const { opened, disabled, anchorEl, total, contentLoading, updateFilters, filters, reset, applyFilters } = props
  const sidepanel = isSidepanel()
  const { classes } = useStyles({ sidepanel })

  const handleCompanyTypeChange = (event: React.SyntheticEvent<Element, Event>, newValue: FiltersType['companyType'] | null) => {
    if (filters) {
      const newFilter: { companyType: FiltersType['companyType'] } = { companyType: newValue || '' }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  const handleMultiplePickerChange = (newValue: string[], field: MarketDataFieldType) => {
    if (filters && field) {
      const newFilter = { [field]: newValue }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  const handleRemove = (value: string | null, field: MarketDataFieldType) => {
    if (filters && value) {
      const typedField = field as FieldType
      const newFilter = { [typedField]: filters[typedField].filter((v: string) => v !== value) }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (filters) {
      const field = event.target.name
      const newFilter = { [field]: event.target.value }
      updateFilters({ ...filters, ...newFilter })
    }
  }

  return (
    <Filters
      opened={opened}
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={reset}
      anchorEl={anchorEl || null}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.block}>
          <MarketDataFieldPicker
            field="countries"
            label="Countries"
            icon={['fas', 'location-dot']}
            placeholder="E.g. United States"
            value={filters?.countries}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.block}>
          <MarketDataFieldPicker
            field="industries"
            label="Industry"
            icon={['fas', 'industry']}
            placeholder="E.g. Real Estate"
            value={filters?.industries}
            handleChange={handleMultiplePickerChange}
            handleDelete={handleRemove}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.block}>
          <Combobox<MarketDataCompanyType, false, boolean>
            label="Company type"
            options={companyTypeOptions}
            disabled={disabled}
            value={filters?.companyType || null}
            onChange={handleCompanyTypeChange}
            disableClearable={!filters?.companyType}
            blurOnSelect
          />
        </Box>
        <Box className={classes.block}>
          <FormLabel label="Employee count" />
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              name="minimumEmployeeCount"
              value={filters?.minimumEmployeeCount || ''}
              onChange={handleTextFieldChange}
              onWheel={preventDefault}
              onKeyPress={allowOnlyNumbers}
              type="number"
            />
            <Typography component="span" className={classes.divider}>
              -
            </Typography>
            <TextField
              fullWidth
              name="maximumEmployeeCount"
              value={filters?.maximumEmployeeCount || ''}
              onChange={handleTextFieldChange}
              onWheel={preventDefault}
              onKeyPress={allowOnlyNumbers}
              type="number"
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button onClick={applyFilters} disabled={disabled || contentLoading || !applyFilters} variant="text" disablePR>
          Apply
        </Button>
      </Box>
    </Filters>
  )
}

export default SimilarCompaniesFilters
