/* eslint-disable react/jsx-key */
import React, { ComponentProps, Dispatch, Fragment, SetStateAction, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Moment as MomentType } from 'moment'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AlertWidget } from '_pages/people/[id]/touchpoints'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { Button } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import Affiliation, { AffiliationPopover } from '_core/components/Affiliation'
import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import { emailReason } from '_core/components/dialogs/InformAboutIdentity'
import Empty from '_core/components/Empty'
import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import HelpLinkButton from '_core/components/HelpLinkButton'
import IdentifierLabel from '_core/components/IdentifierLabel'
import { Interaction, getTime } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import PhoneNumber from '_core/components/PhoneNumber'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointSummary from '_core/components/TouchpointSummary'
import Widget from '_core/components/Widget'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import ExternalKeysWidget from '_core/components/widgets/ExternalKeys'
import IntroducersWidget from '_core/components/widgets/Introducers'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useDialog from '_core/hooks/useDialog'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { PeopleType as SimilarPeopleType, transformResponse as transformSimilarPeopleResponse } from '_core/hooks/useSimilarPeople'

import { stringifyUrl } from '_core/helpers/browser'
import { sum } from '_core/helpers/numbers'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { getLocal, dateFormat, getUTC } from 'utils/Utils'

import { widgetSubTitle } from 'AppTheme'

import Paths from 'Paths'

type ProfileWidgetsProps = {
  loading: boolean
  interactions: { [p: string]: any } | null | undefined
  marketData: PersonMarketDataType | null
  similar: SimilarPeopleType | null
  setSimilar: Dispatch<SetStateAction<any>>
  showInteractionsInProfile: boolean
  hideColleagueRelationships: boolean
  showActivityStats: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  enabledMarketData: boolean | undefined
  meUser?: ProfileType
} & Pick<MarketDataIntegration, 'showSimilarPeople'>

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: 16,
      marginBottom: -16
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  widget: {
    [theme.breakpoints.up('md')]: {
      minHeight: 275
    }
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 14,
    lineHeight: '22px'
  },
  bold: {
    fontWeight: 700
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  marketDataWrapper: {
    '& > div:not(:first-of-type)': {
      paddingTop: theme.spacing(1)
    }
  },
  informIcon: {
    marginLeft: theme.spacing(1)
  },
  tagsWrapper: {
    overflow: 'hidden',
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

export const checkContributor = (loading: boolean, email: string, myUserKeyMd5: string, userEmail: string, hideColleagueRelationships: boolean) =>
  (!hideColleagueRelationships || email === userEmail) && !loading && myUserKeyMd5

export const PeopleWidget = (props: PersonType & Pick<ProfileWidgetsProps, 'loading'> & { md5: string }) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.UserContacts || {}

  return (
    <Widget mdFlex0 className={classes.widget}>
      <Heading
        underlined
        title="People"
        count={total_item_count}
        icon={['far', 'user']}
        link={total_item_count ? `${Paths._people}/${props.md5}/people` : null}
      />
      {!!total_item_count && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 4, loading: props.loading }}
          items={
            items?.map((contact) => ({
              userKey: contact.ContactUserKeyMd5 ? contact.BestEmailAddrText : '',
              name: contact.PersonNameText || contact.BestEmailAddrText,
              score: contact.ScorePoints,
              sidepanel: true,
              link: `${Paths._relationships}/${props.MyUserKeyMd5}/people/${contact.PersonMd5}`
            })) || []
          }
        />
      )}
      {!total_item_count && (
        <Empty
          className={classes.empty}
          subTitle={`${props.PersonNameText || props.BestEmailAddrText} has no people`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const CompaniesWidget = (props: PersonType & Pick<ProfileWidgetsProps, 'loading'> & { md5: string }) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.UserCompanies || {}

  return (
    <Widget mdFlex0 className={classes.widget}>
      <Heading
        underlined
        title="Companies"
        count={total_item_count}
        icon={['far', 'building']}
        link={total_item_count ? `${Paths._people}/${props.md5}/companies` : null}
      />
      {!!total_item_count && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 4, loading: props.loading }}
          items={
            items?.map((company) => ({
              name: company.CompanyNameText || company.BestUrlText,
              score: company.ScorePoints,
              logoUrl: company.BestUrlText,
              sidepanel: true,
              link: `${Paths._relationships}/${props.MyUserKeyMd5}/companies/${company.CompanyMd5}`
            })) || []
          }
        />
      )}
      {!total_item_count && (
        <Empty
          className={classes.empty}
          subTitle={`${props.PersonNameText || props.BestEmailAddrText} has no companies`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'buildings']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const ActivitiesStatsWidget = ({
  isDetailedActivityFromStatsWidgetVisible,
  md5 = '',
  onChange
}: {
  md5?: string
  onChange: (params: ActivityStatsPageParams) => void
} & Pick<ProfileWidgetsProps, 'isDetailedActivityFromStatsWidgetVisible'>) => {
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { stackColumns = 'false', from: fromUTC, to: toUTC } = queryParams

  const statsPayloads = useContactActivitiesPayloads(md5)

  const chartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { stats, clearStats } = useActivitiesStats(
    md5 ? statsPayloads : null,
    useMemo(() => [md5], [md5]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  useEffect(() => {
    if (!md5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5])

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    onChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    onChange({ stackColumns: `${!JSON.parse(stackColumns)}` })
  }

  return (
    <ActivityStatsWidget
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      stats={stats}
      stack={stackColumns === 'true'}
      seeAllLink={`${Paths._people}/${md5}/activityStats`}
      detailsLink={`${Paths._people}/${md5}/activities`}
      updatePeriod={updatePeriod}
      toggleStack={toggleStack}
      {...chartData}
    />
  )
}

const TouchpointsWidget = (props: PersonType & Pick<ProfileWidgetsProps, 'loading'> & { md5: string }) => {
  const { classes } = useStyles()
  const stats =
    !props.loading && props.Stats
      ? Object.getOwnPropertyNames(props.Stats).filter(
          (k) => ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg'].indexOf(k) > -1
        )
      : []

  return (
    <Widget className={classes.widget}>
      <Heading
        underlined
        title="Key touch points"
        icon={['far', 'handshake']}
        count={props.loading || props.Stats === null ? -1 : stats.length}
        link={stats.length && !props.loading ? `${Paths._people}/${props.md5}/touchpoints` : ``}
      />
      {(!!stats.length || props.loading) && (
        <Repeater component={TitleDescription} skeleton={{ size: 3, loading: props.loading }} items={TouchpointSummary(props.Stats)} />
      )}
      {!stats.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${props.PersonNameText || props.BestEmailAddrText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

type InformAffiliationType = { companyName: string; isFormerJob: boolean; currentAsOf: string }

const AffiliationsWidget = (props: PersonType & Pick<ProfileWidgetsProps, 'loading'> & { md5: string }) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.Jobs || {}
  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<InformAffiliationType>()
  const wide = useWide()

  const personName = props.PersonNameText || props.BestEmailAddrText

  const handleOpenDialog = (e: SyntheticEvent, data: InformAffiliationType) => {
    e.stopPropagation()
    e.preventDefault()
    openDialog(data)
  }

  const handleCloseDialog = () => {
    closeDialog()
  }

  return (
    <>
      <Widget>
        <Heading
          underlined
          title="Affiliations"
          count={total_item_count}
          icon={['far', 'suitcase']}
          link={total_item_count ? `${Paths._people}/${props.md5}/affiliations` : ''}
        />
        {(!!total_item_count || props.loading) && (
          <Repeater
            component={Affiliation}
            skeleton={{ size: 2, loading: props.loading }}
            items={
              (wide ? items?.slice(0, 2) : items)?.map((job) => ({
                title: job.JobCoName || job.JobCorpLevelName,
                description: job.JobTitleText,
                blurb: `Last evidence on ${getLocal(job.JobCurrentAsOf).format(dateFormat)}`,
                sidepanel: true,
                link: `${Paths._companies}/${job.JobCompanyMd5}`,
                icons: (
                  <Box display="flex" alignItems="center">
                    {job.JobIsFormer && (
                      <AffiliationPopover
                        workExperience={{
                          companyIdentity: job.JobCompanyMd5,
                          companyName: job.JobCoName || job.JobCorpLevelName,
                          jobTitle: job.JobTitleText,
                          currentAsOf: job.JobCurrentAsOf
                        }}
                        icon={['far', 'address-book']}
                      />
                    )}
                    <AffiliationInformDialog.TriggerEl
                      open={(e: SyntheticEvent) =>
                        handleOpenDialog(e, {
                          companyName: job.JobCoName || job.JobCorpLevelName,
                          isFormerJob: !!job.JobIsFormer,
                          currentAsOf: job.JobCurrentAsOf || ''
                        })
                      }
                    />
                  </Box>
                )
              })) || []
            }
          />
        )}
        {!total_item_count && !props.loading && (
          <Empty
            className={classes.empty}
            subTitle={`${personName} has no affiliations`}
            icon={<FontAwesomeIcon size="3x" icon={['fat', 'suitcase']} style={{ color: '#A7A7A7' }} />}
          />
        )}
      </Widget>
      <AffiliationInformDialog
        personId={props.PersonMd5}
        personName={personName}
        close={handleCloseDialog}
        openSuccess={openSuccess}
        opened={!!openedDialog}
        success={successMode}
        {...openedDialog}
      />
    </>
  )
}

const InteractionsWidget = ({ interactions, ...props }: PersonType & Pick<ProfileWidgetsProps, 'loading' | 'interactions'> & { md5: string }) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()
  const { data: items, errorMessage } = interactions || {}
  const loading = props.loading || !interactions

  return (
    <Widget>
      <Heading
        underlined
        title="Interactions"
        icon={['far', 'envelope']}
        count={0}
        action={
          <Box flex={1}>
            <InteractionsPrivacyMsg />
          </Box>
        }
        link={!loading && items?.length ? `${Paths._people}/${props.md5}/interactions` : undefined}
      />
      {errorMessage && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      )}
      {!errorMessage && (
        <>
          {(!!items?.length || loading) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={items
                  ?.map((item: { [key: string]: any }) => ({
                    time: getTime(item.receivedDateTime),
                    title: item.rawSubject || '(No subject)',
                    byline: item.body,
                    byline2: (
                      <NameLink
                        variant="light"
                        url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                        name={item.senderName}
                      />
                    ),
                    dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} style={{ fontSize: 14 }} />,
                    sidepanel: true,
                    link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                    component: 'div'
                  }))
                  .slice(0, 5)}
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!items?.length && !loading && (
            <Empty
              className={classes.empty}
              subTitle={`You have not exchanged any email messages with ${props.PersonNameText || props.BestEmailAddrText}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
            />
          )}
        </>
      )}
    </Widget>
  )
}

const ContactInformationWidget = (props: PersonType & Pick<ProfileWidgetsProps, 'loading'> & { md5: string }) => {
  const { teamContextValue } = useContext(TeamContext)
  const {
    classes: { heading, widget }
  } = useStyles()

  const personName = props.PersonNameText || props.BestEmailAddrText

  const submitInvalid = (identity: string, value: number) => {
    return post(mergeUrlWithParams('/people/disqualify', { teamNumber: `${teamContextValue.teamNumber}` }), {
      identity,
      reason: emailReason[value]
    })
  }

  return (
    <Widget className={widget}>
      <Heading
        underlined
        title="Contact information"
        count={props.loading ? -1 : sum(props.Phones?.total_item_count, props.Emails?.total_item_count)}
        icon={['far', 'address-card']}
        link={!props.loading && `${Paths._people}/${props.md5}/info`}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: props.Emails?.total_item_count ? -8 : 0 }}
        >
          Emails
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={
            props.Emails?.data.map((email) => ({
              identity: email.AddressText,
              type: 'EmailAddress',
              icon: ['far', 'envelope'],
              name: personName,
              submit: submitInvalid,
              sourceLink: `${Paths._people}/${props.md5}/emailSources/${email.AddressText}`,
              auditLink: `${Paths._people}/${props.md5}/audit`
            })) || []
          }
          empty="No email addresses were found"
        />

        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, marginBottom: props?.Phones?.total_item_count ? -8 : 0 }}
        >
          Phone numbers
        </Typography>

        <Repeater
          component={PhoneNumber}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={
            props.Phones?.data.map((phone) => ({
              phoneType: phone.PhoneType,
              number: phone.StandardizedPhoneNumber,
              person: { name: personName, id: props.PersonMd5 }
            })) || []
          }
          empty="No phone numbers were found"
        />
      </Box>
    </Widget>
  )
}

const SimilarPeopleWidget = ({
  similar,
  setSimilar,
  ...props
}: { name: string; email: string; jobTitle?: string } & Pick<ProfileWidgetsProps, 'loading' | 'similar' | 'setSimilar' | 'marketData'> & {
    md5: string
  }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading } = useAbortableFetch<PeopleMarketDataRes>()
  const { classes } = useStyles()

  const load = loading || props.loading
  const { jobTitle, jobTitleRole, jobTitleSubRole, jobTitleLevels, jobCompanyIndustry, industry, locationCountry, jobCompanySize, workEmail } =
    props.marketData || {}
  const enoughFilters = props.marketData && (workEmail || props.email) && (jobTitle || props.jobTitle)
  const showEmptyWidget = (enoughFilters && !loading && !similar?.data?.length) || (props.marketData && !enoughFilters)

  const initParams = useMemo(
    () => ({
      email: workEmail || props.email,
      jobTitles: [jobTitle || props.jobTitle || ''].filter(Boolean),
      jobTitleRoles: jobTitleRole ? [jobTitleRole] : [],
      jobTitleSubroles: jobTitleSubRole ? [jobTitleSubRole] : [],
      jobTitleLevels: jobTitleLevels || [],
      companySize: jobCompanySize || '',
      industries: jobCompanyIndustry || industry ? [jobCompanyIndustry || industry || ''] : [],
      countries: [locationCountry || 'United States'],
      teamNumber: teamNumber.toString(),
      titlecase: 'true'
    }),
    [props.jobTitle, props.email, workEmail, jobTitle, jobCompanyIndustry, industry, locationCountry, teamNumber]
  )
  useEffect(() => {
    if (enoughFilters && !similar && teamNumber) {
      ;(async () => {
        const res = await fetchWithAbort({
          url: mergeUrlWithParams('/cloudhub/people/similar', initParams)
        })
        if (res) {
          setSimilar({
            ...res,
            data: transformSimilarPeopleResponse(res.data)
          })
        }
      })()
    }
  }, [enoughFilters, similar, teamNumber])

  const items = similar?.data
    ? similar.data.map((person) => {
        return {
          name: person.fullName,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._people}/${props.md5}/similar/${person.fullName}`, {
                email: person.workEmail,
                company: person.jobCompanyName
              })
        }
      })
    : []

  return (
    <Widget mdFlex0>
      <Heading
        underlined
        title="Similar people"
        icon={['fas', 'diagram-venn']}
        count={showEmptyWidget ? 0 : similar?.total}
        link={
          similar?.total && props.md5 && !showEmptyWidget ? `${Paths._people}/${props.md5}/similar?clearSearch=true&name=${props.name}` : undefined
        }
        sidepanel
      />

      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}

      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={
            enoughFilters ? 'No records were found matching your search' : 'Not enough market data is available for an effective search filter.'
          }
          action={
            <Box display="flex" justifyContent="center">
              <SidepanelLink
                linkProps={{ to: `${Paths._people}/${props.md5}/similar?openedFilters=true&clearSearch=true&name=${props.name}` }}
                sidepanel={true}
              >
                <Button variant="link" bold={false}>
                  {enoughFilters ? 'Change' : 'Add'} filters
                </Button>
              </SidepanelLink>
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const MarketDataWidget = (props: { name: string } & Pick<ProfileWidgetsProps, 'loading' | 'marketData'> & { md5: string }) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const sidepanel = useMediaQuery(theme.breakpoints.up('sidepanel'))

  const { locationName, industry, jobCompanyIndustry, skills, interests, experience, education, workEmail, id } = props.marketData || {}
  const mocked = new Array(3).fill('')
  const noData = props.marketData && !id
  const noWidgetData = id && !locationName && (!jobCompanyIndustry || !industry) && !skills?.length && !interests?.length
  const notEnoughData = noWidgetData && !experience?.length && !education?.length && !workEmail

  return (
    <Widget>
      <Heading
        underlined
        title="Details"
        icon={['fas', 'chart-column']}
        count={0}
        action={
          <Box flex={1}>
            <HelpLinkButton
              hint="Learn more about market data"
              to={{ pathname: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data' }}
              size="small"
              disablePY
            />
          </Box>
        }
        link={!noData && !notEnoughData ? `${Paths._people}/${props.md5}/market-data` : ''}
        sidepanel
      />
      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No skill, interest, location or industry info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      <Box mb={{ md: 2 }} className={classes.marketDataWrapper}>
        {(!props.marketData || locationName) && (
          <Skeleton condition={!props.marketData && !locationName}>
            <Tooltip title={locationName} placement="bottom-start">
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon icon={['fas', 'location-dot']} className={classes.byLineIcon} />
                <Typography>{locationName || 'Placeholder name'}</Typography>
              </Box>
            </Tooltip>
          </Skeleton>
        )}
        {(!props.marketData || jobCompanyIndustry || industry) && (
          <Skeleton condition={!props.marketData && (!jobCompanyIndustry || !industry)}>
            <Tooltip title={jobCompanyIndustry || industry} placement="bottom-start">
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon icon={['fas', 'industry']} className={classes.byLineIcon} />
                <Typography>{jobCompanyIndustry || industry || 'Placeholder name'}</Typography>
              </Box>
            </Tooltip>
          </Skeleton>
        )}
        {(!props.marketData || !!skills?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={sidepanel ? 2 : 1}
            items={!props.marketData ? mocked : skills}
            title={<Typography bold>Skills</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={skills?.length ? skills : mocked}
                      title="Skills"
                      name={props.name}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
        {(!props.marketData || !!interests?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={sidepanel ? 2 : 1}
            items={!props.marketData ? mocked : interests}
            title={<Typography bold>Interests</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={interests?.length ? interests : mocked}
                      title="Interests"
                      name={props.name}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
      </Box>
    </Widget>
  )
}

const Profile = ({
  interactions,
  marketData,
  similar,
  setSimilar,
  showInteractionsInProfile,
  showActivityStats,
  isDetailedActivityFromStatsWidgetVisible,
  enabledMarketData,
  showSimilarPeople,
  hideColleagueRelationships,
  meUser,
  CrmSourceKeys,
  ...props
}: ProfileWidgetsProps & PersonType) => {
  const wideStrict = useWide('lg')
  const theme = useTheme()

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { stackColumns } = queryParams

  const md5 = props.MyUserKeyMd5 || props.PersonMd5

  useEffect(() => {
    if (!props.loading && props.PersonMd5) window.parent.postMessage('da_open', '*')
  }, [props.loading, props.PersonMd5])

  const personName = props.PersonNameText || props.BestEmailAddrText

  const IntroducersWidgetEl = (
    <IntroducersWidget
      loading={props.loading}
      total={props.Introducers?.total_item_count}
      entityName={personName}
      link={`${Paths._people}/${md5}/introducers`}
      items={
        props.Introducers?.data.slice(0, 5).map((intro) => ({
          name: intro.IntroducerName,
          userKey: intro.IntroducerBestEmailAddressText,
          score: intro.ScorePoints,
          sidepanel: true,
          link: `${Paths._relationships}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}/people/${props.PersonMd5}`
        })) || []
      }
    />
  )
  const AffiliationsWidgetEl = <AffiliationsWidget md5={md5} {...props} />
  const ActivitiesStatsWidgetEl = (
    <UserSettings
      key={md5 && `${!!stackColumns}`}
      endpoint={!stackColumns && showActivityStats ? '/usersettings/activitiesstats' : ''}
      setInitial={setInitial}
    >
      <ActivitiesStatsWidget isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible} md5={md5} onChange={handleChange} />
    </UserSettings>
  )
  const TouchpointsWidgetEl = <TouchpointsWidget md5={md5} {...props} />
  const ContactInformationWidgetEl = <ContactInformationWidget md5={md5} {...props} />
  const PeopleWidgetEl = <PeopleWidget md5={md5} {...props} />
  const CompaniesWidgetEl = <CompaniesWidget md5={md5} {...props} />
  const InteractionsWidgetEl = <InteractionsWidget md5={md5} interactions={interactions} {...props} />
  const MarketDataWidgetEl = <MarketDataWidget md5={md5} marketData={marketData} name={personName} loading={props.loading} />
  const SimilarPeopleWidgetEl = (
    <SimilarPeopleWidget
      md5={md5}
      marketData={marketData}
      similar={similar}
      setSimilar={setSimilar}
      email={props.BestEmailAddrText}
      name={personName}
      loading={props.loading}
      jobTitle={props.BestJobTitleText}
    />
  )

  const ExternalKeysWidgetEl = CrmSourceKeys?.data?.length ? (
    <ExternalKeysWidget
      entity="person"
      loading={props.loading}
      keys={CrmSourceKeys.data.map((key) => ({ sourceId: key.SourceId, lastModified: key.LastModifiedDateTime }))}
      total={CrmSourceKeys.total_item_count}
      name={personName}
      link={`${Paths._people}/${md5}/keys`}
    />
  ) : (
    <></>
  )

  const contributor = checkContributor(
    props.loading,
    props.BestEmailAddrText,
    props.MyUserKeyMd5 || '',
    meUser?.UserKey || '',
    hideColleagueRelationships
  )

  const columnsRows = [
    {
      condition: contributor && showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [SimilarPeopleWidgetEl, PeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, SimilarPeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [PeopleWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[PeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl, TouchpointsWidgetEl], [CompaniesWidgetEl, AffiliationsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl, CompaniesWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [SimilarPeopleWidgetEl], [PeopleWidgetEl]] },
            { columns: [[CompaniesWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [CompaniesWidgetEl], [PeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[PeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [AffiliationsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && !showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && !showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && showActivityStats && !showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [IntroducersWidgetEl]]
            },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, AffiliationsWidgetEl], [IntroducersWidgetEl, SimilarPeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl, TouchpointsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, AffiliationsWidgetEl], [IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, AffiliationsWidgetEl], [TouchpointsWidgetEl, ContactInformationWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && !showInteractionsInProfile && enabledMarketData && showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && !showInteractionsInProfile && enabledMarketData && !showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !showActivityStats && !showInteractionsInProfile && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <>
      <Wide>
        <Columns>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => {
              if (!Array.isArray(column)) {
                return (
                  <Column key={`${k}${i}`} md={12}>
                    {column}
                  </Column>
                )
              }
              return (
                <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                  {column.map((row, j) =>
                    Array.isArray(row) ? (
                      <Columns
                        key={`${k}${i}${j}`}
                        md={12}
                        minWidth={`calc(100% + ${theme.spacing(2)})`}
                        {...(!row.length ? { display: 'none' } : {})}
                      >
                        {row.map((r, y) => (
                          <Column md={6} key={`${k}${i}${j}${y}`}>
                            {r}
                          </Column>
                        ))}
                      </Columns>
                    ) : (
                      <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                    )
                  )}
                </Column>
              )
            })}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <AlertWidget {...props} />
        {!contributor && showActivityStats && ActivitiesStatsWidgetEl}
        {enabledMarketData && MarketDataWidgetEl}
        {!contributor && IntroducersWidgetEl}
        {showSimilarPeople && SimilarPeopleWidgetEl}
        {contributor && (
          <>
            {PeopleWidgetEl}
            {CompaniesWidgetEl}
          </>
        )}
        {showInteractionsInProfile && InteractionsWidgetEl}
        {TouchpointsWidgetEl}
        {AffiliationsWidgetEl}
        {ContactInformationWidgetEl}
        {ExternalKeysWidgetEl}
      </Narrow>
    </>
  )
}

export default Profile
