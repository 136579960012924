import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Chip, { FilterChipLabel } from '_core/components/Chip'
import Content from '_core/components/MarketDataCompaniesSearchFilters'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Filters, { AppliedFilters } from './index'

export type MarketDataFilters = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  anchorEl?: HTMLElement | null
  total: number | undefined
  loading: boolean
  toggleOpen: () => void
}

export type CompaniesFiltersType = {
  name: string
  website: string
  companyType: MarketDataCompanyType | ''
  countries: string[]
  industries: string[]
  locationName: string[]
  locationRegion: string[]
  minimumEmployeeCount: number | null
  maximumEmployeeCount: number | null
}

const useStyles = makeStyles()((theme) => ({
  container: {
    overflow: 'visible',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      width: 270,
      maxHeight: 'calc(100vh - 190px)',
      paddingBottom: 0
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%',
    margin: `0 -${theme.spacing(2.5)}`,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.background.light,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      width: 'auto'
    }
  }
}))

const resetFilters: CompaniesFiltersType = {
  name: '',
  website: '',
  companyType: '',
  countries: [],
  industries: [],
  locationName: [],
  locationRegion: [],
  minimumEmployeeCount: null,
  maximumEmployeeCount: null
}

const getPayload = (filters: CompaniesFiltersType) =>
  Object.entries(filters)
    .filter(([, value]) => (Array.isArray(value) ? value.length : !!value))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: Array.isArray(value) ? value : `${value}` }), {})

const AppliedFiltersContent = ({
  disabled,
  setValue,
  filters,
  handleUpdateQuery
}: {
  disabled: boolean
  filters: Partial<CompaniesFiltersType>
  setValue: UseFormReturn<CompaniesFiltersType>['setValue']
  handleUpdateQuery: (arg: string) => void
}) => {
  const fieldNamesMap: { [key in keyof CompaniesFiltersType]: string } = {
    name: 'Name',
    website: 'Website',
    industries: 'Industry',
    countries: 'Country',
    companyType: 'Company type',
    locationName: 'Location',
    locationRegion: 'Region',
    minimumEmployeeCount: 'Minimum employee count',
    maximumEmployeeCount: 'Maximum employee count'
  }

  const transformedFilters = Object.entries(filters).flatMap(([field, value]) => {
    if (!value) return []

    const fieldLabel = fieldNamesMap[field as keyof CompaniesFiltersType]
    return Array.isArray(value) ? value.map((val) => ({ field, fieldLabel, value: val })) : [{ field, fieldLabel, value: `${value}` }]
  })

  const handleDelete = (field: keyof CompaniesFiltersType, value: string) => {
    const currentValue = filters[field]
    if (currentValue) {
      const updatedFilters = { ...filters, [field]: Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : undefined }
      handleUpdateQuery(JSON.stringify(updatedFilters))
      setValue(field, Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : null)
    }
  }

  const filtersCount = Object.keys(transformedFilters).length

  return (
    <AppliedFilters disabled={disabled} filtersCount={filtersCount}>
      <Box display="flex" flexWrap="wrap" gap={1.5} maxHeight="170px" overflow="auto">
        {transformedFilters.map(({ field, fieldLabel, value }) => {
          return (
            <Chip
              key={value}
              label={<FilterChipLabel label={fieldLabel} value={value} />}
              color="secondary"
              size="small"
              onDelete={() => handleDelete(field as keyof CompaniesFiltersType, value)}
            />
          )
        })}
      </Box>
    </AppliedFilters>
  )
}

const CompaniesSearchFilters = ({ opened, contentLoading, loading, disabled, anchorEl, handleChange, total, ...props }: MarketDataFilters) => {
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams>()
  const companiesFilters = queryParams.companiesFilters ? JSON.parse(queryParams.companiesFilters) : {}
  const {
    reset: formReset,
    getValues,
    setValue,
    control,
    register
  } = useForm<CompaniesFiltersType>({
    defaultValues: {
      name: companiesFilters.name || '',
      website: companiesFilters.website || '',
      companyType: companiesFilters.companyType || '',
      countries: companiesFilters.countries || [],
      industries: companiesFilters.industries || [],
      locationName: companiesFilters.locationName || [],
      locationRegion: companiesFilters.locationRegion || [],
      minimumEmployeeCount: +companiesFilters.minimumEmployeeCount || null,
      maximumEmployeeCount: +companiesFilters.maximumEmployeeCount || null
    }
  })
  const { classes } = useStyles()

  useEffect(() => {
    if (!queryParams.companiesFilters && !loading) {
      formReset(resetFilters)
    }
  }, [queryParams.companiesFilters, loading])

  const handleUpdateQuery = (updatedQuery: string) => {
    updateQuery({ ...queryParams, companiesFilters: updatedQuery })
  }

  const reset = () => {
    if (queryParams.companiesFilters) {
      handleUpdateQuery('')
    } else {
      formReset()
    }
  }

  const apply = () => {
    const filters = getPayload(getValues())
    if (Object.keys(filters).length) {
      handleUpdateQuery(JSON.stringify(filters))
    } else {
      reset()
    }
  }

  const filtersCount = Object.keys(companiesFilters).length

  return (
    <Filters
      opened={opened}
      reset={reset}
      disabled={disabled}
      contentLoading={contentLoading}
      anchorEl={anchorEl}
      total={total}
      className={classes.container}
      toggleOpen={props.toggleOpen}
      filtersCount={filtersCount}
    >
      <AppliedFiltersContent disabled={disabled} filters={companiesFilters} setValue={setValue} handleUpdateQuery={handleUpdateQuery} />
      <Content disabled={disabled} register={register} control={control} filtersCount={filtersCount} apply={apply} />
      <Box className={classes.buttonContainer}>
        <Button disabled={disabled} variant="text" color="secondary" onClick={apply} disablePadding>
          Search
        </Button>
      </Box>
    </Filters>
  )
}

export default CompaniesSearchFilters
