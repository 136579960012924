import React, { useContext, useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Empty from '_core/components/Empty'
import { Controller } from '_core/components/filters'
import Filters from '_core/components/filters/SimilarCompanies'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { CompaniesType, FiltersType, transformResponse } from '_core/hooks/useSimilarCompanies'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface SimilarCompaniesProps {
  marketData: CompanyMarketDataType | null
  website: string
  similar: CompaniesType | null
  setSimilar: (val: CompaniesType | null) => void
  loading: boolean
}

const SimilarCompanies = ({ marketData, similar, setSimilar, ...props }: SimilarCompaniesProps) => {
  const { queryParams, updateQuery } = useSearchQuery<{ clearSearch?: 'true' | null; openedFilters?: 'true' | null }>()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const [openedFilters, setOpenedFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<FiltersType | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const notAppliedFilters = useRef<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort } = useAbortableFetch<CompaniesMarketDataRes>()

  const load = loading || props.loading
  const { employeeCount, industry, location, type, website: marketDataWebsite } = marketData || {}
  const website = marketDataWebsite || props.website
  const enoughFilters = marketData && website
  const storedFilters = id && similar?.id && similar?.id === id && !!similar?.filters
  const total = similar?.total || 0

  useEffect(() => {
    setSubHeader(`Similar Companies ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  useEffect(() => {
    if (queryParams.clearSearch === 'true' && setSimilar && !props.loading) {
      updateQuery({ clearSearch: null })
      setSimilar(null)
      setFilters(null)
    }
    if (queryParams.openedFilters === 'true' && !props.loading) {
      updateQuery({ openedFilters: null })
      setOpenedFilters(true)
    }
  }, [queryParams.clearSearch, queryParams.openedFilters, setSimilar, props.loading])

  useEffect(() => {
    if (!filters && storedFilters && !queryParams.clearSearch) {
      setFilters(similar.filters)
      return
    }

    if (!filters && marketData) {
      setInitialFilters()
      return
    }
  }, [filters, marketData, queryParams.clearSearch, storedFilters])

  useEffect(() => {
    if (filters && notAppliedFilters.current && !openedFilters) {
      notAppliedFilters.current = false
      if (storedFilters) {
        setFilters(similar.filters)
        return
      }
      if (marketData) {
        setInitialFilters()
        return
      }
    }
  }, [filters, marketData, notAppliedFilters.current, openedFilters])

  const setNoSimilar = (persistFilters?: boolean) => {
    notAppliedFilters.current = false
    setSimilar({
      id,
      website,
      data: [],
      scrollToken: '',
      total: 0,
      ...(persistFilters && filters && { filters })
    })
  }

  const getSimilar = async (persistFilters?: boolean, loadMore?: boolean) => {
    if (filters && teamNumber) {
      setLoading(true)
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/companies/similar', {
          website,
          titlecase: 'true',
          teamNumber: teamNumber.toString(),
          ...(loadMore && similar?.scrollToken && { scrollToken: similar.scrollToken }),
          ...filters
        })
      })
      if (res) {
        const transformedData = transformResponse(res.data)
        setSimilar({
          id,
          website,
          data: loadMore && similar?.data ? [...similar.data, ...transformedData] : transformedData,
          scrollToken: res.scrollToken || '',
          total: res.total,
          ...(persistFilters && { filters })
        })
        if (persistFilters) {
          notAppliedFilters.current = false
        }
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (filters && !similar && !load) {
      enoughFilters ? getSimilar() : setNoSimilar()
    }
  }, [load, similar, filters, enoughFilters])

  const items = similar?.data?.map((company) => {
    return {
      name: company.name,
      score: company.companyMd5 ? company.score : undefined,
      logoUrl: company.website,
      link: company.companyMd5
        ? `${Paths._companies}/${company.companyMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
            website: company.website
          }),
      sidepanel: true,
      byline: company.website,
      byline2: company.industry
    }
  })

  const setInitialFilters = () => {
    setFilters({
      minimumEmployeeCount: employeeCount ? Math.round(Number(employeeCount) / 2).toString() : '',
      maximumEmployeeCount: employeeCount ? Math.round(Number(employeeCount) * 1.5).toString() : '',
      industries: industry ? [industry] : [],
      countries: enoughFilters ? [location?.country || 'United States'] : [],
      companyType: enoughFilters ? type || 'Private' : ''
    })
  }

  const updateFilters = (updatedFilters: FiltersType) => {
    notAppliedFilters.current = true
    setFilters(updatedFilters)
  }

  const toggleOpen = () => setOpenedFilters((prevState: boolean) => !prevState)
  const applyFilters = () => {
    const emptyFilters = !!filters && Object.entries(filters)?.every(([, value]) => (Array.isArray(value) ? !value.length : !value))
    emptyFilters ? setNoSimilar(true) : getSimilar(true)
  }
  const loadMore = () => getSimilar(true, true)
  const reset = () => {
    notAppliedFilters.current = true
    setInitialFilters()
  }

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <Controller opened={openedFilters} toggleOpen={toggleOpen} disabled={!filters} variant="text" ref={anchorRef} />
        <Filters
          opened={openedFilters}
          disabled={!filters}
          contentLoading={load}
          anchorEl={anchorRef.current}
          total={similar?.total || 0}
          filters={filters}
          updateFilters={updateFilters}
          reset={reset}
          applyFilters={notAppliedFilters.current ? applyFilters : undefined}
        />
        <InfiniteList loading={loading} next={loadMore} dataLength={similar?.total || 0} hasMore={!!similar?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            empty={<Empty title={enoughFilters ? 'No records were found' : 'Not enough market data is available for an effective search filter'} />}
            items={items || []}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default SimilarCompanies
