import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Chip, { FilterChipLabel } from '_core/components/Chip'
import Content, { PeopleFiltersType } from '_core/components/MarketDataPeopleSearchFilters'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Filters, { AppliedFilters } from './index'

export type MarketDataFilters = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  anchorEl?: HTMLElement | null
  total: number | undefined
  loading: boolean
  toggleOpen: () => void
}

const useStyles = makeStyles()((theme) => ({
  container: {
    overflow: 'visible',
    '& div[class*="topBlock"]': {
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      marginBottom: 0,
      width: 'auto',
      marginLeft: `-${theme.spacing(2)}`,
      marginRight: `-${theme.spacing(2)}`
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      width: 270,
      maxHeight: 'calc(100vh - 190px)',
      paddingBottom: 0
    }
  },
  wrapper: {
    position: 'fixed',
    left: 0,
    background: theme.palette.background.light,
    zIndex: 10,
    maxWidth: 'calc(100vw - 5px)',
    width: '100%',
    borderBottom: '1px solid #f2f2f2',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    marginTop: `-${theme.spacing(1.5)}`,
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'sticky',
      top: 59,
      margin: `0 -${theme.spacing(2)}`
    }
  },
  collapseContainer: {
    borderBottom: 0,
    paddingTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%',
    margin: `0 -${theme.spacing(2.5)}`,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.background.light,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      width: 'auto'
    }
  }
}))

const getPayload = (filters: PeopleFiltersType) =>
  Object.entries(filters)
    .filter(([, value]) => (Array.isArray(value) ? value.length : !!value))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: Array.isArray(value) ? value : `${value}` }), {})

const resetFilters: PeopleFiltersType = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  companySize: null,
  companyNames: [],
  jobTitleLevels: [],
  jobTitles: [],
  jobTitleRoles: [],
  jobTitleSubroles: [],
  countries: [],
  industries: [],
  locationName: [],
  locationRegion: [],
  educationSchoolName: [],
  skills: []
}

const AppliedFiltersContent = ({
  disabled,
  filters,
  setValue,
  handleUpdateQuery,
  setHeight
}: {
  disabled: boolean
  filters: Partial<PeopleFiltersType>
  setValue: UseFormReturn<PeopleFiltersType>['setValue']
  handleUpdateQuery: (arg: string) => void
  setHeight: (arg: number) => void
}) => {
  const filterFieldLabels: { [key in keyof PeopleFiltersType]: string } = {
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email address',
    companySize: 'Company size',
    companyNames: 'Company name',
    jobTitles: 'Job title',
    jobTitleRoles: 'Job area',
    jobTitleSubroles: 'Job sub-area',
    jobTitleLevels: 'Seniority level',
    countries: 'Country',
    industries: 'Industry',
    locationName: 'Location',
    locationRegion: 'Region',
    educationSchoolName: 'School name',
    skills: 'Skill'
  }

  const transformedFilters = Object.entries(filters).flatMap(([field, value]) => {
    if (!value) return []

    const fieldLabel = filterFieldLabels[field as keyof PeopleFiltersType]
    return Array.isArray(value) ? value.map((val) => ({ field, fieldLabel, value: val })) : [{ field, fieldLabel, value: `${value}` }]
  })

  const handleDelete = (field: keyof PeopleFiltersType, value: string) => {
    const currentValue = filters[field]
    if (currentValue) {
      const updatedFilters = { ...filters, [field]: Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : undefined }
      handleUpdateQuery(JSON.stringify(updatedFilters))
      setValue(field, Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : null)
    }
  }

  const filtersCount = Object.keys(transformedFilters).length

  return (
    <AppliedFilters disabled={disabled} filtersCount={filtersCount} setHeight={setHeight}>
      <Box display="flex" flexWrap="wrap" gap={1.5} maxHeight="170px" overflow="auto">
        {transformedFilters.map(({ field, fieldLabel, value }) => {
          return (
            <Chip
              key={value}
              label={<FilterChipLabel label={fieldLabel} value={value} />}
              color="secondary"
              size="small"
              onDelete={() => handleDelete(field as keyof PeopleFiltersType, value)}
            />
          )
        })}
      </Box>
    </AppliedFilters>
  )
}

const FiltersContent = ({
  disabled,
  peopleForm,
  handleUpdateQuery,
  peopleFilters,
  apply
}: {
  disabled: boolean
  peopleForm: UseFormReturn<PeopleFiltersType>
  handleUpdateQuery: (arg: string) => void
  peopleFilters: Partial<PeopleFiltersType>
  apply: () => void
}) => {
  const [appliedFiltersHeight, setAppliedFiltersHeight] = useState(0)

  return (
    <Box position="relative">
      <AppliedFiltersContent
        disabled={disabled}
        setHeight={setAppliedFiltersHeight}
        filters={peopleFilters}
        setValue={peopleForm.setValue}
        handleUpdateQuery={handleUpdateQuery}
      />
      <Content appliedFiltersHeight={appliedFiltersHeight} disabled={disabled} applyFilters={apply} {...peopleForm} />
    </Box>
  )
}

const PeopleSearchFilters = ({ opened, contentLoading, loading, disabled, anchorEl, handleChange, total, ...props }: MarketDataFilters) => {
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams>()
  const peopleFilters = queryParams.peopleFilters ? JSON.parse(queryParams.peopleFilters) : {}
  const peopleForm = useForm<PeopleFiltersType>({
    defaultValues: {
      firstName: peopleFilters.firstName || '',
      lastName: peopleFilters.lastName || '',
      emailAddress: peopleFilters.emailAddress || '',
      companySize: peopleFilters.companySize || null,
      companyNames: peopleFilters.companyNames || [],
      jobTitleLevels: peopleFilters.jobTitleLevels || [],
      jobTitles: peopleFilters.jobTitles || [],
      jobTitleRoles: peopleFilters.jobTitleRoles || [],
      jobTitleSubroles: peopleFilters.jobTitleSubroles || [],
      countries: peopleFilters.countries || [],
      industries: peopleFilters.industries || [],
      locationName: peopleFilters.locationName || [],
      locationRegion: peopleFilters.locationRegion || [],
      educationSchoolName: peopleFilters.educationSchoolName || [],
      skills: peopleFilters.skills || []
    }
  })
  const { classes } = useStyles()

  useEffect(() => {
    if (!queryParams.peopleFilters && !loading) {
      peopleForm.reset(resetFilters)
    }
  }, [queryParams.peopleFilters, loading])

  const handleUpdateQuery = (updatedQuery: string) => {
    updateQuery({ ...queryParams, peopleFilters: updatedQuery })
  }

  const reset = () => {
    if (queryParams.peopleFilters) {
      handleUpdateQuery('')
    } else {
      peopleForm.reset(resetFilters)
    }
  }

  const apply = () => {
    const filters = getPayload(peopleForm.getValues())
    if (Object.keys(filters).length) {
      handleUpdateQuery(JSON.stringify(filters))
    } else {
      reset()
    }
  }

  const filtersCount = Object.keys(peopleFilters).length

  return (
    <Filters
      opened={opened}
      reset={reset}
      disabled={disabled}
      contentLoading={contentLoading}
      anchorEl={anchorEl}
      total={total}
      className={classes.container}
      toggleOpen={props.toggleOpen}
      filtersCount={filtersCount}
    >
      <FiltersContent disabled={disabled} peopleFilters={peopleFilters} peopleForm={peopleForm} handleUpdateQuery={handleUpdateQuery} apply={apply} />
      <Box className={classes.buttonContainer}>
        <Button disabled={disabled} variant="text" color="secondary" onClick={apply} disablePadding>
          Search
        </Button>
      </Box>
    </Filters>
  )
}

export { Controller } from './index'
export default PeopleSearchFilters
