import { useCallback, useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { capitalizeWord } from '_core/helpers/string'

import { mergeUrlWithParams } from 'utils/httpUtils'

export const transformPersonData = (person: PersonMarketDataRes | undefined): PersonMarketDataType => ({
  id: person?.id || '',
  fullName: person?.full_name || '',
  locationName: person?.location_name || '',
  twitterUrl: person?.twitter_url || '',
  linkedinUrl: person?.linkedin_url || '',
  githubUrl: person?.github_url || '',
  industry: person?.industry || '',
  locationCountry: person?.location_country || '',
  jobTitle: person?.job_title || '',
  jobTitleRole: person?.job_title_role || '',
  jobTitleSubRole: person?.job_title_sub_role || '',
  jobTitleLevels: (person?.job_title_levels || []).map((title: string) => capitalizeWord(title)),
  jobCompanyName: person?.job_company_name || '',
  jobCompanyWebsite: person?.job_company_website || '',
  jobCompanySize: person?.job_company_size || '',
  jobCompanyLocationName: person?.job_company_location_name || '',
  jobCompanyIndustry: person?.job_company_industry || '',
  jobCompanyFacebookUrl: person?.job_company_facebook_url || '',
  jobCompanyTwitterUrl: person?.job_company_twitter_url || '',
  jobCompanyLinkedinUrl: person?.job_company_linkedin_url || '',
  jobStartDate: person?.job_start_date || '',
  jobCompanyMd5: person?.jobCompanyMd5 || '',
  jobLastChanged: person?.job_last_changed || '',
  workEmail: person?.work_email || '',
  skills: person?.skills || [],
  interests: person?.interests || [],
  experience: (person?.experience || []).map(({ title, company, start_date, end_date }) => ({
    title: { name: title?.name || '', levels: title?.levels || [], role: title?.role || '', subRole: title?.sub_role || '' },
    company: { name: company?.name || '', website: company?.website || '' },
    startDate: start_date || '',
    endDate: end_date || ''
  })),
  education: (person?.education || []).map(({ degrees, majors, school, start_date, end_date }) => ({
    degrees: degrees || [],
    majors: majors || [],
    school: { name: school?.name || '', website: school?.website || '', locationName: school?.location?.name || '' },
    startDate: start_date || '',
    endDate: end_date || ''
  })),
  phoneNumbers: person?.phone_numbers || [],
  mobilePhone: person?.mobile_phone || ''
})

export const usePersonMarketData = (name: string, email?: string, company?: string) => {
  const { teamContextValue } = useContext(TeamContext)
  const [marketData, setMarketData] = useState<PersonMarketDataType | null>(null)
  const { fetchWithAbort } = useAbortableFetch<{ data: PersonMarketDataRes }>()

  useEffect(() => {
    return () => {
      setMarketData(null)
    }
  }, [name])

  const getMarketData = useCallback(async () => {
    if (name && (email || company)) {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/people/enrich', {
          name,
          email,
          company,
          teamNumber: `${teamContextValue.teamNumber}`,
          titlecase: 'true'
        })
      })
      setMarketData(transformPersonData(res?.data))
    }
  }, [name, email, company, teamContextValue.teamNumber])

  return {
    marketData,
    setMarketData,
    getMarketData
  }
}
