import React, { SyntheticEvent, useRef } from 'react'

import { Box } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import Combobox from '_shared/forms/Combobox'
import FormLabel from '_shared/forms/FormLabel'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import { CompaniesFiltersType } from '_core/components/filters/MarketDataCompaniesSearch'
import { companyTypeOptions } from '_core/components/MarketDataFieldPicker'
import {
  CountriesController,
  IndustriesController,
  LocationNamesController,
  LocationRegionsController
} from '_core/components/MarketDataSearchFilters'

import useResizeObserver from '_core/hooks/useResizeObserver'

const preventDefault = (e: SyntheticEvent) => (e.target as HTMLInputElement).blur()

const useStyles = makeStyles<{ filtersCount?: number }>()((theme, { filtersCount }) => ({
  content: {
    marginTop: filtersCount ? theme.spacing(7) : 0,
    marginBottom: theme.spacing(6.5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2.5),
      marginBottom: 0
    }
  },
  block: {
    paddingBottom: theme.spacing(1.5)
  },
  divider: {
    padding: theme.spacing(1.5)
  }
}))

const MarketDataCompaniesSearchFilters = ({
  disabled,
  register,
  control,
  filtersCount,
  apply
}: {
  disabled: boolean
  filtersCount: number
  apply: () => void
} & Pick<UseFormReturn<CompaniesFiltersType>, 'register' | 'control'>) => {
  const inputsRefs = useRef<{ [key in keyof CompaniesFiltersType]: HTMLInputElement | null }>({
    name: null,
    website: null,
    industries: null,
    countries: null,
    locationName: null,
    locationRegion: null,
    maximumEmployeeCount: null,
    minimumEmployeeCount: null,
    companyType: null
  })
  useResizeObserver(inputsRefs.current)
  const { classes } = useStyles({ filtersCount })

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      apply()
    }
  }

  const handleChange = (v: string[], onChange: (v: string[]) => void) => {
    onChange(v)
    apply()
  }

  const handleDelete = (removableValue: string, value: string[], onChange: (v: string[]) => void) => {
    onChange(value.filter((el: string) => el !== removableValue))
    apply()
  }

  return (
    <Box className={classes.content}>
      <Box className={classes.block}>
        <TextField
          label="Name"
          {...register('name')}
          inputRef={(el: HTMLInputElement) => (inputsRefs.current['name'] = el)}
          placeholder="E.g. Dotalign"
          disabled={disabled}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Box>
      <Box className={classes.block}>
        <TextField
          label="Website"
          {...register('website')}
          inputRef={(el: HTMLInputElement) => (inputsRefs.current['website'] = el)}
          placeholder="E.g. dotalign.com"
          disabled={disabled}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Box>
      <Box className={classes.block}>
        <IndustriesController
          control={control}
          disabled={disabled}
          handleChange={handleChange}
          handleDelete={handleDelete}
          inputRef={(el) => (inputsRefs.current['industries'] = el)}
        />
      </Box>
      <Box className={classes.block}>
        <CountriesController
          control={control}
          disabled={disabled}
          handleChange={handleChange}
          handleDelete={handleDelete}
          inputRef={(el) => (inputsRefs.current['countries'] = el)}
        />
      </Box>
      <Box className={classes.block}>
        <LocationNamesController
          control={control}
          disabled={disabled}
          handleChange={handleChange}
          handleDelete={handleDelete}
          inputRef={(el) => (inputsRefs.current['locationName'] = el)}
        />
      </Box>
      <Box className={classes.block}>
        <LocationRegionsController
          control={control}
          disabled={disabled}
          handleChange={handleChange}
          handleDelete={handleDelete}
          inputRef={(el) => (inputsRefs.current['locationRegion'] = el)}
        />
      </Box>
      <Box className={classes.block}>
        <Controller
          name="companyType"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Combobox<MarketDataCompanyType, false, boolean>
              label="Company type"
              icon={['far', 'arrow-up-small-big']}
              placeholder="Pick an option"
              options={companyTypeOptions}
              disabled={disabled}
              value={value || null}
              inputRef={(el) => (inputsRefs.current[name] = el)}
              onChange={(e, v) => {
                onChange(v)
                apply()
              }}
              disableClearable={!value}
              blurOnSelect
              filterSelectedOptions
            />
          )}
        />
      </Box>
      <Box className={classes.block}>
        <FormLabel label="Employee count" />
        <Box display="flex" alignItems="center">
          <TextField
            {...register('minimumEmployeeCount', {
              valueAsNumber: true
            })}
            inputRef={(el) => (inputsRefs.current['minimumEmployeeCount'] = el)}
            disabled={disabled}
            fullWidth
            onWheel={preventDefault}
            onKeyDown={handleKeyDown}
            type="number"
          />
          <Typography component="span" className={classes.divider}>
            -
          </Typography>
          <TextField
            {...register('maximumEmployeeCount', {
              valueAsNumber: true
            })}
            inputRef={(el) => (inputsRefs.current['maximumEmployeeCount'] = el)}
            disabled={disabled}
            fullWidth
            onWheel={preventDefault}
            onKeyDown={handleKeyDown}
            type="number"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MarketDataCompaniesSearchFilters
