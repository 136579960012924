import React, { ComponentProps, ReactElement, useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, TypographyProps } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Popover from '_shared/popovers/Popover'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AffiliationPopover } from '_core/components/Affiliation'
import { IsPrivateRelationshipBudge } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import IsInNetworkIcon from '_core/components/IsInNetworkIcon'
import { activityStatsEntryPointOnboardingTarget } from '_core/components/onboarding/ActivityStats'
import SidepanelLink from '_core/components/SidepanelLink'
import StatusIcon from '_core/components/StatusIcon'
import TagsGroup from '_core/components/TagsGroup'

import useOnboarding from '_core/hooks/useOnboarding'
import { groupTags } from '_core/hooks/useTagsManager'

import { formatDateTime, formatDateFromNow } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  status: {
    textAlign: 'center',
    width: '100%'
  },
  fitContentWidth: {
    maxWidth: 'fit-content'
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px',
    minWidth: '16px'
  }
}))

export const TextCell = React.memo(
  ({ value, bold, color, className }: { value: string; bold?: boolean; color?: TypographyProps['color']; className?: string }) => {
    const { classes } = useStyles()
    return (
      <Tooltip title={value}>
        <Typography color={color || 'inherit'} bold={bold} noWrap classes={{ root: classes.fitContentWidth }} className={className}>
          {value}
        </Typography>
      </Tooltip>
    )
  }
)

export const TextWithIconCell = React.memo(
  ({ text, bold, textColor, icon }: { text: string; icon: IconProp; bold?: boolean; textColor?: TypographyProps['color'] }) => {
    const { classes } = useStyles()
    return (
      <Tooltip title={text}>
        <Box display="flex" alignItems="center">
          <FontAwesomeIcon icon={icon} className={classes.icon} />
          <Typography color={textColor || 'inherit'} bold={bold} noWrap classes={{ root: classes.fitContentWidth }}>
            {text}
          </Typography>
        </Box>
      </Tooltip>
    )
  }
)

export const DescriptionCell = React.memo(({ value }: { value: string }) => (
  <Popover placement="bottom" maxWidth={400} triggerElement={<Typography noWrap>{value}</Typography>}>
    <Typography>{value}</Typography>
  </Popover>
))

export const MultipleLinedCell = ({
  startIcon,
  title,
  byline1,
  byline2,
  alignment,
  endIcons
}: {
  startIcon?: ReactElement
  title: ReactElement | string
  byline1: ReactElement | string
  byline2?: ReactElement | string
  alignment?: 'flex-end' | 'center'
  endIcons?: ReactElement
}) => {
  const { classes } = useStyles()
  return (
    <Box display="flex" alignItems="center" maxWidth="100%">
      {startIcon}
      <Box display="flex" flexDirection="column" alignItems={alignment || 'flex-start'} lineHeight="1.2" overflow="hidden" ml={startIcon ? 1 : 0}>
        {typeof title === 'string' ? <TextCell value={title} /> : title}
        {typeof byline1 === 'string' ? (
          <Tooltip title={byline1}>
            <Typography classes={{ root: classes.fitContentWidth }} style={{ fontSize: 14 }} color="textSecondary" noWrap>
              {byline1}
            </Typography>
          </Tooltip>
        ) : (
          byline1
        )}
        {typeof byline2 === 'string' ? (
          <Tooltip title={byline1}>
            <Typography classes={{ root: classes.fitContentWidth }} style={{ fontSize: 14 }} color="textSecondary" noWrap>
              {byline1}
            </Typography>
          </Tooltip>
        ) : (
          byline2
        )}
      </Box>
      {endIcons}
    </Box>
  )
}

export const SidepanelLinkCell = React.memo(
  (props: { onClick: (id: GridRowId) => void; id: GridRowId; value: string; link: string; sidepanel: SidepanelType }) => {
    const { onClick, id, value, link, sidepanel } = props
    const { classes } = useStyles()

    return (
      <SidepanelLink linkProps={{ to: link, className: classes.link }} sidepanel={sidepanel} onClick={() => onClick(id)}>
        <TextCell value={value} />
      </SidepanelLink>
    )
  }
)

export const DateCell = React.memo(({ value, label }: { value: string; label: string }) => (
  <>
    {value ? (
      <Tooltip title={value}>
        <Typography color="text.secondary" semiBold>
          {label}
        </Typography>
      </Tooltip>
    ) : null}
  </>
))

export const EmailCell = React.memo(({ value }: { value: string }) => (
  <Tooltip title={value}>
    <Typography color="primary.main" noWrap>
      <a href={`mailto:${value}`} onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}>
        {value}
      </a>
    </Typography>
  </Tooltip>
))

const NameCellIcons = (params: GridRenderCellParams) => {
  const { startOnboarding, active } = useOnboarding()
  const apiRef = useGridApiContext()

  const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id)

  useEffect(() => {
    if (params.row.activityStatsLink) {
      startOnboarding('activityStatsEntryPoint')
    }
  }, [params.row.activityStatsLink])

  const { cx } = useStyles()
  return (
    <Box display="flex" alignItems="center">
      {params.row.formerJob && (
        <Box ml={1.5}>
          <AffiliationPopover workExperience={params.row.workExperience} icon={['far', 'address-book']} />
        </Box>
      )}
      {params.row.isPrivateRelations && <IsPrivateRelationshipBudge userName={params.row.name} />}
      {params.row.isInNetwork && (
        <Box ml={1}>
          <IsInNetworkIcon name={params.row.name} />
        </Box>
      )}
      {params.row.tags && (
        <Box ml={1.5} display="flex" alignItems="center">
          <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
            items={params.row.tags}
            tagComponent={InternalTagPopover}
            renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
              <ExtraTagsPopover
                triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={params.row.showAllTagsLink} />}
                items={params.row.tags ? groupTags(params.row.tags) : []}
              />
            )}
            addTagTriggerEl={<AddTagTriggerEl className="hoveredRowActions" link={params.row.editTagsLink} hasAny={true} />}
          />
          {params.row.auditLink && (
            <SidepanelLink linkProps={{ to: `${params.row.auditLink}` }}>
              <IconButton className="hoveredRowActions" size="small" icon={['far', 'list-check']} hint="Audit" />
            </SidepanelLink>
          )}
          {params.row.activityStatsLink && (
            <SidepanelLink linkProps={{ to: `${params.row.activityStatsLink}` }}>
              <IconButton
                className={cx(
                  { hoveredRowActions: rowIndex === 0 ? active !== 'activityStatsEntryPoint' : true },
                  activityStatsEntryPointOnboardingTarget
                )}
                size="small"
                icon={['far', 'wave-pulse']}
                hint="Open activity statistics"
              />
            </SidepanelLink>
          )}
        </Box>
      )}
    </Box>
  )
}

export const ActionCell = React.memo(({ action }: { action: ReactElement }) => <>{action}</>)

export const NameCell = React.memo((params: GridRenderCellParams) => {
  const { classes } = useStyles()

  return (
    <Box width={1} display="grid" alignItems="center" gridTemplateColumns="minmax(0, auto) 1fr" height="100%">
      {params.row.name && (
        <>
          <SidepanelLink
            linkProps={{ to: params.row.linkAlt || params.row.link, className: classes.link }}
            sidepanel={params.row.sidepanel}
            onClick={() => params.row.onClick(params.id)}
          >
            <Box display="grid" alignItems="center" gridTemplateColumns="1fr minmax(0px, auto)">
              <AvatarWrapper name={params.value.toString()} userKey={params.row.email} logoUrl={params.row.logoUrl} size="xs" mode="dark" hideName />
              <Box ml={1}>
                <TextCell value={params.row.name} />
              </Box>
            </Box>
          </SidepanelLink>
          <NameCellIcons {...params} />
        </>
      )}
    </Box>
  )
})

export const nameColumn: GridColDef = {
  field: 'name',
  headerName: 'Name',
  width: 280,
  minWidth: 280,
  flex: 1,
  hideable: false,
  pinnable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <NameCell {...params} />
}

export const jobTitleColumn: GridColDef = {
  field: 'title',
  headerName: 'Job title',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.title} />
}

export const companyColumn: GridColDef = {
  field: 'company',
  headerName: 'Company',
  width: 200,
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { company = {}, onClick } = row
    return <SidepanelLinkCell value={company.name} id={id} onClick={onClick} link={company.link} sidepanel={company.sidepanel} />
  }
}

export const keyContactColumn: GridColDef = {
  field: 'keyContact',
  headerName: 'Key contact',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { keyContact = {}, onClick } = row
    return <SidepanelLinkCell value={keyContact.name} id={id} onClick={onClick} link={keyContact.link} sidepanel={keyContact.sidepanel} />
  }
}

export const bestIntroducerColumn: GridColDef = {
  field: 'bestIntroducer',
  headerName: 'Best introducer',
  width: 200,
  minWidth: 120,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { bestIntroducer = {}, onClick } = row
    return <SidepanelLinkCell value={bestIntroducer.name} id={id} onClick={onClick} link={bestIntroducer.link} sidepanel={bestIntroducer.sidepanel} />
  }
}

export const scoreColumn: GridColDef = {
  field: 'score',
  headerName: 'Score',
  align: 'right',
  headerAlign: 'right',
  width: 80,
  minWidth: 80,
  type: 'number',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.score} />
}

export const emailColumn: GridColDef = {
  field: 'email',
  headerName: 'Email',
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <EmailCell value={params.row.email} />
}

export const phoneColumn: GridColDef = {
  field: 'phone',
  headerName: 'Phone',
  width: 180,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.phone} />
}

export const dateColumn: GridColDef = {
  field: 'date',
  headerName: 'Date',
  width: 195,
  minWidth: 100,
  flex: 0.7,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { date } = params.row
    const formatted = formatDateTime(date)
    return date ? <DateCell value={formatted} label={formatted} /> : <Typography color="text.secondary">no date</Typography>
  }
}

export const actionButtonColumn: GridColDef = {
  field: 'actionButton',
  headerName: 'Action',
  align: 'center',
  headerAlign: 'center',
  width: 300,
  minWidth: 200,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <ActionCell action={params.row.actionButton} />
}

const NameWithEmailCell = React.memo((params: GridRenderCellParams) => {
  const { email } = params.row
  return (
    <MultipleLinedCell
      title={<NameCell {...params} />}
      byline1={
        <Tooltip title={email}>
          <Typography color="text.secondary" noWrap>
            <a href={`mailto:${email}`} onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}>
              {email}
            </a>
          </Typography>
        </Tooltip>
      }
    />
  )
})

const NameWithByLinesCell = React.memo((params: GridRenderCellParams) => {
  const { classes } = useStyles()
  const { byline, byline2 } = params.row

  return (
    <Box display="flex" alignItems="center">
      <MultipleLinedCell
        startIcon={
          <AvatarWrapper name={params.value?.toString()} userKey={params.row.email} logoUrl={params.row.logoUrl} size="xs" mode="dark" hideName />
        }
        title={
          <SidepanelLink
            linkProps={{ to: params.row.linkAlt || params.row.link, className: classes.link }}
            sidepanel={params.row.sidepanel}
            onClick={() => params.row.onClick(params.id)}
          >
            <Box display="grid" alignItems="center" gridTemplateColumns="1fr minmax(0px, auto)">
              <TextCell value={params.row.name} />
            </Box>
          </SidepanelLink>
        }
        byline1={
          <Tooltip title={byline}>
            <Typography color="text.secondary" noWrap maxWidth="100%">
              {byline}
            </Typography>
          </Tooltip>
        }
        byline2={
          <Tooltip title={byline2}>
            <Typography color="text.secondary" noWrap maxWidth="100%">
              {byline2}
            </Typography>
          </Tooltip>
        }
        endIcons={<NameCellIcons {...params} />}
      />
    </Box>
  )
})

export const nameWithEmailColumn: GridColDef = {
  ...nameColumn,
  renderCell: (params: GridRenderCellParams) => <NameWithEmailCell {...params} />
}

export const nameWithByLinesColumn: GridColDef = {
  ...nameColumn,
  renderCell: (params: GridRenderCellParams) => <NameWithByLinesCell {...params} />
}

export const lastInboundColumn: GridColDef = {
  field: 'lastInboundMsg',
  headerName: 'Last inbound',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastInbound && <DateCell value={formatDateTime(params.row.lastInbound)} label={formatDateFromNow(params.row.lastInbound, 3)} />
}

export const lastOutboundColumn: GridColDef = {
  field: 'lastOutboundMsg',
  headerName: 'Last outbound',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastOutbound && <DateCell value={formatDateTime(params.row.lastOutbound)} label={formatDateFromNow(params.row.lastOutbound, 3)} />
}

export const lastMeetingColumn: GridColDef = {
  field: 'lastMeeting',
  headerName: 'Last meeting',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) =>
    params.row.lastMeeting && <DateCell value={formatDateTime(params.row.lastMeeting)} label={formatDateFromNow(params.row.lastMeeting, 3)} />
}

export const nextFutureMeetingColumn: GridColDef = {
  field: 'nextFutureMeeting',
  headerName: 'Next meeting',
  minWidth: 120,
  flex: 0.6,
  type: 'dateTime',
  sortable: true,
  filterable: true,
  renderCell: (params: GridRenderCellParams) =>
    params.row.nextFutureMeeting && (
      <DateCell value={formatDateTime(params.row.nextFutureMeeting)} label={formatDateFromNow(params.row.nextFutureMeeting, 3)} />
    )
}

const StatusCell = React.memo((params: GridRenderCellParams) => {
  const { classes } = useStyles()
  return (
    <Box width="100%">
      <StatusIcon status={params.row.status} className={classes.status} />
    </Box>
  )
})

export const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  width: 100,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <StatusCell {...params} />
}

export const locationColumn: GridColDef = {
  field: 'location',
  headerName: 'Location',
  width: 200,
  minWidth: 200,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.location} />
}

export const industryColumn: GridColDef = {
  field: 'industry',
  headerName: 'Industry',
  width: 200,
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.industry} />
}

export const websiteColumn: GridColDef = {
  field: 'website',
  headerName: 'Website',
  width: 200,
  minWidth: 130,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { website } = params.row
    return (
      website && (
        <Tooltip title={website}>
          <Typography color="primary.main" noWrap>
            <a
              href={`https://${website}`}
              target="_blank"
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
              rel="noreferrer"
            >
              {website}
            </a>
          </Typography>
        </Tooltip>
      )
    )
  }
}

export const editorColumn: GridColDef = {
  field: 'editor',
  headerName: 'Updated By',
  width: 160,
  minWidth: 160,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { id, editor, onClick, editorLink, sidepanel } = params.row
    return editor ? (
      <SidepanelLinkCell value={editor} id={id} onClick={onClick} link={editorLink} sidepanel={sidepanel} />
    ) : (
      <Typography color="text.secondary">not specified</Typography>
    )
  }
}

export const inboundCountColumn: GridColDef = {
  ...scoreColumn,
  field: 'inboundCount',
  headerName: 'Inbound',
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <Skeleton condition={params.row.inbound && params.row.inbound < 0} width={40}>
      <TextCell value={params.row.inbound} />
    </Skeleton>
  )
}

export const outboundCountColumn: GridColDef = {
  ...inboundCountColumn,
  field: 'outboundCount',
  headerName: 'Outbound',
  renderCell: (params: GridRenderCellParams) => (
    <Skeleton condition={params.row.outbound && params.row.outbound < 0} width={40}>
      <TextCell value={params.row.outbound} />
    </Skeleton>
  )
}

export const meetingsCountColumn: GridColDef = {
  ...inboundCountColumn,
  field: 'meetingsCount',
  headerName: 'Meetings',
  renderCell: (params: GridRenderCellParams) => (
    <Skeleton condition={params.row.meetings && params.row.meetings < 0} width={40}>
      <TextCell value={params.row.meetings} />
    </Skeleton>
  )
}
