import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { employeeCountColumn, typeColumn } from '_pages/search/columns'

import LoadingButton from '_shared/buttons/LoadingButton'

import ExpandableDetails from '_core/components/ExpandableDetails'
import { CompaniesFiltersType } from '_core/components/filters/MarketDataCompaniesSearch'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { industryColumn, locationColumn, nameColumn, scoreColumn, websiteColumn } from '_core/components/grid/columns'
import IsInNetworkIcon from '_core/components/IsInNetworkIcon'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { capitalizeWord } from '_core/helpers/string'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const Results = ({
  loading,
  isNextLoading,
  hasMore,
  more,
  viewMode,
  ...props
}: {
  total: number
  items: CompaniesMarketDataRes['data']
  loading: boolean
  hasMore: boolean
  more: () => void
  isNextLoading: boolean
  viewMode: ViewModeType
} & GridPaginationType) => {
  const isExpandedView = viewMode === 'expanded'

  const items = props.items.map((company, i) => ({
    id: `${i}-${company.id}`,
    name: company.name || '',
    website: company.website,
    employeeCount: company.employee_count,
    industry: company.industry,
    location: company.location?.name,
    type: company.type ? capitalizeWord(company.type.split('_').join(' ')) : '',
    link: company.companyMd5
      ? `${Paths._companies}/${company.companyMd5}`
      : mergeUrlWithParams(`${Paths._companies}/${company.website || company.name}`, {
          name: company.name,
          website: company.website
        }),
    isInNetwork: !!company.score,
    score: company.score
  }))

  const loadMore = (
    <LoadingButton
      loading={isNextLoading}
      disabled={loading}
      fullWidth
      variant="link"
      onClick={more}
      endIcon={<FontAwesomeIcon icon={['far', 'arrow-rotate-right']} style={{ fontSize: 16 }} />}
    >
      Show more
    </LoadingButton>
  )

  return (
    <>
      <Wide>
        <DataGrid
          rows={items}
          loading={loading}
          columns={[nameColumn, websiteColumn, industryColumn, locationColumn, typeColumn, employeeCountColumn, scoreColumn]}
          footerControls={hasMore ? [loadMore] : []}
          controls={[]}
          autoHeight={false}
          total={props.total}
          paging={props.paging}
          setPageSize={props.setPageSize}
        />
      </Wide>
      <Narrow>
        <Widget scope="list">
          <Repeater
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading }}
            variant={isExpandedView ? 'card' : 'list'}
            items={items.map(({ name, isInNetwork, score, website, industry, location, employeeCount, type }) => ({
              variant: 'expandable',
              name: name,
              icons: isInNetwork && <IsInNetworkIcon name={name} />,
              score: score,
              byline: website || '',
              byline3: (
                <ExpandableDetails
                  isExpandedView={isExpandedView}
                  items={[
                    { field: '', value: industry, title: 'Industry', icon: ['fas', 'industry'] },
                    { field: '', value: location, title: 'Location', icon: ['fas', 'location-dot'] },
                    { field: '', value: employeeCount?.toString(), title: 'Employee count', icon: ['far', 'users'] },
                    { field: '', value: type, title: 'Company type', icon: ['far', 'building'] }
                  ]}
                />
              )
            }))}
          />
        </Widget>
      </Narrow>
    </>
  )
}

const CompaniesResults = ({
  teamNumber,
  ...props
}: {
  teamNumber: number
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
}) => {
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])

  const { keyword, companiesFilters, viewMode, rowsPerPage = '10' } = queryParams
  const filters: Partial<CompaniesFiltersType> = companiesFilters ? JSON.parse(companiesFilters) : {}
  const params: { name: Capitalize<keyof CompaniesFiltersType> | 'Size'; value?: ValueOf<CompaniesFiltersType> }[] = [
    { name: 'Name', value: filters.name },
    { name: 'Website', value: filters.website },
    { name: 'MinimumEmployeeCount', value: filters.minimumEmployeeCount },
    { name: 'MaximumEmployeeCount', value: filters.maximumEmployeeCount },
    { name: 'Industries', value: filters.industries },
    { name: 'Countries', value: filters.countries },
    { name: 'LocationName', value: filters.locationName },
    { name: 'LocationRegion', value: filters.locationRegion },
    { name: 'CompanyType', value: filters.companyType },
    { name: 'Size', value: rowsPerPage }
  ]
  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  const url = mergeUrlWithParams('/cloudhub/companies/advancedsearch', { ...payload, teamNumber: `${teamNumber}` })

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage })
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: { viewMode: ViewModeType } } }>
      url={url}
      onLoading={props.onLoading}
      component={Results}
      addprops={{
        viewMode: viewMode || 'collapsed'
      }}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      keepMounted
      list
      infinite="click"
      empty="No records found"
      search
      emptySubtitle={keyword ? `No records found for your search '${keyword}'` : ''}
      id="advanced_companies_search"
    />
  )
}

export default CompaniesResults
