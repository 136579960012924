import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { PrivilegedMember } from '_pages/data-administration/privileged-companies/members/add'

import { TeamContext } from '_core/context/TeamContext'

import { IMenuAction } from '_shared/buttons/FloatingMenuButton'

import RemoveSelectedDialog from '_core/components/dialogs/RemoveSelected'
import { ExpandableContent as Content } from '_core/components/ExpandableDetails'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { dateColumn, editorColumn, emailColumn, nameColumn } from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { Buttons } from '_core/components/PrivilegedHeading'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import { useLookUpPeopleByIds } from '_core/hooks/useLookup'
import usePrivilegedPage from '_core/hooks/usePrivilegedPage'

import DynamicEntity from '_core/DynamicEntity'

import { del } from 'utils/httpUtils'
import { formatDateTime } from 'utils/Utils'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sidepanel')]: {
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
    }
  },
  widget: {
    minHeight: 'calc(100vh - 255px)'
  }
}))

type MembersProps = {
  loading: boolean
  items: PrivilegedMember[]
  teamNumber: number
  handleReload: () => void
} & GridPaginationType

const Action = () => {
  const action: IMenuAction = {
    label: 'Add users',
    icon: ['far', 'user-plus'],
    link: `${DataAdminSubPaths.privilegedCompanies}/members/add`,
    sidepanel: true
  }

  return <Buttons action={action} />
}

// only those colleagues can access the "privcos" (companies where privileged access is required)
const PrivilegedMembersPage = () => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { onLoading, onPageSizeChange, reload, handleReload, loading, rowsPerPage, keyword } = usePrivilegedPage()
  const membersUrl = useMemo(() => `/teams/${teamNumber}/privilegedcomembers`, [teamNumber])
  const { classes } = useStyles()

  const Component = (
    <DynamicEntity<{ extraProps: { addprops: Pick<MembersProps, 'teamNumber' | 'handleReload'> } }>
      url={!reload ? membersUrl : null}
      component={MembersList}
      onLoading={onLoading}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      addprops={{ teamNumber, handleReload }}
      empty="No users found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      list
      infinite
      search
      keepMounted
      id="priv-companies-members"
    />
  )

  return (
    <GridPageFrame
      loading={loading}
      gridTitle="Users"
      searchPlaceholder="Search for users by email"
      component={Component}
      filters={<></>}
      disabledSearch={loading}
      heading={<></>}
      headingAdornmentEnd={Action}
      widgetClassName={classes.widget}
    />
  )
}

const MembersList = ({ teamNumber, handleReload, ...props }: MembersProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const { isDialogOpened: isRemoveDialogOpened, openDialog: openRemoveDialog, closeDialog: closeRemoveDialog, openSuccess, successMode } = useDialog()
  const { lookUpPeopleByIds, peopleByIds: fullData, loading: fullDataLoading } = useLookUpPeopleByIds()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.items.length) {
      const ids = props.items.map((item) => (typeof item === 'string' ? item : item.appUserKey))
      lookUpPeopleByIds(ids)
    }
  }, [props.items])

  const showFullInfo = !!props.items.length && props.items.some((item) => typeof item !== 'string')
  const loading = props.loading || fullDataLoading

  const items = props.items.map((member) => {
    const memberKey = typeof member === 'string' ? member : member.appUserKey
    const fullPersonData = fullData?.data.find(({ BestEmailAddrText, PersonMd5, UserInput }) => {
      return BestEmailAddrText === memberKey || PersonMd5 === memberKey || UserInput === memberKey
    })

    const hasExtraInfo = typeof member !== 'string'
    const name = fullPersonData?.PersonNameText || fullPersonData?.BestEmailAddrText || memberKey
    const email = memberKey.includes('@') ? memberKey : fullPersonData?.BestEmailAddrText

    const commonProps = {
      id: hasExtraInfo ? `${member.rowNumber}` : member,
      name,
      email,
      userKey: memberKey,
      byline: name === email ? '' : email,
      link: `${Paths._people}/${memberKey}`,
      sidepanel: true,
      variant: 'expandable'
    }

    if (hasExtraInfo) {
      return {
        ...commonProps,
        editor: member.viaWhomEmail,
        editorLink: `${Paths._people}/${member.viaWhomEmail}`,
        date: member.lastModified,
        byline3: (
          <Box className={classes.content}>
            <Content
              items={[
                {
                  field: 'date',
                  value: formatDateTime(member.lastModified),
                  title: 'Date',
                  icon: ['far', 'calendar-alt']
                },
                { field: 'editor', value: member.viaWhomEmail, title: 'Updated by', icon: ['fas', 'user-tie'] }
              ]}
              isExpandedView
            />
          </Box>
        )
      }
    }

    return commonProps
  })

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const handleRemove = async () => {
    const payload = selected
      .map((id) => {
        const person = items.find((item) => item.id === id)
        return person?.userKey
      })
      .filter(Boolean)
    await del(`/teams/${teamNumber}/privilegedcomembers`, payload)
    openSuccess()
  }

  const handleSuccessClose = () => {
    closeRemoveDialog()
    handleReload()
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: emailColumn },
        { column: editorColumn, condition: showFullInfo },
        { column: dateColumn, condition: showFullInfo }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || condition)
        .map(({ column }) => column),
    [showFullInfo]
  )

  const removeMembers = useMemo(
    () => (
      <>
        <RemoveSelectedDialog.TriggerEl open={openRemoveDialog} disabled={!selected.length} />
        <RemoveSelectedDialog
          content="Selected people will lose access to restricted companies."
          isOpened={isRemoveDialogOpened}
          close={closeRemoveDialog}
          successClose={handleSuccessClose}
          handleRemove={handleRemove}
          success={successMode}
        />
      </>
    ),
    [selected.length, isRemoveDialogOpened, closeRemoveDialog, openRemoveDialog, successMode, selected.length]
  )

  return (
    <>
      <Wide>
        <DataGrid
          rows={!props.loading && !fullDataLoading ? items : []}
          columns={columns}
          controls={[removeMembers]}
          loading={loading}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={props.total}
          onSelect={handleSelect}
          checkboxSelection
        />
      </Wide>
      <Narrow>
        <Widget scope="card">
          <Repeater direction="vertical" variant="card" component={ProfileItem} skeleton={{ size: 5, loading }} items={items} />
        </Widget>
      </Narrow>
    </>
  )
}

export default PrivilegedMembersPage
