import { useMemo } from 'react'

import { Moment as MomentType } from 'moment'

import { day, daysInAWeek, monthsInAYear } from '_core/data/day'

import { formatDate, getLocal, getUTC } from 'utils/Utils'

const isLeapYear = (year: number) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

const adjustLeapYear = (date: MomentType, years: number) => {
  const newDate = date.clone().add(years, 'year')

  // If original date was Feb 28 and new year is a leap year, move to Feb 29
  if (date.month() === 1 && date.date() === 28 && isLeapYear(newDate.year())) {
    newDate.date(29)
  }

  return newDate
}

const adjustStartOfWeek = (fromDate: MomentType, toDate: MomentType) =>
  fromDate.clone().startOf('week').isSame(toDate, 'month') ? fromDate.clone().startOf('week') : toDate.clone().startOf('month')

const splitYearIntoMonths = (fromDate: MomentType, toDate: MomentType, sort: SortType) => {
  const result = []
  const isAscending = sort === 'OldestToNewest'
  const current = isAscending ? fromDate.clone() : toDate.clone()

  while (isAscending ? current.isSameOrBefore(toDate, 'month') : current.isSameOrAfter(fromDate, 'month')) {
    const year = current.year()
    const month = current.month() + 1
    const minDay = isAscending ? fromDate.date() : toDate.clone().startOf('month').date()
    const maxDay = current.isSame(toDate, 'month') ? toDate.date() : current.clone().endOf('month').date()
    const chartLabel = getLocal(month, 'M').format('MMM')

    result.push({ year, month, minDay, maxDay, chartLabel })
    current.add(isAscending ? 1 : -1, 'month').startOf('month')
  }

  return result
}

const splitMonthIntoWeeks = (fromDate: MomentType, toDate: MomentType, sort: SortType) => {
  const result = []
  const isAscending = sort === 'OldestToNewest'

  const current = isAscending ? adjustStartOfWeek(fromDate, toDate) : toDate.clone().startOf('week')

  while (isAscending ? current.isSameOrBefore(toDate, 'week') : current.isSameOrAfter(fromDate, 'week')) {
    const month = current.month() + 1
    const minDay = current.date()
    const maxDay = current.clone().endOf('week').isSameOrAfter(toDate) ? toDate.date() : current.clone().endOf('week').date()

    result.push({ year: current.year(), month, minDay, maxDay, chartLabel: `${minDay}-${maxDay} ${getLocal(month, 'M').format('MMM')}` })

    if (isAscending) {
      current.add(daysInAWeek - ((current.day() + (daysInAWeek - 1)) % daysInAWeek), 'days')
    } else {
      const decrementDays = minDay >= daysInAWeek || minDay === 1 ? daysInAWeek : minDay - 1
      current.add(-decrementDays, 'days')
    }
  }
  return result
}

const splitWeekIntoDays = (fromDate: MomentType, toDate: MomentType, sort: SortType) => {
  const result = []
  const isAscending = sort === 'OldestToNewest'
  const current = isAscending ? fromDate.clone() : toDate.clone()

  while (isAscending ? current.isSameOrBefore(toDate, 'date') : current.isSameOrAfter(fromDate, 'date')) {
    result.push({
      year: current.year(),
      month: current.month() + 1,
      minDay: current.date(),
      maxDay: current.date(),
      chartLabel: current.format('ddd D')
    })
    current.add(isAscending ? day : -day, 'day')
  }

  return result
}

const useActivityStatsPeriod = (props: { fromUTC: MomentType | null; toUTC: MomentType | null; sort?: SortType }) => {
  const { fromUTC: from, toUTC: to, sort = 'OldestToNewest' } = props || {}

  const fallback = {
    months: [],
    chartTitle: '',
    gridMessage: '',
    zoomOutPeriod: null,
    currentPeriod: null,
    nextPeriod: null,
    prevPeriod: null
  }

  const memoizedResult = useMemo(() => {
    if (!from || !to) return { ...fallback, weekView: false }

    const startOfLast12MonthsFromNow = getUTC()
      .clone()
      .subtract(monthsInAYear - 1, 'months')
      .startOf('month')

    const startOfLast12Months = to
      .clone()
      .subtract(monthsInAYear - 1, 'months')
      .startOf('month')
    const endOfLast12Months = to.clone().endOf('month')
    const startOfMonth = from.clone().startOf('month')
    const endOfMonth = to.clone().endOf('month')
    const startOfWeek = adjustStartOfWeek(from, to)
    const endOfWeek = to.clone().endOf('week')

    // defensive condition provided to jump out from loading state in case user jumped to 28.02 date for the leap year
    const isToTheSameAsEndOfLast12Months =
      isLeapYear(to.year()) && to.month() === 1 ? adjustLeapYear(to, 0).isSame(endOfLast12Months, 'day') : to.isSame(endOfLast12Months, 'day')

    const last12MonthsView = from.isSame(startOfLast12Months, 'day') && isToTheSameAsEndOfLast12Months
    const monthView = from.isSame(startOfMonth, 'day') && to.isSame(endOfMonth, 'day')
    const dayView = to.diff(from, 'days') === 1
    const weekView = !dayView && to.diff(from, 'days') <= daysInAWeek - 1

    const zoomOutFromForMonthView = getUTC({ year: from.year(), month: startOfLast12MonthsFromNow.month(), date: startOfLast12MonthsFromNow.date() })

    const result =
      [
        {
          months: splitYearIntoMonths(from, to, sort),
          chartTitle: `${from.format('MMM')}, ${from.format('YYYY')} - ${to.format('MMM')}, ${to.format('YYYY')}`,
          gridMessage: `${from.format('MMM')}, ${from.format('YYYY')} - ${to.format('MMM')}, ${to.format('YYYY')}`,
          zoomOutPeriod: null,
          currentPeriod: [startOfLast12Months, endOfLast12Months] as const,
          nextPeriod: [adjustLeapYear(startOfLast12Months, 1), adjustLeapYear(endOfLast12Months, 1)] as const,
          prevPeriod: [adjustLeapYear(startOfLast12Months, -1), adjustLeapYear(endOfLast12Months, -1)] as const,
          condition: last12MonthsView
        },
        {
          months: splitMonthIntoWeeks(from, to, sort),
          chartTitle: `${from.format('MMMM YYYY')}`,
          gridMessage: `${from.format('MMMM YYYY')}`,
          zoomOutPeriod: [
            zoomOutFromForMonthView,
            zoomOutFromForMonthView
              .clone()
              .add(monthsInAYear - 1, 'months')
              .endOf('month')
          ] as const,
          currentPeriod: [startOfMonth, endOfMonth] as const,
          nextPeriod: [startOfMonth.clone().add(1, 'month'), endOfMonth.clone().add(1, 'month').endOf('month')] as const,
          prevPeriod: [startOfMonth.clone().add(-1, 'month'), endOfMonth.clone().add(-1, 'month').endOf('month')] as const,
          condition: monthView
        },
        {
          months: splitWeekIntoDays(from, to, sort),
          chartTitle: `${startOfWeek.format('DD')} - ${to.format('DD MMM, YYYY')}`,
          gridMessage: `the week, ${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`,
          zoomOutPeriod: [startOfMonth, endOfMonth] as const,
          currentPeriod: [startOfWeek, endOfWeek] as const,
          nextPeriod: [startOfWeek.clone().add(1, 'week').startOf('week'), endOfWeek.clone().add(1, 'week')] as const,
          prevPeriod: [startOfWeek.clone().add(-1, 'week').startOf('week'), endOfWeek.clone().add(-1, 'week')] as const,
          condition: weekView
        },
        {
          months: splitWeekIntoDays(from, to, sort),
          chartTitle: `${from.format('DD MMM, YYYY')}`,
          gridMessage: `${from.format('DD MMM, YYYY')}`,
          zoomOutPeriod: [startOfWeek, endOfWeek] as const,
          currentPeriod: [from, to] as const,
          nextPeriod: [from.clone().add(1, 'day'), to.clone().add(1, 'day')] as const,
          prevPeriod: [from.clone().add(-1, 'day'), to.clone().add(-1, 'day')] as const,
          condition: dayView
        }
      ].find(({ condition }) => condition) || fallback

    return { ...result, weekView }
  }, [from?.toISOString(), to?.toISOString(), sort])

  return memoizedResult
}

export default useActivityStatsPeriod
