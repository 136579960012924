import React, { useContext, useEffect } from 'react'

import { Link, Route, useParams, Switch } from 'react-router-dom'

import PersonAuditPage from '_pages/people/[id]/audit/identfiers'
import TuplesChainPage from '_pages/people/[id]/audit/identfiers/tuples-chain'
import TupleListPage from '_pages/people/[id]/audit/tuples'
import TupleSourcesPage from '_pages/people/[id]/audit/tuples/sources'

import { TeamContext } from '_core/context/TeamContext'

import { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import Card, { CardContent } from '_shared/Card'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Breadcrumbs, { BreadcrumbAction, BreadcrumbType } from '_core/components/Breadcrumbs'
import Heading from '_core/components/Heading'
import InnerDialog from '_core/components/InnerDialog'
import { Column, Columns, useSidepanelWide } from '_core/components/layout'
import PrivateRoute from '_core/components/PrivateRoute'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useDialog from '_core/hooks/useDialog'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import usePathWithParams from '_core/hooks/usePathWithParams'
import usePicker from '_core/hooks/usePicker'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Cards = () => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Audit')
  }, [setMobileHeader])

  const cards: (IMenuAction & { description: string })[] = [
    {
      label: 'Identifiers',
      icon: ['far', 'list-check'],
      link: `${Paths._people}/${id}/audit/identifiers`,
      description: 'Review and manage the identifiers for this entity'
    },
    {
      label: 'Tuples',
      icon: ['far', 'diagram-cells'],
      link: `${Paths._people}/${id}/audit/tuples`,
      description: 'Review and manage the identifier to identifier associations (i.e. tuples) for this entity'
    }
  ]

  return (
    <>
      <Topbar />
      <Widget>
        <Columns>
          {cards.map((card) => (
            <Column key={card.label} xs={12} sm={6}>
              <Card elevation={0} variant="outlined">
                <Link to={card.link}>
                  <CardContent>
                    <Heading title={card.label} icon={card.icon} underlined />
                    <Typography color="text.secondary">{card.description}</Typography>
                  </CardContent>
                </Link>
              </Card>
            </Column>
          ))}
        </Columns>
      </Widget>
    </>
  )
}

const personAuditUrl = `${Paths._people}/:id/audit`

const Header = React.memo((props: { openDialog: () => void }) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { result } = useEntityEndpoint<{ data: PersonType[] } | null>(`/people/${id}?teamNumber=${teamContextValue.teamNumber}`)
  const sidepanelWide = useSidepanelWide()
  const resolvePathWithParams = usePathWithParams<TuplesPageParams>()

  const personName = result?.data[0].PersonNameText || result?.data[0].BestEmailAddrText || ''
  const profilePath = `${Paths._people}/${id}`

  const routes: BreadcrumbType[] = [
    {
      path: profilePath,
      label: personName,
      action: <BreadcrumbAction onClick={props.openDialog} disabled={!personName} icon={['far', 'search']} hint="Pick a person" />
    },
    {
      path: `${profilePath}/audit`,
      label: 'Audit'
    },
    {
      path: `${profilePath}/audit/identifiers`,
      label: 'Identifiers'
    },
    {
      path: resolvePathWithParams(`${personAuditUrl}/identifiers/tuples-chain/:sourceId/:targetId`),
      label: 'Paths'
    },
    {
      path: resolvePathWithParams(`${personAuditUrl}/identifiers/tuples-chain/:sourceId/:targetId/sources`),
      label: 'Sources'
    },
    {
      path: `${profilePath}/audit/tuples`,
      label: 'Tuples'
    },
    {
      path: `${personAuditUrl}/tuples/sources`,
      label: 'Sources'
    }
  ]

  return <Breadcrumbs maxItems={sidepanelWide ? 5 : 2} routes={routes} />
})

const PersonAuditPages = () => {
  const { id } = useParams<{ id: string }>()
  const { isAdminOrCurator } = useAdminOrCuratorCheck()
  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  useEffect(() => {
    closeDialog()
  }, [id])

  const { Picker: Search } = usePicker({
    entity: 'people',
    getLink: (md5: string) => `${Paths._people}/${md5}/audit`
  })

  return (
    <Page>
      <Header openDialog={openDialog} />
      {isDialogOpened ? (
        <InnerDialog close={closeDialog}>{Search}</InnerDialog>
      ) : (
        <Switch>
          <PrivateRoute
            hasAccess={isAdminOrCurator}
            path={`${personAuditUrl}/identifiers/tuples-chain/:sourceId/:targetId/sources/:tuple1/:tuple2`}
            component={TupleSourcesPage}
          />
          <Route path={`${personAuditUrl}/identifiers/tuples-chain/:sourceId/:targetId`} component={TuplesChainPage} />
          <Route path={`${personAuditUrl}/identifiers`} component={PersonAuditPage} />
          <PrivateRoute hasAccess={isAdminOrCurator} path={`${personAuditUrl}/tuples/sources/:tuple1/:tuple2`} component={TupleSourcesPage} />
          <Route path={`${personAuditUrl}/tuples`} component={TupleListPage} />
          <Route path={personAuditUrl} component={Cards} />
        </Switch>
      )}
    </Page>
  )
}

export default PersonAuditPages
