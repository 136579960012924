import { useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AliasNames, { AliasNamesProps } from '_core/components/AliasNames'
import AliasUrls, { AliasUrlsProps } from '_core/components/AliasUrls'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Aliases = (props: any) => {
  const { teamContextValue } = useContext(TeamContext)
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)
  const [totalAliases, setTotalAliases] = useState<number>(0)
  const [totalDomains, setTotalDomains] = useState<number>(0)

  useEffect(() => {
    setSubHeader('Aliases and domains')
    return () => setSubHeader('')
  }, [setSubHeader])

  const wide = useWide()

  const maxNumber = 10

  return (
    <Page variant="core">
      <Wide>
        <Heading title="Aliases and domains" icon={['far', 'fingerprint']} variant="main" />
      </Wide>
      <Narrow>
        <Topbar nativeBack />
      </Narrow>
      <Columns spacing={wide ? 1 : 0}>
        <Column two>
          <Widget scope={totalAliases > maxNumber ? 'default' : 'stack'}>
            <Heading
              title="Aliases"
              icon={['far', 'building']}
              count={totalAliases}
              link={totalAliases > maxNumber ? `${Paths._companies}/${id}/aliases/names` : ''}
              sidepanel
            />
            <DynamicEntity<{ extraProps: { addprops: Pick<AliasNamesProps, 'companyName' | 'setTotalAliases'> } }>
              url={`/companies/${id}/aliases?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
              component={AliasNames}
              addprops={{ companyName: props.companyName, setTotalAliases }}
              list
              id="company_aliases_name"
            />
          </Widget>
        </Column>
        <Column two>
          <Widget scope={totalDomains > maxNumber ? 'default' : 'stack'}>
            <Heading
              title="Domains"
              icon={['far', 'globe']}
              count={totalDomains}
              link={totalDomains > maxNumber ? `${Paths._companies}/${id}/aliases/urls` : ''}
              sidepanel
            />
            <DynamicEntity<{ extraProps: { addprops: Pick<AliasUrlsProps, 'companyName' | 'setTotalDomains'> } }>
              url={`/companies/${id}/urls?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
              component={AliasUrls}
              addprops={{ companyName: props.companyName, setTotalDomains }}
              list
              id="company_aliases_url"
            />
          </Widget>
        </Column>
      </Columns>
    </Page>
  )
}

export default Aliases
