import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import ExternalTag from '_core/components/ExternalTag'

const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  space: {
    paddingTop: '3px'
  },
  iconButton: {
    paddingTop: theme.spacing(2)
  }
}))

const CompanyData = ({ data }: { data: CompanyMarketDataType | null }) => {
  const { classes, cx } = useStyles()
  const { id, name, location, founded, website, employeeCount, facebookUrl, twitterUrl, linkedinUrl, industry, type, tags, summary } = data || {}
  const showDivider = id && (!!tags?.length || summary)

  return (
    <>
      {(!id || name) && (
        <Skeleton condition={!name} height={29}>
          <Tooltip title={name} placement="bottom-start">
            <Box display="flex" alignItems="start">
              <FontAwesomeIcon icon={['fas', 'building']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{name || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && website)) && (
        <Skeleton condition={!website} width={120} height={29}>
          <Tooltip title={website} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['far', 'globe']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography color="text.primary">
                <Link to={{ pathname: `https://${website}` }} target="_blank">
                  {website}
                </Link>
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && location?.name)) && (
        <Skeleton condition={!location?.name}>
          <Tooltip title={location?.name} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'location-dot']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{location?.name || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && industry)) && (
        <Skeleton condition={!industry}>
          <Tooltip title={industry} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'industry']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{industry || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && employeeCount)) && (
        <Skeleton condition={!employeeCount}>
          <Tooltip title={employeeCount} placement="bottom-start">
            <Box display="flex" alignItems="center" mt={1}>
              <FontAwesomeIcon icon={['fas', 'users']} className={classes.byLineIcon} />
              <Typography>{`${employeeCount}+ employees` || 'Placeholder'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && founded)) && (
        <Skeleton condition={!founded}>
          <Tooltip title={founded} placement="bottom-start">
            <Box display="flex" alignItems="center" mt={1}>
              <FontAwesomeIcon icon={['fas', 'briefcase']} className={classes.byLineIcon} />
              <Typography>{`Since ${founded}` || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && type)) && (
        <Skeleton condition={!type}>
          <Tooltip title={type} placement="bottom-start">
            <Box display="flex" alignItems="center" mt={1}>
              <FontAwesomeIcon icon={['fas', 'handshake']} className={classes.byLineIcon} />
              <Typography>{type || 'Placeholder'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      <Box display="flex" justifyContent="center">
        {(!id || (id && facebookUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'facebook']}
            color="primary"
            hint="Show company profile on Facebook"
            component="a"
            href={`https://${facebookUrl}`}
            loading={!facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          />
        )}
        {(!id || (id && twitterUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show company profile on Twitter"
            component="a"
            href={`https://${twitterUrl}`}
            loading={!twitterUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || (id && linkedinUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show company profile on LinkedIn"
            component="a"
            href={`https://${linkedinUrl}`}
            loading={!linkedinUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || (id && website)) && (
          <IconButton
            className={classes.iconButton}
            icon={['far', 'globe']}
            color="primary"
            hint="Show website"
            component="a"
            href={`https://${website}`}
            loading={!website}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>
      {(!id || showDivider) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
      {(!id || (id && !!tags?.length)) && (
        <Skeleton condition={!tags?.length} width={120} height={29}>
          <Box display="flex" alignItems="center" mt={showDivider ? 0 : 2}>
            <FontAwesomeIcon icon={['fas', 'hashtag']} className={classes.byLineIcon} />
            <Typography semiBold>Tags</Typography>
          </Box>
          {!!tags?.length && <Box mt={1}>{tags?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>}
        </Skeleton>
      )}
      {(!id || (id && summary)) && (
        <Skeleton condition={!summary} width={120}>
          <Box display="flex" alignItems="center" mt={2}>
            <FontAwesomeIcon icon={['fas', 'hashtag']} className={classes.byLineIcon} />
            <Typography semiBold>Summary</Typography>
          </Box>
          {summary && (
            <Box mt={1}>
              <Typography>{summary}</Typography>
            </Box>
          )}
        </Skeleton>
      )}
    </>
  )
}

export default CompanyData
