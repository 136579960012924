import React, { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import CompaniesSearchFilters from '_core/components/filters/MarketDataCompaniesSearch'
import PeopleSearchFilters from '_core/components/filters/MarketDataPeopleSearch'
import GridPageFrame from '_core/components/GridPageFrame'
import CompaniesResults from '_core/components/MarketDataCompaniesSearch'
import PeopleResults from '_core/components/MarketDataPeopleSearch'
import Heading, { HeadingProps } from '_core/components/MarketDataSearchHeading'

import useOnboarding from '_core/hooks/useOnboarding'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type SearchProps = {
  opened: boolean
  filtersLoading: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  selectedEntity: SearchEntities
  toggleOpen: () => void
}

const useStyles = makeStyles()(() => ({
  grid: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: 'inherit',
    '& > div[class*="wrapper"]': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      overflow: 'auto'
    }
  },
  container: {
    '& div[class*="filter"]': {
      height: 'inherit'
    }
  }
}))

export const entityOptions: { label: string; value: SearchEntities }[] = [
  { label: 'People', value: 'person' },
  { label: 'Companies', value: 'company' }
]

const PeopleHeading = (props: HeadingProps) => {
  const { queryParams } = useSearchQuery<SearchPageParams>()
  const peopleFilters = queryParams.peopleFilters ? JSON.parse(queryParams.peopleFilters) : {}
  const filtersCount = Object.keys(peopleFilters).length

  return (
    <Heading
      filters={props.filters}
      filtersProps={props.filtersProps}
      filtersCount={filtersCount}
      viewProps={props.viewProps}
      placeholder="Search for people"
    />
  )
}

const CompaniesHeading = (props: HeadingProps) => {
  const { queryParams } = useSearchQuery<SearchPageParams>()
  const companiesFilters = queryParams.companiesFilters ? JSON.parse(queryParams.companiesFilters) : {}
  const filtersCount = Object.keys(companiesFilters).length

  return (
    <Heading
      filters={props.filters}
      filtersProps={props.filtersProps}
      filtersCount={filtersCount}
      viewProps={props.viewProps}
      placeholder="Search for companies"
    />
  )
}

const PeopleSearch = ({
  filtersLoading,
  handleChange,
  opened,
  toggleOpen,
  teamNumber
}: Omit<SearchProps, 'selectedEntity'> & { teamNumber: number }) => {
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const { classes } = useStyles()

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    total,
    contentLoading,
    loading: filtersLoading,
    disabled: filtersLoading,
    handleChange,
    opened,
    toggleOpen
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  return (
    <GridPageFrame
      loading={typeof total !== 'number'}
      gridTitle="People market data search"
      gridHeadingIcon={['far', 'search']}
      searchPlaceholder="Search for people"
      disabledSearch={filtersProps.disabled}
      filters={<PeopleSearchFilters {...filtersProps} />}
      heading={
        <PeopleHeading
          filters={<PeopleSearchFilters {...filtersProps} />}
          filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
          viewProps={{ viewMode: queryParams.viewMode, updateViewMode }}
        />
      }
      component={
        <Box className={classes.grid}>
          <PeopleResults teamNumber={teamNumber} onLoading={onLoading} />
        </Box>
      }
    />
  )
}

const CompaniesSearch = ({
  filtersLoading,
  handleChange,
  opened,
  toggleOpen,
  teamNumber
}: Omit<SearchProps, 'selectedEntity'> & { teamNumber: number }) => {
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const { classes } = useStyles()

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    total,
    contentLoading,
    loading: filtersLoading,
    disabled: filtersLoading,
    handleChange,
    opened,
    toggleOpen
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  return (
    <GridPageFrame
      loading={typeof total !== 'number'}
      gridTitle="Companies market data search"
      gridHeadingIcon={['far', 'search']}
      searchPlaceholder="Search for companies"
      disabledSearch={filtersProps.disabled}
      filters={<CompaniesSearchFilters {...filtersProps} />}
      heading={
        <CompaniesHeading
          filters={<CompaniesSearchFilters {...filtersProps} />}
          filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
          viewProps={{ viewMode: queryParams.viewMode, updateViewMode }}
        />
      }
      component={
        <Box className={classes.grid}>
          <CompaniesResults teamNumber={teamNumber} onLoading={onLoading} />
        </Box>
      }
    />
  )
}

const MarketDataSearch = ({ selectedEntity, ...props }: SearchProps) => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)
  const { startOnboarding } = useOnboarding()
  const { classes } = useStyles()

  const entityLabel = entityOptions.find((entity) => entity.value === selectedEntity)?.label || ''

  useEffect(() => {
    startOnboarding(Paths._search)
  }, [])

  useEffect(() => {
    setMobileHeader(`${entityLabel} market data search`)
  }, [setMobileHeader, entityLabel])

  return (
    <Box className={classes.container}>
      {selectedEntity === 'person' ? <PeopleSearch teamNumber={teamNumber} {...props} /> : <CompaniesSearch teamNumber={teamNumber} {...props} />}
    </Box>
  )
}

export default MarketDataSearch
