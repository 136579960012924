import { useState } from 'react'

export type CSuiteType = { data: PeopleMarketDataType[]; id?: string; website?: string } & PaginationMarketData

export const useCSuite = () => {
  const [cSuite, setCSuite] = useState<CSuiteType | null>(null)

  const getPersonDetails = (name: string, email?: string): PeopleMarketDataType | undefined =>
    cSuite?.data?.find((item) => item.fullName === name || (email && item.workEmail === email))

  return {
    cSuite,
    setCSuite,
    getPersonDetails
  }
}
