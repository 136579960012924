import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import PersonData from '_core/components/market-data/PersonData'
import ReportIncorrectData from '_core/components/market-data/Report'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

interface PersonMarketDataProps {
  person: PersonMarketDataType | null
  urlType?: 'people' | 'companies'
}

const PersonMarketData = ({ person, urlType }: PersonMarketDataProps) => (
  <>
    {(!person || person.id) && (
      <>
        <Widget>
          <UnknownProfileSummary title={person?.fullName || ''} userKey={person?.workEmail} loading={!person?.fullName} />
        </Widget>
        <Widget>
          <Heading underlined title="Details" icon={['fas', 'chart-column']} count={0} />
          <PersonData urlType={urlType} data={person} />
        </Widget>
      </>
    )}
    {person && !person.id && (
      <Widget>
        <Empty title="No data found" />
      </Widget>
    )}
    <ReportIncorrectData marketData={JSON.stringify(person)} />
  </>
)

export default PersonMarketData
