import React, { useEffect, useContext, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Empty from '_core/components/Empty'
import Filters, { Controller } from '_core/components/filters/SimilarPeople'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { FiltersType, PeopleType, transformResponse } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface SimilarPeopleProps {
  marketData: PersonMarketDataType | null
  similar: PeopleType | null
  setSimilar: (val: PeopleType | null) => void
  loading: boolean
  email: string
  jobTitle: string
}

const SimilarPeople = ({ marketData, similar, setSimilar, ...props }: SimilarPeopleProps) => {
  const { queryParams, updateQuery } = useSearchQuery<{ clearSearch?: 'true' | null; openedFilters?: 'true' | null }>()
  const { teamContextValue } = useContext(TeamContext)
  const [openedFilters, setOpenedFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<FiltersType | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const notAppliedFilters = useRef<boolean>(false)
  const { fetchWithAbort } = useAbortableFetch<PeopleMarketDataRes>()

  const load = loading || props.loading
  const {
    jobTitle,
    jobTitleRole,
    jobTitleSubRole,
    jobTitleLevels,
    jobCompanyIndustry,
    industry = '',
    locationCountry,
    jobCompanySize,
    workEmail
  } = marketData || {}
  const email = workEmail || props.email
  const enoughFilters = marketData && email && (jobTitle || props.jobTitle)
  const storedFilters = id && similar?.id && similar.id === id && !!similar?.filters
  const total = similar?.total || 0

  useEffect(() => {
    setSubHeader(`Similar People ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  useEffect(() => {
    if (queryParams.clearSearch === 'true' && setSimilar && !props.loading) {
      updateQuery({ clearSearch: null })
      setSimilar(null)
      setFilters(null)
    }
    if (queryParams.openedFilters === 'true' && !props.loading) {
      updateQuery({ openedFilters: null })
      setOpenedFilters(true)
    }
  }, [queryParams.clearSearch, queryParams.openedFilters, setSimilar, props.loading])

  useEffect(() => {
    if (!filters && storedFilters && !queryParams.clearSearch) {
      setFilters(similar.filters)
      return
    }

    if (!filters && marketData) {
      setInitialFilters()
    }
  }, [filters, marketData, storedFilters, queryParams.clearSearch])

  useEffect(() => {
    if (filters && notAppliedFilters.current && !openedFilters) {
      notAppliedFilters.current = false
      if (storedFilters) {
        setFilters(similar.filters)
        return
      }
      if (marketData) {
        setInitialFilters()
        return
      }
    }
  }, [filters, notAppliedFilters.current, openedFilters, storedFilters])

  const setNoSimilar = (persistFilters?: boolean) => {
    notAppliedFilters.current = false
    setSimilar({
      id,
      email,
      data: [],
      scrollToken: '',
      total: 0,
      ...(persistFilters && filters && { filters })
    })
  }

  const getSimilar = async (persistFilters?: boolean, loadMore?: boolean) => {
    if (filters && teamContextValue.teamNumber) {
      setLoading(true)
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/people/similar', {
          email,
          titlecase: 'true',
          teamNumber: `${teamContextValue.teamNumber}`,
          ...(loadMore && similar?.scrollToken && { scrollToken: similar.scrollToken }),
          ...filters
        })
      })
      if (res) {
        const transformedData = transformResponse(res.data)
        setSimilar({
          id,
          email,
          data: loadMore && similar?.data ? [...similar.data, ...transformedData] : transformedData,
          scrollToken: res.scrollToken || '',
          total: res.total,
          ...(persistFilters && { filters })
        })
      }

      if (persistFilters) {
        notAppliedFilters.current = false
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filters && !similar && !load) {
      enoughFilters ? getSimilar() : setNoSimilar()
    }
  }, [similar, filters, load])

  const items = similar?.data?.map((person) => ({
    name: person.fullName,
    score: person.personMd5 ? person.score : undefined,
    link: person.personMd5
      ? `${Paths._people}/${person.personMd5}`
      : mergeUrlWithParams(`${Paths._people}/${id}/similar/${person.fullName}`, {
          email: person.workEmail,
          company: person.jobCompanyName
        }),
    sidepanel: true,
    byline: person.jobTitle || '',
    byline2: person.jobCompanyName || ''
  }))

  const setInitialFilters = () => {
    setFilters({
      companySize: jobCompanySize || '',
      jobTitles: [jobTitle || props.jobTitle].filter(Boolean),
      jobTitleRoles: jobTitleRole ? [jobTitleRole] : [],
      jobTitleSubroles: jobTitleSubRole ? [jobTitleSubRole] : [],
      jobTitleLevels: jobTitleLevels || [],
      industries: [jobCompanyIndustry || industry].filter(Boolean),
      countries: enoughFilters ? [locationCountry || 'United States'] : []
    })
  }

  const updateFilters = (updatedFilters: FiltersType) => {
    notAppliedFilters.current = true
    setFilters(updatedFilters)
  }

  const toggleOpen = () => setOpenedFilters((prevState: boolean) => !prevState)
  const applyFilters = () => {
    const emptyFilters = !!filters && Object.entries(filters)?.every(([, value]) => (Array.isArray(value) ? !value.length : !value))
    emptyFilters ? setNoSimilar(true) : getSimilar(true)
  }
  const loadMore = () => getSimilar(true, true)
  const reset = () => {
    notAppliedFilters.current = true
    setInitialFilters()
  }

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <Controller opened={openedFilters} toggleOpen={toggleOpen} disabled={!filters} variant="text" ref={anchorRef} />
        <Filters
          opened={openedFilters}
          disabled={!filters}
          contentLoading={load}
          anchorEl={anchorRef.current}
          total={similar?.total || 0}
          filters={filters}
          updateFilters={updateFilters}
          reset={reset}
          applyFilters={notAppliedFilters.current ? applyFilters : undefined}
        />
        <InfiniteList loading={loading} next={loadMore} dataLength={similar?.total || 0} hasMore={!!similar?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            empty={<Empty title={enoughFilters ? 'No records were found' : 'Not enough market data is available for an effective search filter'} />}
            items={items || []}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default SimilarPeople
