import React, { useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { matchPath, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'

import ActivitiesPage from '_pages/people/[id]/activities'
import ContactActivityStatisticsPage from '_pages/people/[id]/activityStats'
import AffiliationsPage, { AffiliationsList } from '_pages/people/[id]/affiliations'
import UserCompanies from '_pages/people/[id]/companies'
import ExternalKeysPage, { ExternalKeysList, ExternalKeysListPropsType } from '_pages/people/[id]/externalKeys'
import ContactInfo from '_pages/people/[id]/info'
import ContactInfoEmails from '_pages/people/[id]/info/emails'
import ContactInfoPhones from '_pages/people/[id]/info/phones'
import UserInteractions from '_pages/people/[id]/interactions'
import UserIntroducers from '_pages/people/[id]/introducers'
import MarketDataPage from '_pages/people/[id]/market-data'
import CompanyMarketDataPage from '_pages/people/[id]/market-data/company'
import UserPeople from '_pages/people/[id]/people'
import Profile, { checkContributor } from '_pages/people/[id]/profile'
import SimilarPeople from '_pages/people/[id]/similar'
import SimilarPersonPage from '_pages/people/[id]/similar/[id]'
import Touchpoints from '_pages/people/[id]/touchpoints'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Dashboard, { DashboardLinkType } from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import PersonMarketData from '_core/components/market-data/PersonMarketData'
import PersonHeader from '_core/components/PersonHeader'
import PrivateRoute from '_core/components/PrivateRoute'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { useSimilarPeople } from '_core/hooks/useSimilarPeople'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'

import { get } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type PersonProfileType = PersonType & {
  loading: boolean
  meUser?: ProfileType
  salesforce?: {
    isEnabled: true
  }
}

const url = Paths._people

const PersonProfile = ({ salesforce, ...props }: PersonProfileType) => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<PersonProfilePageParams>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const [interactions, setInteractions] = useState<{ [key: string]: any } | null>()
  const matchProfile = matchPath(pathname, { path: Paths.personProfile })?.isExact
  const enableScroll = interactions !== undefined && !props.loading && !!props.PersonMd5 && matchProfile
  const { enabled: enabledMarketData, showSimilarPeople } = useMarketDataIntegration()
  usePresetScroll(enableScroll)

  const prohibitedMarketData = typeof enabledMarketData === 'boolean' && !enabledMarketData
  const {
    name: mdName,
    email: mdEmail,
    company: mdCompany
  } = !props.loading && !props.PersonMd5
    ? queryParams
    : {
        name: props.PersonNameText,
        email: props.BestEmailAddrText,
        company: props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName
      }
  const { marketData, getMarketData } = usePersonMarketData(mdName || '', mdEmail, mdCompany)
  const { similar, setSimilar, getPersonDetails, similarWidget, setSimilarWidget } = useSimilarPeople()

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile, hideColleagueRelationships } = dataVisibility || {}

  const activitiesPath = `${url}/:id/activities`
  const { isExact: matchPersonActivities = false } = matchPath(pathname, { path: activitiesPath }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([id, matchPersonActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  useEffect(() => {
    if (!props.loading && enabledMarketData && !marketData) {
      getMarketData()
    }
  }, [enabledMarketData, marketData, props.loading, getMarketData])

  const contributor = checkContributor(
    props.loading,
    props.BestEmailAddrText,
    props.MyUserKeyMd5 || '',
    props.meUser?.UserKey || '',
    !!hideColleagueRelationships
  )

  const personName = props.PersonNameText || props.BestEmailAddrText
  const profileLink = `${Paths._people}/${props.MyUserKeyMd5 || props.PersonMd5}`

  const dashboardLinks: DashboardLinkType[] = [
    {
      name: 'Dashboard',
      link: profileLink,
      condition: Boolean(props.loading || props.MyUserKeyMd5 || props.PersonMd5),
      icon: ['far', 'th'] as IconProp
    },
    {
      name: 'Activity statistics',
      link: `${profileLink}/activityStats`,
      condition: !contributor && isStatsWidgetVisible,
      icon: ['far', 'chart-simple'] as IconProp
    },
    {
      name: 'Introducers',
      link: `${profileLink}/introducers`,
      condition: !contributor,
      icon: ['far', 'address-book'] as IconProp
    },
    {
      name: 'People',
      link: `${profileLink}/people`,
      condition: !!contributor,
      icon: ['far', 'user'] as IconProp
    },
    {
      name: 'Companies',
      link: `${profileLink}/companies`,
      condition: !!contributor,
      icon: ['far', 'building'] as IconProp
    },
    {
      name: 'Interactions',
      link: `${profileLink}/interactions`,
      condition: showInteractionsInProfile,
      icon: ['far', 'envelope'] as IconProp
    },
    {
      name: 'Touchpoints',
      link: `${profileLink}/touchpoints`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'handshake'] as IconProp
    },
    {
      name: 'Affiliations',
      link: `${profileLink}/affiliations`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'suitcase'] as IconProp
    },
    {
      name: 'Contact Information',
      link: `${profileLink}/info`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'address-card'] as IconProp
    },
    {
      name: 'External Keys',
      link: `${profileLink}/keys`,
      condition: !!props.CrmSourceKeys?.data?.length,
      icon: ['fas', 'share-alt'] as IconProp
    }
  ].filter(({ condition }) => condition)

  const alternateMobileHeader = matchPath(pathname, {
    path: [`${Paths.personProfile}/similar/:similarId`, `${Paths.personProfile}/market-data/company/:companyId`]
  })?.isExact

  React.useEffect(() => {
    if (!alternateMobileHeader) {
      setMobileHeader(!props.loading ? queryParams.name || personName : '', props.loading)
    }
  }, [personName, setMobileHeader, alternateMobileHeader, props.loading, queryParams.name])

  React.useEffect(() => {
    return () => {
      setInteractions(undefined)
      setSimilarWidget(null)
    }
  }, [props.PersonMd5])

  React.useEffect(() => {
    const getInteractions = async () => {
      setInteractions(await get(`/interactions?TeamNumber=${teamNumber}&personMd5=${props.PersonMd5}`))
    }

    if (matchProfile && !props.loading && interactions === undefined) {
      if (showInteractionsInProfile) {
        getInteractions()
      } else {
        setInteractions(null)
      }
    }
  }, [props.PersonMd5, showInteractionsInProfile, props.loading, teamNumber, matchProfile, interactions])

  if (!props.loading && !props.PersonMd5) {
    return queryParams.name ? (
      <>
        {enabledMarketData && (queryParams.email || queryParams.company) ? (
          <PersonMarketData person={marketData} />
        ) : (
          <>
            <Widget scope="none">
              <UnknownProfileSummary title={queryParams.name} userKey={queryParams.email} loading={props.loading} />
            </Widget>
            <Widget scope="list">
              <Empty title="Profile not found in DotAlign" />
            </Widget>
          </>
        )}
      </>
    ) : (
      <Empty title="Profile not found" closeExtension />
    )
  }

  const interactionsHeight = dashboardLinks.length * 78

  const Header = (
    <PersonHeader
      {...props}
      isSalesforceEnabled={!!salesforce?.isEnabled}
      marketData={marketData}
      enabledMarketData={enabledMarketData}
      showSimilarPeople={!!showSimilarPeople}
    />
  )

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          {Header}
        </Route>

        <Route exact path={`${url}/:id/*`}>
          <Wide>{Header}</Wide>
        </Route>
      </Switch>

      <Dashboard loading={props.loading} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile
              interactions={interactions}
              enabledMarketData={enabledMarketData}
              showSimilarPeople={!!showSimilarPeople}
              marketData={marketData}
              similar={similarWidget}
              setSimilar={setSimilarWidget}
              showInteractionsInProfile={!!showInteractionsInProfile}
              hideColleagueRelationships={!!hideColleagueRelationships}
              showActivityStats={!!isStatsWidgetVisible}
              isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
              {...props}
            />
          </Route>

          <PrivateRoute
            hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
            exact
            path={activitiesPath}
            render={() => <ActivitiesPage personName={personName} />}
          />
          <PrivateRoute
            hasAccess={isStatsWidgetVisible}
            exact
            path={`${url}/:id/activityStats`}
            render={() => <ContactActivityStatisticsPage personName={personName} />}
          />
          <Route exact path={`${url}/:id/companies`} component={UserCompanies} />
          <Route exact path={`${url}/:id/people`} component={UserPeople} />
          <Route exact path={`${url}/:id/introducers`} component={UserIntroducers} />
          <Route exact path={`${url}/:id/info/phones`} render={() => <ContactInfoPhones name={personName} />} />
          <Route exact path={`${url}/:id/info/emails`} render={() => <ContactInfoEmails name={personName} />} />
          <Route exact path={`${url}/:id/info`} render={() => <ContactInfo name={personName} />} />
          <Route
            exact
            path={`${url}/:id/keys`}
            render={() => (
              <ExternalKeysPage>
                <DynamicEntity
                  url={`/people/${id}/externalkeys?TeamNumber=${teamNumber}`}
                  component={ExternalKeysList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  keepMounted
                  subHeader={(props: ExternalKeysListPropsType) => (
                    <Typography title={`External keys · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      External keys · {props.total_item_count}
                    </Typography>
                  )}
                  id="person_external_keys"
                />
              </ExternalKeysPage>
            )}
          />
          <Route
            exact
            path={`${url}/:id/affiliations`}
            render={() => (
              <AffiliationsPage>
                <DynamicEntity
                  url={`/personjobs/${id}?TeamNumber=${teamNumber}`}
                  component={AffiliationsList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  subHeader={(props: any) => (
                    <Typography title={`Affiliations · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      Affiliations · {props.total_item_count}
                    </Typography>
                  )}
                  id="person_affiliations"
                />
              </AffiliationsPage>
            )}
          />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={`/people/${id}?teamNumber=${teamNumber}&includeStats=true`}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap>
                    Key touch points
                  </Typography>
                )}
                id="person_touchpoints"
              />
            )}
          />

          <Route exact path={`${Paths._people}/:id/market-data`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <MarketDataPage marketData={marketData} />}
          </Route>

          <Route exact path={`${url}/:id/similar`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarPeople
                email={props.BestEmailAddrText}
                jobTitle={props.BestJobTitleText || ''}
                marketData={marketData}
                similar={similar || similarWidget}
                setSimilar={setSimilar}
                loading={props.loading}
              />
            )}
          </Route>

          <Route exact path={`${url}/:id/similar/:similarId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <SimilarPersonPage getPersonDetails={getPersonDetails} />}
          </Route>

          <Route exact path={`${url}/:id/market-data/company/:companyId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CompanyMarketDataPage />}
          </Route>

          <PrivateRoute hasAccess={showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <UserInteractions minHeight={interactionsHeight} PersonMd5={props.PersonMd5} />
          </PrivateRoute>
        </Switch>
      </Dashboard>
    </>
  )
}

const PersonPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const memoUrls = useMemo(
    () => [
      {
        key: 'profile',
        url: `/people/${id}?teamNumber=${teamNumber}&includeTeamMembership=true&numCompanies=4&numUserContacts=4&numIntroducers=8&numAliases=5&numJobs=4&numPhones=2&numEmails=2&includeStats=true&sortBy=ScoreDesc&WithPersonTags=true&NumCrmSourceKeys=3`,
        merge: true,
        single: true
      },
      {
        key: 'salesforce',
        url: '/usersettings/salesforce'
      },
      {
        key: 'meUser',
        url: '/me/profile'
      }
    ],
    [id, teamNumber]
  )

  return (
    <Page>
      <DynamicEntity urls={memoUrls} component={PersonProfile} keepMounted id="person_profile" />
    </Page>
  )
}

export default PersonPage
