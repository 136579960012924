import React, { useEffect } from 'react'

import { Box, ButtonGroup, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import DotAlignSearch from '_core/components/DotAlignSearch'
import MarketDataSearch, { entityOptions } from '_core/components/MarketDataSearch'

import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSearchSettings from '_core/hooks/useSearchSettings'

import UserSettings from '_core/UserSettings'

const scopeOptions: { label: string; value: SearchScope }[] = [
  { label: 'DotAlign', value: 'DotAlign' },
  { label: 'Market data', value: 'MarketData' }
]

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    margin: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4),
      margin: `0 0 ${theme.spacing(2)} 0`
    }
  },
  buttons: {
    '&&': {
      '& > button, & > button:hover': {
        textTransform: 'uppercase'
      }
    }
  }
}))

const ControlsPanel = ({
  switchSearchScope,
  handleChange,
  queryParams: { scope, selectedEntity }
}: {
  handleChange: (params: ModifiedSearchPageParams) => void
  queryParams: ModifiedSearchPageParams
  switchSearchScope: (scope: SearchScope) => void
}) => {
  const { classes } = useStyles()

  const updateEntity = (selectedEntity: SearchEntities) => {
    handleChange({ selectedEntity, keyword: '' })
  }

  return (
    <Box className={classes.wrapper}>
      <ButtonGroup classes={{ root: classes.buttons }} color="primary" size="small" className="advanced-search-scope" disableElevation disableRipple>
        {scopeOptions.map(({ label, value }) => (
          <Button key={value} variant={value === scope ? 'contained' : 'outlined'} onClick={() => switchSearchScope(value)}>
            {label}
          </Button>
        ))}
      </ButtonGroup>

      {scope === 'MarketData' && (
        <ButtonGroup color="primary" size="small" className="advanced-search-entity" disableElevation disableRipple>
          {entityOptions.map(({ label, value }) => (
            <Button key={value} variant={value === selectedEntity ? 'contained' : 'outlined'} onClick={() => updateEntity(value)}>
              {label}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Box>
  )
}

const SearchPage = () => {
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const { handleChange, setInitial, loading, opened, toggleOpen, switchSearchScope } = useSearchSettings()
  const { scope, selectedEntity } = queryParams
  const { enabled: isMarketDataEnabled } = useMarketDataIntegration(!!scope)

  useEffect(() => {
    if (scope === 'MarketData' && isMarketDataEnabled === false) {
      switchSearchScope('DotAlign', selectedEntity)
    }
  }, [isMarketDataEnabled, scope, selectedEntity])

  return (
    <UserSettings endpoint="/usersettings/searchfilter" setInitial={setInitial}>
      {typeof isMarketDataEnabled === 'boolean' ? (
        <>
          {isMarketDataEnabled && <ControlsPanel handleChange={handleChange} switchSearchScope={switchSearchScope} queryParams={queryParams} />}
          {scope === 'DotAlign' && (
            <DotAlignSearch
              filtersLoading={loading}
              handleChange={handleChange}
              queryParams={queryParams}
              isMarketDataEnabled={isMarketDataEnabled}
            />
          )}
          {scope === 'MarketData' && isMarketDataEnabled && (
            <MarketDataSearch
              opened={opened}
              toggleOpen={toggleOpen}
              filtersLoading={loading}
              handleChange={handleChange}
              selectedEntity={selectedEntity || 'person'}
            />
          )}
        </>
      ) : (
        <Box height="calc(100vh - 120px)" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}
    </UserSettings>
  )
}

export default SearchPage
