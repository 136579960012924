import React, { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Empty from '_core/components/Empty'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { CSuiteType } from '_core/hooks/useCSuite'
import { transformResponse } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface CSuiteProps {
  companyName: string
  website: string
  people: CSuiteType | null
  setPeople: (val: CSuiteType | null) => void
  loading: boolean
}

const CSuite = ({ website, people, setPeople, companyName, ...props }: CSuiteProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort } = useAbortableFetch<PeopleMarketDataRes>()

  const load = props.loading || loading

  useEffect(() => {
    setSubHeader(`C Suite ${people?.total ? `· ${people.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, people?.total])

  const getPeople = async (loadMore?: boolean) => {
    setLoading(true)
    const res = await fetchWithAbort({
      url: mergeUrlWithParams(`/cloudhub/companies/${website}/csuite`, {
        titlecase: 'true',
        teamNumber: teamNumber.toString(),
        ...(loadMore && people?.scrollToken && { scrollToken: people.scrollToken })
      })
    })
    if (res) {
      const transformedData = transformResponse(res.data)
      setPeople({
        id,
        website,
        data: loadMore && people?.data ? [...people.data, ...transformedData] : transformedData,
        scrollToken: res.scrollToken || '',
        total: res.total
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (website && !people && !load && teamNumber) {
      getPeople()
    }
  }, [website, people, load, teamNumber])

  const loadMore = () => getPeople(true)

  const items = people?.data?.map((person) => {
    return {
      name: person.fullName,
      score: person.personMd5 ? person.score : undefined,
      link: person.personMd5
        ? `${Paths._people}/${person.personMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.fullName}`, {
            email: person.workEmail,
            company: person.jobCompanyName
          }),
      byline: person.jobTitle || '',
      byline2: person.jobStartDate ? `from ${moment(person.jobStartDate).format('MM/YYYY')}` : '',
      sidepanel: true
    }
  })

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <InfiniteList loading={loading} next={loadMore} dataLength={people?.total || 0} hasMore={!!people?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            variant="list"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !people }}
            items={items || []}
            empty={<Empty title="No records were found" />}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default CSuite
