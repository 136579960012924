import React from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import Button from '_shared/buttons/Button'
import Skeleton from '_shared/Skeleton'

import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'

import Paths from 'Paths'

const MarketDataSearchLink = (props: { entity: SearchEntities; keyword?: string }) => {
  const { enabled: isMarketDataEnabled } = useMarketDataIntegration(!!props.entity)

  if (isMarketDataEnabled === false) return null

  return (
    <Box display="flex" alignSelf="center">
      <Skeleton condition={!isMarketDataEnabled}>
        <Button<typeof Link>
          variant="link"
          disablePadding
          component={Link}
          to={`${Paths._search}?scope=MarketData&selectedEntity=${props.entity}&keyword=${props.keyword}`}
        >
          search external market data
        </Button>
      </Skeleton>
    </Box>
  )
}

export default MarketDataSearchLink
