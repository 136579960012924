import React, { useContext, useEffect, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Redirect, useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Repeater from '_core/components/lists/Repeater'
import { WarningStatusIcon } from '_core/components/StatusIcon'
import Widget from '_core/components/Widget'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { put } from 'utils/httpUtils'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type SettingItem = {
  handleClick: () => void
  picked: boolean
  disabled: boolean
} & Setting

type Setting = { icon: IconProp; label: string }

export const settingsDetails: { [key: string]: Setting } = {
  private: {
    icon: ['fas', 'lock'],
    label: 'Private, visible only to me'
  },
  shared: {
    icon: ['far', 'users'],
    label: 'Visible to the network team and collaborators you choose'
  }
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2)
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1)
  },
  icon: {
    width: 20
  },
  actionButtons: actionButtons(theme)
}))

type SharingSettingsProps = {
  defaultManager: { userKey?: string }
  request: IntroductionRequestResp
  loading: boolean
}

const SharingSettings = ({ defaultManager, request, ...props }: SharingSettingsProps) => {
  const { queryParams } = useSearchQuery<{ nextStep: string }>()
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const [readyForReview, setReadyForReview] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { classes } = useStyles()

  const { queriedByAppUserKey } = request || {}
  const plan = request?.plan || {}

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  useEffect(() => {
    setSubHeader('Sharing settings')
    return () => setSubHeader('')
  }, [setSubHeader])

  useEffect(() => {
    if (plan.planUid) {
      setReadyForReview(queryParams.nextStep ? true : !!plan.readyForReview)
    }
  }, [plan.planUid, plan.readyForReview, queryParams.nextStep])

  const isCreator = plan.planUid && queriedByAppUserKey === plan.creatorDotAppUserKey
  const detailsLink = `${Paths._introductions}/${plan.planUid}`
  if (plan.planUid && !isCreator) {
    return <Redirect to={detailsLink} />
  }

  const reportingFlow = queryParams.nextStep?.includes('add-participants')
  const assignDefaultManger = !reportingFlow && !plan.assignedToAppUserKey && defaultManager?.userKey
  const goToDetails = () => history.push(detailsLink)

  const save = async () => {
    setLoading(true)
    await put<IntroductionPlanResp>('/prospecting/editplan', {
      planUid: plan.planUid,
      ...(readyForReview
        ? { markReadyForReview: true, ...(assignDefaultManger ? { assigneeEmail: defaultManager.userKey } : {}) }
        : { moveBackToDraftState: true })
    })
    setLoading(false)
    if (queryParams.nextStep) {
      history.push(queryParams.nextStep)
    } else {
      enqueueSnackbar('Sharing settings have been updated')
      goToDetails()
    }
  }

  const disabled = props.loading || loading

  const items: SettingItem[] = [
    {
      icon: settingsDetails['private'].icon,
      label: settingsDetails['private'].label,
      picked: !readyForReview,
      handleClick: () => setReadyForReview(false),
      disabled
    },
    {
      icon: settingsDetails['shared'].icon,
      label: settingsDetails['shared'].label,
      picked: readyForReview,
      handleClick: () => setReadyForReview(true),
      disabled
    }
  ]

  return (
    <>
      <Widget scope="card">
        <Repeater items={items} component={SettingItem} direction="vertical" skeleton={{ loading: props.loading, size: 2 }} />
      </Widget>
      <Box className={classes.actionButtons}>
        <Button onClick={goToDetails} variant="text" color="secondary" disabled={disabled} disablePL>
          Cancel
        </Button>
        <Button onClick={save} variant="text" disabled={disabled} disablePR>
          {queryParams.nextStep ? 'Next' : 'Apply'}
        </Button>
      </Box>
    </>
  )
}

const SettingItem = ({ picked, disabled, handleClick, ...props }: SettingItem) => {
  const { classes } = useStyles()

  return (
    <Card>
      <CardContent>
        <Box className={classes.wrapper}>
          <SettingContent {...props} />
          <IconButton
            hint="Pick"
            disabled={disabled}
            onClick={handleClick}
            icon={['fas', 'check-circle']}
            color={picked ? 'success' : 'secondary'}
            disablePR
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export const SettingContent = (props: Partial<Setting>) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.content}>
      <Skeleton condition={!props.icon} width={24} height={24} variant="circular">
        {props.icon && <WarningStatusIcon icon={props.icon} iconClassName={classes.icon} />}
      </Skeleton>
      <Skeleton condition={!props.label}>
        <Typography align="left" semiBold>
          {props.label || 'Placeholder text'}
        </Typography>
      </Skeleton>
    </Box>
  )
}

export default SharingSettings
