import { Fragment, ReactElement, useContext, useEffect, useMemo } from 'react'

import { Box, useTheme } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { Link, matchPath, Route, Switch, useLocation, useParams } from 'react-router-dom'

import RelationshipActivitiesPage from '_pages/relationships/[userId]/people/[personId]/activities'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { PickAContributorRelationshipDialog, PickAContactRelationshipDialog } from '_core/components/dialogs/RelationshipPicker'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { MDByLine, MDPersonByLine2 } from '_core/components/MarketDataSummary'
import PrivateRoute from '_core/components/PrivateRoute'
import ProfileSummary from '_core/components/ProfileSummary'
import RelationshipScore from '_core/components/RelationshipScore'
import TitleDescription from '_core/components/TitleDescription'
import Topbar from '_core/components/Topbar'
import TouchpointRelations from '_core/components/TouchpointRelations'
import Widget from '_core/components/Widget'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useDialog from '_core/hooks/useDialog'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import { usePersonMarketData } from '_core/hooks/usePersonMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const RelationHeader = (props: Partial<UserPersonType> & { loading: boolean; enabledMarketData: boolean }) => {
  const wide = useWide()
  const { dialogContentProps, openDialog, closeDialog } = useDialog<{ type: 'user' | 'contact' }>()
  const { type } = dialogContentProps || {}

  const {
    IntroducerBestJobTitleText,
    IntroducerBestJobMatchedUrlText,
    IntroducerBestJobMatchedCompanyName,
    IntroducerBestJobCorpLevelCompanyName,
    IntroducerBestJobCompanyMd5,
    IntroducerBestJobCurrentAsOf,
    IntroducerBestJobIsFormer,
    IntroducerBestPhoneText
  } = props.Introducers?.data[0] || {}

  const { JobIsFormer: BestJobIsFormer, JobCurrentAsOf: BestJobCurrentAsOf } = props.Jobs?.data?.[0] || {}

  const { marketData: userMarketData, getMarketData: getUserMarketData } = usePersonMarketData(
    props.UserFullName || '',
    props.UserBestEmailAddress,
    IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName
  )

  const personName = props.PersonNameText || props.BestEmailAddrText

  const { marketData: contactMarketData, getMarketData: getContactMarketData } = usePersonMarketData(
    personName || '',
    props.BestEmailAddrText,
    props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName
  )

  useEffect(() => {
    if (wide && !props.loading && props.enabledMarketData && !userMarketData) {
      getUserMarketData()
    }
  }, [wide, props.enabledMarketData, userMarketData, props.loading, getUserMarketData])

  useEffect(() => {
    if (wide && !props.loading && props.enabledMarketData && !contactMarketData) {
      getContactMarketData()
    }
  }, [wide, props.enabledMarketData, contactMarketData, props.loading, getContactMarketData])

  const pickAContributorOpen = () => {
    openDialog({ type: 'user' })
  }

  const pickAContactOpen = () => {
    openDialog({ type: 'contact' })
  }

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'}>
        <Box display="flex" justifyContent="space-between">
          <Wide>
            <ProfileSummary
              title={props.UserFullName || ''}
              loading={props.loading}
              userKey={props.UserBestEmailAddress}
              profileLink={`${Paths._people}/${props?.UserKeyMd5}`}
              introducers={null}
              actions={[
                (props.loading || (!props.loading && IntroducerBestPhoneText)) && (
                  <IconButton
                    icon={['far', 'phone']}
                    color="primary"
                    hint={`Make a call to ${IntroducerBestPhoneText}`}
                    disablePadding
                    component="a"
                    href={`tel:${IntroducerBestPhoneText?.substring(2)}`}
                    loading={!IntroducerBestPhoneText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.UserBestEmailAddress)) && (
                  <IconButton
                    icon={['far', 'envelope']}
                    color="primary"
                    hint={`Send an email to ${props.UserBestEmailAddress}`}
                    disablePadding
                    component="a"
                    href={`mailto:${props.UserBestEmailAddress}`}
                    loading={!props.UserBestEmailAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.UserFullName)) && (
                  <IconButton
                    icon={['fab', 'linkedin']}
                    color="primary"
                    hint="Show Linkedin profile"
                    component="a"
                    disablePadding
                    href={
                      userMarketData?.linkedinUrl
                        ? `https://${userMarketData?.linkedinUrl}`
                        : `https://www.linkedin.com/search/results/people/?keywords=${props.UserFullName}`
                    }
                    loading={!props.UserFullName}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((props.enabledMarketData && !userMarketData) || (!props.loading && userMarketData?.githubUrl)) && (
                  <IconButton
                    icon={['fab', 'github']}
                    color="primary"
                    hint="Show Github profile"
                    component="a"
                    disablePadding
                    href={`https://${userMarketData?.githubUrl}`}
                    loading={!userMarketData?.githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(props.enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!userMarketData} locationName={userMarketData?.locationName} />,
                    byline2: (
                      <MDPersonByLine2
                        marketData={userMarketData}
                        bestJobMatchedUrlText={IntroducerBestJobMatchedUrlText}
                        internalCompanyName={IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        bestJobTitleText={IntroducerBestJobTitleText}
                        bestJobCompanyMd5={IntroducerBestJobCompanyMd5}
                        bestJobCurrentAsOf={IntroducerBestJobCurrentAsOf}
                        bestJobIsFormer={IntroducerBestJobIsFormer}
                      />
                    )
                  }
                : {
                    enabledMarketData: false,
                    byline: IntroducerBestJobTitleText,
                    byline2: (
                      <Typography variant="body1" noWrap color="primary.main">
                        <Link to={`${Paths._companies}/${IntroducerBestJobCompanyMd5}`}>
                          {IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        </Link>
                      </Typography>
                    )
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar name={props.UserFullName || ''} size="md" link={`${Paths._people}/${props?.UserKeyMd5}`} userKey={props.UserBestEmailAddress} />
          </Narrow>
          <Box display="flex" alignItems="center" p={2}>
            <PickAContributorRelationshipDialog.TriggerEl disabled={props.loading} open={pickAContributorOpen} />
          </Box>
          <RelationshipScore score={props.Score} />
          <Box display="flex" alignItems="center" p={2}>
            <PickAContactRelationshipDialog.TriggerEl disabled={props.loading} open={pickAContactOpen} />
          </Box>
          <Wide>
            <ProfileSummary
              flip
              userKey={props.MyUserKeyMd5 && props.BestEmailAddrText}
              title={personName || ''}
              loading={props.loading}
              introducers={null}
              profileLink={`${Paths._people}/${props.MyUserKeyMd5 || props.PersonMd5}`}
              actions={[
                (props.loading || (!props.loading && props.BestPhoneText)) && (
                  <IconButton
                    icon={['far', 'phone']}
                    color="primary"
                    hint={`Make a call to ${props.BestPhoneText}`}
                    disablePadding
                    component="a"
                    href={`tel:${props.BestPhoneText?.substring(2)}`}
                    loading={!props.BestPhoneText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.BestEmailAddrText)) && (
                  <IconButton
                    icon={['far', 'envelope']}
                    color="primary"
                    hint={`Send an email to ${props.BestEmailAddrText}`}
                    disablePadding
                    component="a"
                    href={`mailto:${props.BestEmailAddrText}`}
                    loading={!props.BestEmailAddrText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && personName)) && (
                  <IconButton
                    icon={['fab', 'linkedin']}
                    color="primary"
                    hint="Show Linkedin profile"
                    component="a"
                    disablePadding
                    href={
                      contactMarketData?.linkedinUrl
                        ? `https://${contactMarketData?.linkedinUrl}`
                        : `https://www.linkedin.com/search/results/people/?keywords=${props.PersonNameText}`
                    }
                    loading={!personName}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((props.enabledMarketData && !contactMarketData) || (!props.loading && contactMarketData?.githubUrl)) && (
                  <IconButton
                    icon={['fab', 'github']}
                    color="primary"
                    hint="Show Github profile"
                    component="a"
                    disablePadding
                    href={`https://${contactMarketData?.githubUrl}`}
                    loading={!contactMarketData?.githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(props.enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!contactMarketData} locationName={contactMarketData?.locationName} />,
                    byline2: (
                      <MDPersonByLine2
                        marketData={contactMarketData}
                        bestJobMatchedUrlText={props.BestJobMatchedUrlText}
                        internalCompanyName={props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                        bestJobTitleText={props.BestJobTitleText}
                        bestJobCompanyMd5={props.BestJobCompanyMd5}
                        bestJobCurrentAsOf={BestJobCurrentAsOf}
                        bestJobIsFormer={BestJobIsFormer}
                      />
                    )
                  }
                : {
                    enabledMarketData: false,
                    byline: props.BestJobTitleText,
                    byline2: (
                      <Typography variant="body1" noWrap color="primary.main">
                        <Link to={`${Paths._companies}/${props.BestJobCompanyMd5}`}>
                          {props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                        </Link>
                      </Typography>
                    )
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar
              name={personName || ''}
              size="md"
              link={`${Paths._people}/${props.MyUserKeyMd5 || props.PersonMd5}`}
              userKey={props.MyUserKeyMd5 || props.BestEmailAddrText}
            />
          </Narrow>
        </Box>
      </Widget>
      <PickAContributorRelationshipDialog isOpened={type === 'user'} close={closeDialog} />
      <PickAContactRelationshipDialog isOpened={type === 'contact'} close={closeDialog} />
    </>
  )
}

const ActivityStats = ({
  isDetailedActivityFromStatsWidgetVisible,
  introducerMd5,
  contactMd5,
  onChange
}: {
  isDetailedActivityFromStatsWidgetVisible: boolean
  introducerMd5: string
  contactMd5: string
  onChange: (params: ActivityStatsPageParams) => void
}) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()

  const { stackColumns = 'false', from: fromUTC, to: toUTC } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const statsPayloads = useContactActivitiesPayloads(contactMd5, introducerMd5)

  const { stats, clearStats } = useActivitiesStats(
    introducerMd5 ? statsPayloads : null,
    useMemo(() => [contactMd5], [contactMd5]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  useEffect(() => {
    if (!introducerMd5 || !contactMd5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introducerMd5, contactMd5])

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    onChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    onChange({ stackColumns: `${!JSON.parse(stackColumns)}` })
  }

  return (
    <ActivityStatsWidget
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      stats={stats}
      stack={stackColumns === 'true'}
      detailsLink={`${Paths._relationships}/${from}/people/${to}/activities`}
      updatePeriod={updatePeriod}
      toggleStack={toggleStack}
      {...chartData}
    />
  )
}

const LatestTouchpoints = ({ loading, stats }: { loading: boolean; stats: Partial<StatsType<{ ContactMd5: string }>> }) => (
  <Widget scope="stack">
    <Heading title="Latest interactions" icon={['far', 'handshake']} />
    <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={!loading ? TouchpointRelations(stats, 'Last') : []} />
  </Widget>
)

const RelationshipDetails = (
  props: {
    loading: boolean
    isStatsWidgetVisible: boolean
    isDetailedActivityFromStatsWidgetVisible: boolean
  } & UserPersonType
) => {
  const theme = useTheme()
  const wideStrict = useWide('lg')

  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Relationship details')
  }, [setMobileHeader])

  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { stackColumns } = queryParams

  const { setInitial: setActivitiesInitial, handleChange } = useActivitiesStatsUserSettings()
  const { enabled } = useMarketDataIntegration()

  if (!props.loading && !props.UserKeyMd5) return <Empty title="No relationship details found" />

  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible, loading, ...main } = props
  const { UserKeyMd5 = '', PersonNameText = '', BestEmailAddrText = '', UserFullName = '', Introducers, PersonMd5 = '' } = main
  const { IntroducerStats, IntroducerUserKeyMd5, IntroducerName } = Introducers?.data[0] || {}

  const ContactMd5Keys: (keyof StatsType<{ ContactMd5: string }>)[] = [
    'LastInboundMsgContactMd5',
    'LastOutboundMsgContactMd5',
    'LastMeetingContactMd5'
  ]

  const ContactNameText: (keyof StatsType<{ ContactMd5: string }>)[] = [
    'LastInboundMsgContactName',
    'LastOutboundMsgContactName',
    'LastMeetingContactName'
  ]

  const UserKeys: (keyof StatsType<{ ContactMd5: string }>)[] = ['LastInboundMsgUserKeyMd5', 'LastOutboundMsgUserKeyMd5', 'LastMeetingUserKeyMd5']
  const UserNames: (keyof StatsType<{ ContactMd5: string }>)[] = ['LastInboundMsgUserName', 'LastOutboundMsgUserName', 'LastMeetingUserName']

  const stats = {
    ...IntroducerStats,
    ...UserKeys.reduce((acc, key) => ({ ...acc, [key]: IntroducerUserKeyMd5 }), {}),
    ...UserNames.reduce((acc, key) => ({ ...acc, [key]: IntroducerName }), {}),
    ...ContactMd5Keys.reduce((acc, key) => ({ ...acc, [key]: PersonMd5 }), {}),
    ...ContactNameText.reduce((acc, key) => ({ ...acc, [key]: PersonNameText || BestEmailAddrText }), {})
  }

  const ActivitiesStatsWidgetEl = (
    <ActivityStats
      isDetailedActivityFromStatsWidgetVisible={props.isDetailedActivityFromStatsWidgetVisible}
      introducerMd5={UserKeyMd5}
      contactMd5={PersonMd5}
      onChange={handleChange}
    />
  )
  const LatestTouchpointsWidgetEl = <LatestTouchpoints loading={props.loading} stats={stats} />

  const columnsRows = [
    {
      condition: props.isStatsWidgetVisible,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [LatestTouchpointsWidgetEl]]
            }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [{ columns: [ActivitiesStatsWidgetEl] }, { columns: [[LatestTouchpointsWidgetEl]] }]
        }
      ]
    },
    {
      condition: !props.isStatsWidgetVisible,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[LatestTouchpointsWidgetEl]]
            }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [{ columns: [[LatestTouchpointsWidgetEl]] }]
        }
      ]
    }
  ]

  const sub = UserFullName && (PersonNameText || BestEmailAddrText) ? `${UserFullName} and ${PersonNameText || BestEmailAddrText}` : ''
  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <>
      <Narrow>
        <Topbar
          nativeBack
          autoHideOnScroll
          sub={
            <Skeleton condition={!sub} width={96}>
              <Typography title={sub} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                {sub || 'placeholder'}
              </Typography>
            </Skeleton>
          }
        />
      </Narrow>
      <UserSettings endpoint={!stackColumns && props.isStatsWidgetVisible ? '/usersettings/activitiesstats' : ''} setInitial={setActivitiesInitial}>
        <RelationHeader {...main} loading={props.loading} enabledMarketData={!!enabled} />
        <Wide>
          {colRows?.map(({ columns }, k) => (
            <Columns key={k}>
              {columns.map((column, i) => {
                if (!Array.isArray(column)) {
                  return (
                    <Column md={12} key={`${k}${i}`}>
                      {column}
                    </Column>
                  )
                }
                return (
                  <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                    {column.map((row, j) =>
                      Array.isArray(row) ? (
                        <Columns
                          key={`${k}${i}${j}`}
                          md={12}
                          minWidth={`calc(100% + ${theme.spacing(2)})`}
                          {...(!row.length ? { display: 'none' } : {})}
                        >
                          {row.map((r, y) => (
                            <Column md={6} key={`${k}${i}${j}${y}`}>
                              {r}
                            </Column>
                          ))}
                        </Columns>
                      ) : (
                        <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                      )
                    )}
                  </Column>
                )
              })}
            </Columns>
          ))}
        </Wide>
        <Narrow>
          {props.isStatsWidgetVisible && ActivitiesStatsWidgetEl}
          {LatestTouchpointsWidgetEl}
        </Narrow>
      </UserSettings>
    </>
  )
}

const RelationshipUserContact = ({ loading, forceNarrow, ...relProps }: { loading: boolean; forceNarrow: boolean } & UserPersonType) => {
  const { pathname } = useLocation()
  const { from, to } = useParams<{ from: string; to: string }>()

  const activitiesPath = `${Paths._relationships}/:from/:via/:to/activities`
  const { isExact: matchActivities = false } = matchPath(pathname, { path: activitiesPath }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([from, to, matchActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  const { UserFullName, PersonNameText, BestEmailAddrText } = relProps || {}

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
          path={activitiesPath}
          render={() => <RelationshipActivitiesPage userName={UserFullName} personName={PersonNameText || BestEmailAddrText} />}
        />
        <Route path={`${Paths._relationships}/:from/:via/:to`}>
          <RelationshipDetails
            isStatsWidgetVisible={!!isStatsWidgetVisible}
            isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
            loading={loading}
            {...relProps}
          />
        </Route>
      </Switch>
    </>
  )
}
const RelationshipUserContactPage = () => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const wide = useWide()

  return (
    <DynamicEntity
      url={`/users/${from}/people/${to}?TeamNumber=${teamContextValue.teamNumber}&Take=${wide ? 4 : 3}&IncludeIntroducerStats=True`}
      component={RelationshipUserContact}
      nativeBack={true}
      keepMounted
      id="rel_details"
    />
  )
}

export default RelationshipUserContactPage
