import React, { useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Route, Switch, useParams, useLocation, matchPath, Redirect } from 'react-router-dom'

import ActivitiesPage from '_pages/companies/[id]/activities'
import ActivityStatsPages from '_pages/companies/[id]/activityStats'
import CSuite from '_pages/companies/[id]/c-suite'
import CSuitePage from '_pages/companies/[id]/c-suite/[id]'
import ExternalKeysPage, { ExternalKeysList, ExternalKeysListPropsType } from '_pages/companies/[id]/externalKeys'
import MarketDataPage from '_pages/companies/[id]/market-data'
import CompanyMarketDataPage from '_pages/companies/[id]/market-data/company'
import SimilarCompanies from '_pages/companies/[id]/similar'
import SimilarCompanyPage from '_pages/companies/[id]/similar/[id]'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import CompanyHeader from '_core/components/CompanyHeader'
import Dashboard, { DashboardLinkType } from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'
import PrivateRoute from '_core/components/PrivateRoute'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import { useCSuite } from '_core/hooks/useCSuite'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { useSimilarCompanies } from '_core/hooks/useSimilarCompanies'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'

import { get } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import Aliases from './aliases'
import AliasesNames from './aliases/names'
import AliasesDomains from './aliases/urls'
import Contacts from './contacts'
import Interactions from './interactions'
import Introducers from './introducers'
import Profile from './profile'
import Relationships from './relationships'
import Touchpoints from './touchpoints'

type CompanyProfileType = {
  loading: boolean
  salesforce?: {
    isEnabled: true
  }
} & CompanyType

const url = Paths._companies

const CompanyProfile = ({ salesforce, ...props }: CompanyProfileType) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<CompanyProfilePageParams>()
  const { isExact: matchProfile } = matchPath(pathname, { path: Paths.companyProfile }) || {}
  const [interactions, setInteractions] = useState<{ [key: string]: any } | null>()
  const enableScroll = interactions !== undefined && !props.loading && !!props.CompanyMd5 && matchProfile
  const { enabled: enabledMarketData, showSimilarCompanies } = useMarketDataIntegration()
  usePresetScroll(enableScroll)

  const companyName = props.CompanyNameText || props.BestUrlText

  const prohibitedMarketData = typeof enabledMarketData === 'boolean' && !enabledMarketData
  const { name: mdName, website: mdWebsite } = !props.loading && !props.CompanyMd5 ? queryParams : { name: companyName, website: props.BestUrlText }
  const { marketData, getMarketData } = useCompanyMarketData(mdName || '', mdWebsite)
  const { similar, setSimilar, similarWidget, setSimilarWidget, getCompanyDetails } = useSimilarCompanies()
  const { cSuite, setCSuite, getPersonDetails } = useCSuite()

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile } = dataVisibility || {}

  const activitiesPath = `${url}/:id/activities`
  const { isExact: matchCompanyActivities = false } = matchPath(pathname, { path: activitiesPath }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([id, matchCompanyActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  const dashboardLinks: DashboardLinkType[] = [
    {
      name: 'Dashboard',
      link: `${url}/${props.CompanyMd5}`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'th'] as IconProp
    },
    {
      name: 'Activity statistics',
      link: `${url}/${props.CompanyMd5}/activityStats`,
      condition: isStatsWidgetVisible,
      icon: ['far', 'chart-simple'] as IconProp
    },
    {
      name: 'Introducers',
      link: `${url}/${props.CompanyMd5}/introducers`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'address-book'] as IconProp
    },
    {
      name: 'Interactions',
      link: `${url}/${props.CompanyMd5}/interactions`,
      condition: showInteractionsInProfile,
      icon: ['far', 'envelope'] as IconProp
    },
    {
      name: 'Contacts',
      link: `${url}/${props.CompanyMd5}/contacts`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'user'] as IconProp
    },
    {
      name: 'Touchpoints',
      link: `${url}/${props.CompanyMd5}/touchpoints`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'handshake'] as IconProp
    },
    {
      name: 'Key Relationships',
      link: `${url}/${props.CompanyMd5}/relationships`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'star'] as IconProp
    },
    {
      name: 'Aliases and domains',
      link: `${url}/${props.CompanyMd5}/aliases`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'fingerprint'] as IconProp
    },
    {
      name: 'External Keys',
      link: `${url}/${props.CompanyMd5}/keys`,
      condition: !!props.CrmSourceKeys?.data?.length,
      icon: ['fas', 'share-alt'] as IconProp
    }
  ].filter(({ condition }) => condition)

  const alternateMobileHeader = matchPath(pathname, {
    path: [
      `${Paths.companyProfile}/similar/:similarId`,
      `${Paths.companyProfile}/c-suite/:cSuiteId`,
      `${Paths.companyProfile}/market-data/company/:companyId`
    ]
  })?.isExact

  useEffect(() => {
    if (!alternateMobileHeader) {
      setMobileHeader(!props.loading ? queryParams.name || companyName : '', props.loading)
    }
  }, [companyName, setMobileHeader, alternateMobileHeader, props.loading, queryParams.name])

  useEffect(() => {
    return () => {
      setInteractions(undefined)
      setSimilarWidget(null)
      setCSuite(null)
    }
  }, [props.CompanyMd5])

  useEffect(() => {
    const getInteractions = async () => {
      setInteractions(await get(`/interactions?TeamNumber=${teamNumber}&companyMd5=${props.CompanyMd5}`))
    }

    if (matchProfile && !props.loading && interactions === undefined) {
      if (showInteractionsInProfile) {
        getInteractions()
      } else {
        setInteractions(null)
      }
    }
  }, [interactions, matchProfile, props.CompanyMd5, showInteractionsInProfile, props.loading, teamNumber])

  useEffect(() => {
    if (!props.loading && enabledMarketData && !marketData) {
      getMarketData()
    }
  }, [enabledMarketData, marketData, props.loading, getMarketData])

  const interactionsHeight = dashboardLinks.length * 78

  if (!props.loading && !props.CompanyMd5) {
    return queryParams.name ? (
      <>
        {enabledMarketData && queryParams.website ? (
          <CompanyMarketData company={marketData} />
        ) : (
          <>
            <Widget scope="none">
              <UnknownProfileSummary loading={props.loading} title={queryParams.name} url={queryParams.website} />
            </Widget>
            <Widget scope="list">
              <Empty title="Profile not found in DotAlign" />
            </Widget>
          </>
        )}
      </>
    ) : (
      <Empty title="Profile not found" closeExtension />
    )
  }

  const Header = (
    <CompanyHeader
      marketData={marketData}
      isSalesforceEnabled={salesforce?.isEnabled}
      enabledMarketData={enabledMarketData}
      showSimilarCompanies={!!showSimilarCompanies}
      {...props}
    />
  )

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          {Header}
        </Route>
        <Route exact path={`${url}/:id/*`}>
          <Wide>{Header}</Wide>
        </Route>
      </Switch>

      <Dashboard loading={props.loading} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile
              interactions={interactions}
              marketData={marketData}
              similar={similarWidget}
              setSimilar={setSimilarWidget}
              cSuite={cSuite}
              setCSuite={setCSuite}
              enabledMarketData={enabledMarketData}
              showSimilarCompanies={!!showSimilarCompanies}
              showInteractionsInProfile={!!showInteractionsInProfile}
              showActivityStats={!!isStatsWidgetVisible}
              isDetailedActivityFromStatsWidgetVisible={!!isDetailedActivityFromStatsWidgetVisible}
              {...props}
            />
          </Route>

          <PrivateRoute
            hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
            exact
            path={activitiesPath}
            render={() => <ActivitiesPage companyName={companyName} />}
          />
          <PrivateRoute
            hasAccess={isStatsWidgetVisible}
            exact
            path={`${url}/:id/activityStats`}
            render={() => <ActivityStatsPages companyName={companyName} />}
          />
          <Route exact path={`${url}/:id/relationships`}>
            <DynamicEntity
              url={`/companies/${id}/people?TeamNumber=${teamNumber}&SortBy=ScoreDesc&employeeType=Current`}
              component={Relationships}
              list={true}
              infinite={true}
              nativeBack
              subHeader={(props: any) => (
                <Typography title={`Key relationships · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                  Key relationships · {props.total_item_count}
                </Typography>
              )}
              id="company_relationships"
            />
          </Route>

          <Route exact path={`${url}/:id/introducers`} component={Introducers} />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={`/companies/${id}?teamNumber=${teamNumber}&includeStats=true`}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap>
                    Key touch points
                  </Typography>
                )}
                id="company_touchpoints"
              />
            )}
          />
          <Route exact path={`${url}/:id/contacts`} component={Contacts} />
          <Route exact path={`${url}/:id/aliases/urls`} render={() => <AliasesDomains companyName={companyName} />} />
          <Route exact path={`${url}/:id/aliases/names`} render={() => <AliasesNames companyName={companyName} />} />
          <Route exact path={`${url}/:id/aliases`} render={() => <Aliases companyName={companyName} />} />
          <Route exact path={`${url}/:id/similar`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <SimilarCompanies
                website={props.BestUrlText}
                marketData={marketData}
                similar={similar || similarWidget}
                setSimilar={setSimilar}
                loading={props.loading}
              />
            )}
          </Route>
          <Route exact path={`${url}/:id/similar/:similarId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <SimilarCompanyPage getCompanyDetails={getCompanyDetails} />}
          </Route>
          <Route exact path={`${url}/:id/c-suite`}>
            {prohibitedMarketData ? (
              <Redirect to={`${url}/${id}`} />
            ) : (
              <CSuite companyName={companyName} website={props.BestUrlText} loading={props.loading} people={cSuite} setPeople={setCSuite} />
            )}
          </Route>
          <Route exact path={`${url}/:id/c-suite/:cSuiteId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CSuitePage getPersonDetails={getPersonDetails} />}
          </Route>
          <Route exact path={`${url}/:id/market-data`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <MarketDataPage marketData={marketData} />}
          </Route>
          <Route exact path={`${url}/:id/market-data/company/:companyId`}>
            {prohibitedMarketData ? <Redirect to={`${url}/${id}`} /> : <CompanyMarketDataPage />}
          </Route>
          <PrivateRoute hasAccess={showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <Interactions minHeight={interactionsHeight} CompanyMd5={props.CompanyMd5} />
          </PrivateRoute>
          <Route
            exact
            path={`${url}/:id/keys`}
            render={() => (
              <ExternalKeysPage>
                <DynamicEntity
                  url={`/companies/${id}/externalkeys?TeamNumber=${teamNumber}`}
                  component={ExternalKeysList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  keepMounted
                  subHeader={(props: ExternalKeysListPropsType) => (
                    <Typography title={`External keys · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      External keys · {props.total_item_count}
                    </Typography>
                  )}
                  id="company_external_keys"
                />
              </ExternalKeysPage>
            )}
          />
        </Switch>
      </Dashboard>
    </>
  )
}

const CompanyPage = (props: any) => {
  const { id } = props.match.params
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const memoUrls = useMemo(
    () => [
      {
        key: 'profile',
        url: `/companies/${id}?teamNumber=${teamNumber}&numIntroducers=5&numEmployees=7&numUrls=4&numAliases=4&includeStats=true&excludeFormerRoles=true&WithCompanyTags=true&NumCrmSourceKeys=3`,
        merge: true,
        single: true
      },
      {
        key: 'salesforce',
        url: '/usersettings/salesforce'
      }
    ],
    [id, teamNumber]
  )

  return (
    <Page>
      <DynamicEntity urls={memoUrls} component={CompanyProfile} keepMounted id="company_profile" />
    </Page>
  )
}

export default CompanyPage
