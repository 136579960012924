import { useContext, useEffect, useMemo } from 'react'

import { Box, Tab, Tabs } from '@mui/material'
import { Link, Route } from 'react-router-dom'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import SettingsAccordion from '_core/components/SettingsAccordion'
import Widget from '_core/components/Widget'

import useAdmin from '_core/hooks/useAdmin'
import useTabs from '_core/hooks/useTabs'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import ActivitiesSettingsPage from './activities'
import CloudHubSettingsPage from './cloudhub'
import DataAnalyticsSettingsPage from './dataAnalytics'
import DataProcessingSettingsPage from './dataProcessing'
import DataVisibilitySettingsPage from './dataVisibility'
import EmailAlertsSettingsPage from './emailAlerts'
import GeneralSettingsPage from './general'
import IntroductionsSettingsPage from './introductions'
import LinkedInSettingsPage from './linkedin'
import SalesforceSettingsPage from './salesforce'

type Tab = {
  label: string
  component: () => JSX.Element
  condition?: boolean
}

export const AdminSettings = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const admin = useAdmin()

  useEffect(() => {
    setMobileHeader('Admin settings')
  }, [setMobileHeader])

  const tabs = useMemo(
    () => [
      {
        label: 'GENERAL',
        component: GeneralSettingsPage,
        routesMatch: [`${Paths._adminSettings}`]
      },
      {
        label: 'Cloud Hub',
        component: CloudHubSettingsPage,
        routesMatch: [`${Paths._adminSettings}/cloudhub`]
      },
      {
        label: 'EMAIL ALERTS',
        component: EmailAlertsSettingsPage,
        routesMatch: [`${Paths._adminSettings}/emailalerts`]
      },
      {
        label: 'SALESFORCE',
        component: SalesforceSettingsPage,
        routesMatch: [`${Paths._adminSettings}/salesforce`]
      },
      {
        label: 'DATA PROCESSING',
        component: DataProcessingSettingsPage,
        routesMatch: [`${Paths._adminSettings}/dataprocessing`]
      },
      {
        label: 'DATA VISIBILITY',
        component: DataVisibilitySettingsPage,
        routesMatch: [`${Paths._adminSettings}/datavisibility`]
      },
      {
        label: 'DATA ANALYTICS',
        component: DataAnalyticsSettingsPage,
        routesMatch: [`${Paths._adminSettings}/dataanalytics`]
      },
      {
        label: 'LINKEDIN',
        component: LinkedInSettingsPage,
        routesMatch: [`${Paths._adminSettings}/linkedin`]
      },
      {
        label: 'Activities',
        component: ActivitiesSettingsPage,
        routesMatch: [`${Paths._adminSettings}/activites`]
      },
      {
        label: 'Introductions',
        component: IntroductionsSettingsPage,
        routesMatch: [`${Paths._adminSettings}/introductions`]
      }
    ],
    []
  )

  const { tabIndex } = useTabs(tabs)

  return (
    <Page>
      {admin && (
        <Widget scope="none">
          <Wide>
            <Box mx={5} my={3} minHeight={'calc(100vh - 215px)'}>
              <Typography variant="h1" style={{ marginBottom: 16 }}>
                Admin settings
              </Typography>
              <Tabs value={tabIndex > -1 ? tabIndex : false} variant="scrollable" scrollButtons="auto">
                {tabs.map(({ routesMatch, label }) => (
                  <Tab key={routesMatch[0]} label={label} component={Link} to={routesMatch[0]} />
                ))}
              </Tabs>
              {tabs.map(({ routesMatch, component }) => (
                <Route exact key={routesMatch[0]} path={routesMatch} component={component} />
              ))}
            </Box>
          </Wide>
          <Narrow>
            <SettingsAccordion tabs={tabs} />
          </Narrow>
        </Widget>
      )}
    </Page>
  )
}
