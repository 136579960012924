import React from 'react'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

type SourceType = {
  aggLevel: 'NonComposite' | 'MeOnlyComposite'
  aggLevelId: number
  distillationType: 'CompanyIdentity' | 'PersonIdentity'
  graphOwnerUserKey: string
  graphSourceType?: 'EmailMessages' | 'MarketData' | 'ContactCards'
  graphUri: string
  highMd5: string
  lowMd5: string
  namedGraphId: number
  rowNumber: number
  sourceTally: number
  subjectMd5: string
  total: number
}

const TupleSourcesList = (props: { loading: boolean; items: SourceType[] }) => (
  <Widget scope="list">
    <Repeater
      variant="list"
      items={props.items.map((item) => ({
        name: item.graphOwnerUserKey,
        userKey: item.graphOwnerUserKey,
        byline: item.aggLevel === 'NonComposite' ? item.graphSourceType : item.aggLevel
      }))}
      component={ProfileItem}
      skeleton={{ size: 5, loading: props.loading }}
    />
  </Widget>
)

export default TupleSourcesList
