import React, { useContext, useEffect } from 'react'

import Page from '_shared/Page'

import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import CompanyData from '_core/components/market-data/CompanyData'
import ReportIncorrectData from '_core/components/market-data/Report'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface PageProps {
  marketData: CompanyMarketDataType | null
}

const MarketDataPage = ({ marketData }: PageProps) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        {(!marketData || marketData.id) && (
          <Widget>
            <CompanyData data={marketData} />
          </Widget>
        )}
        {marketData && !marketData.id && (
          <Widget>
            <Empty title="No data found" />
          </Widget>
        )}
        <ReportIncorrectData marketData={JSON.stringify(marketData)} />
      </Narrow>
    </Page>
  )
}

export default MarketDataPage
