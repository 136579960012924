import React, { useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import { Button, IconButton } from '_shared/buttons'
import Popover from '_shared/popovers/Popover'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import DeleteIntroductionRequestDialog from '_core/components/dialogs/DeleteIntroductionRequest'
import UpdateRequestTypeDialog from '_core/components/dialogs/UpdateIntroductionRequestType'
import Empty from '_core/components/Empty'
import Heading, { HeadingButton } from '_core/components/Heading'
import { SettingContent, settingsDetails } from '_core/components/introductions/SharingSettings'
import StatusChip, { defineStatus, StatusType } from '_core/components/introductions/StatusChip'
import Repeater from '_core/components/lists/Repeater'
import ProfileLinkBox from '_core/components/ProfileLinkBox'
import { SummaryContent } from '_core/components/SummaryItem'
import Widget from '_core/components/Widget'

import useActiveDirectoryPersonData, { PersonType } from '_core/hooks/useActiveDirectoryPersonData'
import useAssignee, { AssigneeType } from '_core/hooks/useAssignee'
import useCollaboratorsAccess, { Colleague, Team } from '_core/hooks/useCollaboratorsAccess'
import useDialog from '_core/hooks/useDialog'
import useIntroductionReason from '_core/hooks/useIntroductionReason'
import { BeneficiaryType } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { sum } from '_core/helpers/numbers'

import { ellipsisText, formatDate, formatDateTime, formatNumberWithSuffix } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  },
  heading: {
    margin: 0
  },
  block: {
    marginBottom: theme.spacing(2)
  },
  iconButton: {
    fontSize: 16,
    width: '40px'
  },
  wrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto'
  },
  assigneeWrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(2)
  },
  assigneeContent: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(0, max-content) auto',
    justifyContent: 'end'
  },
  assigneeIcon: {
    marginLeft: theme.spacing(2)
  }
}))

type RequestDetailsProps = {
  request: IntroductionRequestResp
  loading: boolean
}

const RequestDetails = ({ request, ...props }: RequestDetailsProps) => {
  const { queriedByAppUserKey, querierIsLtnManager, querierHasWriteAccess } = request || {}
  const plan = request?.plan || {}
  const contacts = request?.contacts || []

  const {
    created,
    planSummary,
    planDescription,
    planUid,
    requesterEmail,
    assignedToAppUserKey,
    readyForReview,
    beneficiaryPersonName,
    beneficiaryCompanyName,
    beneficiaryPersonEmail,
    beneficiaryCompanyUrl,
    creatorDotAppUserKey
  } = plan
  const getReason = useIntroductionReason()
  const { setMobileHeader } = useContext(LayoutContext)
  const { updateParent } = useSidepanelPayloads()
  const {
    dialogContentProps: openedDialog,
    openDialog,
    closeDialog,
    successMode,
    openSuccess
  } = useDialog<'delete' | 'updateRequestType' | null>(null)
  const { person: requester, loading: requesterLoading } = useActiveDirectoryPersonData(requesterEmail)
  const { person: creator, loading: creatorLoading } = useActiveDirectoryPersonData(
    requesterEmail?.toLowerCase() === creatorDotAppUserKey?.toLowerCase() ? '' : creatorDotAppUserKey
  )
  const { teamAccesses, appUserAccesses } = useCollaboratorsAccess(request?.appUserAccesses, request?.teamAccesses, props.loading)
  const { assignee, loading: assigneeLoading } = useAssignee(planUid, assignedToAppUserKey)
  const [closedOut, setClosedOut] = useState<string>()
  const reason = getReason(plan.reason)

  useEffect(() => {
    setMobileHeader(planSummary, !planSummary)
  }, [setMobileHeader, planSummary])

  useEffect(() => {
    setClosedOut(plan.closedOut)
  }, [plan.closedOut])

  const loading = props.loading || requesterLoading || creatorLoading
  const isCreator = !!planUid && queriedByAppUserKey === creatorDotAppUserKey
  const isRequester = !!planUid && queriedByAppUserKey === requesterEmail

  const beneficiaryType: BeneficiaryType = beneficiaryCompanyName ? 'company' : 'person'
  const beneficiary = {
    name: !loading ? beneficiaryCompanyName || beneficiaryPersonName : '',
    url: !loading ? beneficiaryCompanyUrl || beneficiaryPersonEmail : ''
  }

  const actions = useMemo(
    () =>
      [
        {
          name: 'Edit request',
          icon: ['far', 'edit'],
          link: `${Paths._introductions}/${planUid}/edit`,
          condition: querierHasWriteAccess && !closedOut
        },
        {
          name: 'Delete request',
          icon: ['far', 'trash'],
          action: () => openDialog('delete'),
          condition: isCreator || querierIsLtnManager
        },
        {
          name: 'Close request',
          icon: ['far', 'box-archive'],
          action: () => openDialog('updateRequestType'),
          condition: (isCreator || querierIsLtnManager) && !closedOut
        },
        {
          name: 'Make request active',
          icon: ['far', 'box-open'],
          action: () => openDialog('updateRequestType'),
          condition: (isCreator || querierIsLtnManager) && closedOut
        }
      ].filter((item) => item.condition || loading),
    [isCreator, querierIsLtnManager, querierHasWriteAccess, loading, closedOut, planUid]
  )

  const status = defineStatus(planUid, !!closedOut)

  const reload = () => updateParent({ action: 'RELOAD_LIST', value: 'introductions' })

  return (
    <>
      <MainInfoWidget
        actions={actions}
        loading={loading}
        status={status}
        reason={reason}
        headline={planSummary}
        description={planDescription}
        created={created}
        creator={creator}
        requester={requester}
        beneficiary={beneficiary}
        beneficiaryType={beneficiaryType}
      />
      {(isCreator || isRequester) && <SharingSettingsWidget planUid={planUid} readyForReview={!!readyForReview} loading={loading} />}
      {!!readyForReview && (
        <AssigneeWidget loading={loading || assigneeLoading} planUid={planUid} assignee={assignee} querierIsLtnManager={querierIsLtnManager} />
      )}
      <ContactsWidget contacts={contacts} loading={loading} planUid={planUid} querierHasWriteAccess={querierHasWriteAccess} closedOut={!!closedOut} />
      {!!readyForReview && <CollaboratorsWidget planUid={planUid} isCreator={isCreator} colleagues={appUserAccesses} teams={teamAccesses} />}
      <OutcomeWidget
        loading={loading}
        planUid={planUid}
        querierIsLtnManager={querierIsLtnManager}
        isCreator={isCreator}
        date={plan.outcomeDate}
        memo={plan.outcomeMemo}
        usd={plan.outcomeUsd}
        rating={plan.outcomeRating}
        editorUserKey={plan.outcomeEditorAppUserKey}
      />
      <DeleteIntroductionRequestDialog
        isOpened={openedDialog === 'delete'}
        closeDialog={closeDialog}
        reload={reload}
        items={[planUid]}
        openSuccess={openSuccess}
        successMode={successMode}
      />
      <UpdateRequestTypeDialog
        outputType={closedOut ? 'active' : 'closed'}
        isOpened={openedDialog === 'updateRequestType'}
        closeDialog={closeDialog}
        planUid={planUid}
        openSuccess={openSuccess}
        successMode={successMode}
        setUpdatedValue={setClosedOut}
        updateParent={updateParent}
      />
    </>
  )
}

type BeneficiaryEntity = { name: string; url: string }

type MainInfoWidgetProps = {
  loading: boolean
  headline: string
  reason: string
  description: string
  created: string
  creator: PersonType
  requester: PersonType
  beneficiary: BeneficiaryEntity
  beneficiaryType: BeneficiaryType
  actions: { [key: string]: any }[]
  status: StatusType | undefined
}

type AssigneeProps = {
  loading: boolean
  querierIsLtnManager: boolean
  planUid: string
  assignee: AssigneeType
}

type CollaboratorsWidgetProps = {
  colleagues: Colleague[] | undefined
  teams: Team[] | undefined
  isCreator: boolean
  planUid: string
}

const MainInfoWidget = ({
  status,
  reason,
  loading,
  actions,
  headline,
  description,
  created,
  creator,
  requester,
  beneficiary,
  beneficiaryType
}: MainInfoWidgetProps) => {
  const { classes } = useStyles()

  return (
    <Widget scope="none">
      <Box className={classes.block}>
        <StatusChip status={loading ? undefined : status} />
      </Box>
      <Box className={classes.block}>
        <SummaryContent
          priorLine={reason}
          title={loading ? '' : headline}
          byline={loading ? '' : description}
          byline2={
            <Box display="flex" alignItems="center" mb={0.5}>
              <Skeleton condition={loading} width={80} height={24}>
                <Tooltip title={formatDateTime(created)}>
                  <Typography color="text.secondary" semiBold>
                    {formatDate(created)}
                  </Typography>
                </Tooltip>
              </Skeleton>
            </Box>
          }
          score={-1}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(120px, 1fr))" gap={2} className={classes.block}>
        {(loading || beneficiary?.name) && (
          <>
            <ProfileLinkBox
              heading="Requester"
              loading={loading}
              name={requester.displayName}
              userKey={requester.emailAddress}
              link={`${Paths._people}/${requester.emailAddress}`}
            />
            {beneficiaryType === 'company' ? (
              <ProfileLinkBox
                heading="Beneficiary"
                loading={loading}
                name={beneficiary?.name}
                logoUrl={beneficiary?.url}
                link={`${Paths._companies}/${beneficiary?.url}`}
              />
            ) : (
              <ProfileLinkBox
                heading="Beneficiary"
                loading={loading}
                name={beneficiary?.name}
                logoUrl={beneficiary?.url}
                link={`${Paths._people}/${beneficiary?.url}`}
              />
            )}
          </>
        )}
        {creator.displayName && (
          <ProfileLinkBox
            heading="Creator"
            loading={loading}
            name={creator.displayName}
            userKey={creator.emailAddress}
            link={`${Paths._people}/${creator.emailAddress}`}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        {actions.map((action) => (
          <Box key={action.name}>
            {!loading && action.link ? (
              <Link to={action.link}>
                <IconButton color="primary" hint={action.name} icon={action.icon} size="small" />
              </Link>
            ) : (
              <IconButton color="primary" hint={action.name} loading={loading} icon={action.icon} onClick={action.action} size="small" />
            )}
          </Box>
        ))}
      </Box>
    </Widget>
  )
}

const AssigneeWidget = ({ querierIsLtnManager, planUid, assignee, loading }: AssigneeProps) => {
  const { classes, cx } = useStyles()

  return (
    <Widget>
      <Box className={classes.assigneeWrapper}>
        <Heading underlined title="Assignee" icon={['fas', 'user']} className={classes.heading} />
        <Box className={classes.assigneeContent}>
          {(assignee.displayName || loading) && (
            <ProfileLinkBox
              loading={loading}
              name={assignee.displayName}
              userKey={assignee.emailAddress}
              link={`${Paths._people}/${assignee.emailAddress}`}
            />
          )}

          {!loading && (
            <>
              {querierIsLtnManager && (
                <Link to={`${Paths._introductions}/${planUid}/assignee`}>
                  {assignee.displayName ? (
                    <IconButton
                      icon={['far', 'edit']}
                      color="primary"
                      hint="Reassign manager"
                      classes={{ root: cx(classes.assigneeIcon, classes.iconButton) }}
                      disablePadding
                    />
                  ) : (
                    <Button color="primary" variant="text" disablePR>
                      Pick
                    </Button>
                  )}
                </Link>
              )}
              {!assignee.displayName && !querierIsLtnManager && <Typography>No manager</Typography>}
            </>
          )}
        </Box>
      </Box>
    </Widget>
  )
}

const SharingSettingsWidget = ({ readyForReview, loading, planUid }: { readyForReview: boolean; loading: boolean; planUid: string }) => {
  const { classes } = useStyles()

  const settings: { picked: boolean; label: string; icon: IconProp }[] = [
    {
      icon: settingsDetails['private'].icon,
      label: settingsDetails['private'].label,
      picked: !readyForReview
    },
    {
      icon: settingsDetails['shared'].icon,
      label: settingsDetails['shared'].label,
      picked: readyForReview
    }
  ]

  const currentSetting = loading ? undefined : settings.find((el) => el.picked)

  return (
    <Widget>
      <Box className={classes.wrapper}>
        <SettingContent icon={currentSetting?.icon} label={currentSetting?.label} />
        {!loading && (
          <Link to={`${Paths._introductions}/${planUid}/sharing-settings`}>
            <IconButton icon={['far', 'edit']} hint="Edit" classes={{ root: classes.iconButton }} color="primary" disablePY disablePR />
          </Link>
        )}
      </Box>
    </Widget>
  )
}

const CollaboratorsWidget = (props: CollaboratorsWidgetProps) => {
  const { colleagues = [], teams = [], isCreator, planUid } = props
  const loading = !props.colleagues || !props.teams
  const collaborators = sum(colleagues.length, teams.length)
  const baseUrl = `${Paths._introductions}/${planUid}/collaborators`

  return (
    <Widget>
      <Heading
        underlined
        title="Collaborators"
        count={collaborators || -1}
        icon={['fas', 'lock']}
        link={collaborators ? baseUrl : ''}
        action={
          !loading && !collaborators && isCreator ? (
            <Link to={`${baseUrl}/add`}>
              <HeadingButton title="Add" />
            </Link>
          ) : null
        }
      />
      <Typography bold>Colleagues</Typography>
      <Repeater
        direction="horizontal"
        component={Avatar}
        variant="homepage"
        skeleton={{ size: 3, loading }}
        items={colleagues.slice(0, 3).map((person: Colleague) => ({
          name: person.name,
          userKey: person.email,
          link: `${Paths._people}/${person.email}`
        }))}
        empty="No colleagues"
      />
      <Box mt={1.5}>
        <Typography bold>Teams</Typography>
        <Repeater
          direction="horizontal"
          component={Avatar}
          variant="homepage"
          skeleton={{ size: 3, loading }}
          items={teams.slice(0, 3).map((team: Team) => ({
            name: team.teamName,
            link: `${Paths._teams}/${team.teamNumber}/members`
          }))}
          empty="No teams"
        />
      </Box>
    </Widget>
  )
}

const ContactsWidget = ({
  contacts,
  loading,
  querierHasWriteAccess,
  planUid,
  closedOut
}: {
  contacts: any[]
  loading: boolean
  closedOut: boolean
  querierHasWriteAccess: boolean
  planUid: string
}) => {
  const baseUrl = `${Paths._introductions}/${planUid}/contacts`

  return (
    <Widget>
      <Heading
        underlined
        title="Identified people"
        count={contacts.length || -1}
        icon={['far', 'users']}
        link={baseUrl}
        action={
          !loading && !contacts.length && !closedOut && querierHasWriteAccess ? (
            <Link to={`${baseUrl}/add`}>
              <HeadingButton title="Add" />
            </Link>
          ) : null
        }
      />
      {(!!contacts.length || loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 3, loading }}
          items={contacts.slice(0, 3).map((contact) => ({
            name: contact.displayAs,
            userKey: contact.contactEmail,
            link: `${baseUrl}/${contact.contactEmail}`
          }))}
        />
      )}
      {!contacts.length && !loading && (
        <Empty subTitle="No contacts" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} />
      )}
    </Widget>
  )
}

const OutcomeWidget = ({
  loading,
  isCreator,
  querierIsLtnManager,
  planUid,
  editorUserKey,
  usd,
  memo,
  rating,
  date
}: {
  loading: boolean
  querierIsLtnManager: boolean
  isCreator: boolean
  planUid: string
  editorUserKey?: string
  usd?: number
  memo?: string
  rating?: number
  date?: string
}) => {
  const { classes } = useStyles()
  return (
    <Widget>
      <Heading
        underlined
        title="Request outcome"
        count={-1}
        icon={['far', 'sigma']}
        action={
          querierIsLtnManager || isCreator ? (
            <Link to={`${Paths._introductions}/${planUid}/outcome`}>
              <HeadingButton title={editorUserKey ? 'Edit' : 'Add'} />
            </Link>
          ) : null
        }
      />
      {!!rating && (
        <Box mb={1.5}>
          <StatusChip status={rating === -1 ? 'failed' : 'successful'} />
        </Box>
      )}
      {(editorUserKey || loading) && (
        <Skeleton condition={!editorUserKey} height={24}>
          <Box display="flex" alignItems="center" mb={1.5}>
            <FontAwesomeIcon icon={['fas', 'user-tie']} className={classes.icon} />
            <Typography>{editorUserKey || 'Long placeholder'}</Typography>
          </Box>
        </Skeleton>
      )}
      {!!usd && (
        <Box display="flex" alignItems="center" mb={1.5}>
          <FontAwesomeIcon icon={['fas', 'dollar-sign']} className={classes.icon} />
          <Typography>{formatNumberWithSuffix(usd)}</Typography>
        </Box>
      )}
      {(memo || loading) && (
        <Skeleton condition={!memo} height={24}>
          <Box display="flex" alignItems="center" mb={1.5}>
            <FontAwesomeIcon icon={['fas', 'file']} className={classes.icon} />
            <Popover placement="top-start" triggerElement={<Typography>{ellipsisText(memo || 'Long placeholder', 75)}</Typography>}>
              <Box maxHeight="50vh" overflow="auto" maxWidth={500}>
                <Typography variant="h4" semiBold>
                  Memo
                </Typography>
                <Box pt={1}>
                  <Typography>{memo}</Typography>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Skeleton>
      )}
      {(date || loading) && (
        <Skeleton condition={!date} height={24}>
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={['far', 'calendar-alt']} className={classes.icon} />
            <Typography>{formatDate(date) || 'Placeholder'}</Typography>
          </Box>
        </Skeleton>
      )}

      {!loading && !editorUserKey && (
        <Empty subTitle="No outcome" icon={<FontAwesomeIcon size="3x" icon={['fat', 'sigma']} style={{ color: '#A7A7A7' }} />} />
      )}
    </Widget>
  )
}

export default RequestDetails
