import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'
import Topbar from '_core/components/Topbar'

import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type PageProps = {
  getCompanyDetails: (name: string, website?: string) => CompanyMarketDataType | undefined
}

const SimilarCompanyPage = ({ getCompanyDetails }: PageProps) => {
  const { similarId: name } = useParams<{ similarId: string }>()
  const { queryParams } = useSearchQuery<{ website?: string }>()
  const { website } = queryParams
  const { marketData, getMarketData, setMarketData } = useCompanyMarketData(name, website)
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader(name)
  }, [name, setMobileHeader])

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  useEffect(() => {
    if (!marketData && name) {
      const details = getCompanyDetails(name, website)
      if (details) {
        setMarketData(details)
      } else {
        getMarketData()
      }
    }
  }, [name, website, marketData, getMarketData])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <CompanyMarketData company={marketData} />
      </Narrow>
    </Page>
  )
}

export default SimilarCompanyPage
