import React, { useContext, useEffect } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { Route, Switch, useParams } from 'react-router-dom'

import AddRequestContactsPage from '_pages/introductions/[id]/contacts/add'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'

import TargetedContacts from '_core/components/introductions/TargetedContacts'
import { Narrow } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'
import Topbar from '_core/components/Topbar'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { useStyles } from '_core/hooks/useIntroductionRequestForm'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const baseUrl = `${Paths._introductions}/:id/contacts`

const RequestContactsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, result: request } = useAbortableFetch<IntroductionRequestResp>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { classes } = useStyles()

  useEffect(() => {
    fetchWithAbort({ url: `/prospecting/plandetail?planUid=${id}` })
  }, [id, fetchWithAbort])

  const planSummary = request?.plan?.planSummary || ''

  useEffect(() => {
    setMobileHeader(planSummary, !planSummary)
  }, [setMobileHeader, planSummary])

  const accessAddProps = {
    querierIsLtnManager: request ? request.querierIsLtnManager : false,
    querierHasWriteAccess: request ? request.querierHasWriteAccess : false,
    closedOut: request ? !!request.plan.closedOut : false,
    planUid: request ? request.plan.planUid : ''
  }

  return (
    <Switch>
      <Route exact path={`${baseUrl}/add`}>
        <DynamicEntity<{ extraProps: { addprops: RequestAccessType } }>
          url={`/prospecting/targetedcontacts?PlanUids=${id}&Take=10`}
          component={AddRequestContactsPage}
          addprops={accessAddProps}
          infiniteLoader={
            <Box display="flex" justifyContent="center" className={classes.infiniteLoader}>
              <CircularProgress color="secondary" />
            </Box>
          }
          id="targeted-contacts-add"
          keepMounted
          infinite
          list
        />
      </Route>
      <Route exact path={baseUrl}>
        <DynamicEntity<{ extraProps: { addprops: RequestAccessType } }>
          url={`/prospecting/targetedcontacts?PlanUids=${id}&Take=10&AllIntroducerStatuses=true&ClosedFilter=All`}
          component={ContactsPage}
          addprops={accessAddProps}
          id="targeted-contacts"
          keepMounted
          infinite
          list
        />
      </Route>
    </Switch>
  )
}

type PageProps = {
  items: IntroductionContactResp[]
  loading: boolean
  total: number
} & RequestAccessType

const ContactsPage = (props: PageProps) => {
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, result: statuses = [] } = useAbortableFetch<{ id: number; label: string; value: string }[]>()

  useEffect(() => {
    fetchWithAbort({ url: '/prospecting/introducerstatuses' })
  }, [fetchWithAbort])

  const Action = (
    <SidepanelLink linkProps={{ to: `${Paths._introductions}/${id}/contacts/add` }}>
      <IconButton icon={['far', 'edit']} hint="Edit contacts list" color="primary" size="small" />
    </SidepanelLink>
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack action={props.querierHasWriteAccess && !props.closedOut ? Action : null} />
        <TargetedContacts
          total={props.total}
          loading={props.loading}
          items={props.items}
          querierHasWriteAccess={props.querierHasWriteAccess}
          closedOut={props.closedOut}
          statuses={statuses}
        />
      </Narrow>
    </Page>
  )
}

export default RequestContactsPage
