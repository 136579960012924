import { ReactNode } from 'react'

import { Box, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { GeneralSettingsData } from '_core/context/GeneralSettings'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import CopyButton from '_core/components/CopyButton'
import Heading from '_core/components/Heading'
import { Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

import { formatDateTime } from 'utils/Utils'

const useStyles = makeStyles()(() => ({
  externalIcon: {
    fontSize: 12
  }
}))

export type ExternalKeysListPropsType = {
  loading: boolean
  total_item_count: number
  items: { CompanyMd5: string; CompanySourceKey: string; LastModifiedDateTime: Date; IdentifierMd5: string; SourceId: string; SourceType: 'Crm' }[]
}

export const ExternalKeysList = (props: ExternalKeysListPropsType) => {
  const theme = useTheme()
  const { result, loading } = useEntityEndpoint<{ results: GeneralSettingsData } | null>('/usersettings/general')
  const { classes } = useStyles()
  const baseCrmUrl = result?.results?.baseCrmUrl
  const load = loading || props.loading

  const getSalesforceLink = (id: string) => {
    const cleanBaseUrl = baseCrmUrl?.endsWith('/') ? baseCrmUrl.slice(0, -1) : baseCrmUrl

    if (cleanBaseUrl) {
      return `${cleanBaseUrl}/account/${id}/view`
    }
  }

  return (
    <Repeater
      component={TitleDescription}
      variant="list"
      skeleton={{ size: 10, loading: load }}
      addprops={{ padding: `${theme.spacing(2)} !important` }}
      items={props.items.map((key) => {
        const externalLink = getSalesforceLink(key.SourceId)
        return {
          title: '',
          description: (
            <Box display="grid" gridTemplateColumns="1fr auto" alignItems="space-between">
              <Skeleton condition={loading}>
                <Typography noWrap>{key.SourceId}</Typography>
              </Skeleton>
              <Box display="flex" alignItems="center">
                {externalLink && (
                  <IconButton<typeof Link>
                    disablePY
                    hint={`Go to ${externalLink}`}
                    icon={['far', 'external-link']}
                    component={Link}
                    to={{ pathname: externalLink }}
                    target="_blank"
                    classes={{ root: classes.externalIcon }}
                    size="small"
                  />
                )}
                <CopyButton text={key.SourceId} disablePR disablePY />
              </Box>
            </Box>
          ),
          blurb: key.LastModifiedDateTime ? formatDateTime(key.LastModifiedDateTime) : 'No date'
        }
      })}
    />
  )
}

const ExternalKeysPage = ({ children }: { children: ReactNode }) => {
  return (
    <Page variant="core">
      <Wide>
        <Heading title="External keys" icon={['fas', 'share-alt']} variant="main" />
      </Wide>

      {children}
    </Page>
  )
}

export default ExternalKeysPage
