import React, { useContext, useEffect, useMemo } from 'react'

import { Box, Tab, Tabs } from '@mui/material'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import SyncSettings from '_core/components/SqlSync'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useTabs from '_core/hooks/useTabs'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  heading: {
    width: '100%',
    zIndex: 1,
    marginBottom: theme.spacing(2),
    background: theme.palette.background.light,
    [theme.breakpoints.up('md')]: {
      background: 'inherit',
      position: 'inherit'
    }
  }
}))

const baseUrl = DataAdminSubPaths.sync

const tabs = [
  {
    label: 'Settings',
    routesMatch: [`${baseUrl}/settings`]
  }
]

const Heading = () => {
  const { tabIndex } = useTabs(tabs)
  const { classes } = useStyles()

  const TabsEl = (
    <Tabs value={tabIndex > -1 ? tabIndex : false} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
      {tabs.map(({ routesMatch, label }, idx) => (
        <Tab key={idx} component={Link} to={routesMatch[0]} label={label} />
      ))}
    </Tabs>
  )

  return (
    <>
      <Wide>
        <Typography variant="h1" className={classes.heading}>
          Sync
        </Typography>
        {TabsEl}
      </Wide>
      <Narrow>
        <Topbar nativeBack={Paths._dataAdmin} />
        <Widget>
          <Box mb={-2}>{TabsEl}</Box>
        </Widget>
      </Narrow>
    </>
  )
}

const SyncPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Sync')
  }, [setMobileHeader])

  const syncUrls = useMemo(
    () => [
      { key: 'settings', url: '/adminsettings/sqlServerSync' },
      { key: 'teams', url: '/teams' }
    ],
    []
  )

  return (
    <Page>
      <Heading />
      <Switch>
        <Route exact path={baseUrl}>
          <Redirect to={`${baseUrl}/settings`} />
        </Route>
        <Route path={`${baseUrl}/settings`} render={() => <DynamicEntity urls={syncUrls} component={SyncSettings} id="sync" />} />
      </Switch>
    </Page>
  )
}

export default SyncPage
