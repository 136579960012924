import { useState } from 'react'

import { transformPersonData } from '_core/hooks/usePersonMarketData'

export type FiltersType = {
  companySize: string
  jobTitles: string[]
  jobTitleRoles: string[]
  jobTitleSubroles: string[]
  jobTitleLevels: string[]
  industries: string[]
  countries: string[]
  locationName?: string[]
  locationRegion?: string[]
  educationSchoolName?: string[]
  skills?: string[]
}

export type PeopleType = {
  data: PeopleMarketDataType[]
  id?: string
  email?: string
  filters?: FiltersType
} & PaginationMarketData

export const transformResponse = (people: PeopleMarketDataRes['data']): PeopleType['data'] => {
  return people.map((person) => ({
    ...transformPersonData(person),
    personMd5: person.personMd5 || '',
    score: person.score || 0
  }))
}

export const useSimilarPeople = () => {
  const [similar, setSimilar] = useState<PeopleType | null>(null)
  const [similarWidget, setSimilarWidget] = useState<PeopleType | null>(null)

  const getPersonDetails = (name: string, email?: string): PeopleMarketDataType | undefined => {
    return [...(similar?.data ? similar.data : []), ...(similarWidget?.data ? similarWidget.data : [])].find(
      (item) => item.fullName === name || (email && item.workEmail === email)
    )
  }

  return {
    similar,
    similarWidget,
    setSimilar,
    setSimilarWidget,
    getPersonDetails
  }
}
