import React, { useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Step } from '_core/context/Onboarding'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import HelpLinkButton from '_core/components/HelpLinkButton'
import Overlay from '_core/components/Overlay'
import Video from '_core/components/Video'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& > div': {
        width: '33%'
      },
      '& > div:not(:last-child)': {
        marginBottom: 0,
        marginRight: theme.spacing(2)
      }
    }
  },
  icon: {
    paddingBottom: theme.spacing(2),
    '& > svg': {
      color: theme.palette.text.secondary
    }
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 170,
    background: `${theme.palette.background.darker} url("/image/demo/introductions.png") no-repeat center`,
    backgroundSize: 'contain',
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(3)
  },
  overlayContent: {
    width: '80vw',
    maxWidth: '800px',
    height: 'auto',
    maxHeight: '70vh',
    '& > div': {
      overflow: 'visible'
    }
  },
  back: {
    position: 'absolute',
    bottom: '-45px',
    right: 34
  }
}))

export const introductionsDemoTarget = 'introductions-demo'

const features: { icon: IconProp; description: string }[] = [
  {
    icon: ['far', 'layer-plus'],
    description:
      'Users can create introduction requests or report introductions for multiple target contacts or companies, and receive help making connections'
  },
  {
    icon: ['far', 'arrow-progress'],
    description: 'Network managers can track and process requests, addressing underlying sensitivities and compliance requirements along the way'
  },
  { icon: ['far', 'chart-column'], description: 'The firm can track monetary, hiring related and other outcomes attributed to its network' }
]

const Features = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      {features.map(({ description, icon }, i) => (
        <Box key={i}>
          <Box className={classes.icon}>
            <FontAwesomeIcon icon={icon} style={{ fontSize: 24 }} />
          </Box>
          <Typography>{description}</Typography>
        </Box>
      ))}
    </Box>
  )
}

const Content = () => {
  const [stepIndex, setStep] = useState(0)
  const { classes } = useStyles()

  const overlay = stepIndex > 0
  const goNextStep = () => setStep((prev) => prev + 1)
  const goBackStep = () => setStep((prev) => prev - 1)

  const OverlayContent = (
    <>
      <Video link="https://player.vimeo.com/video/932667446" />
      <Button variant="text" color="secondary" onClick={goBackStep} className={classes.back}>
        back
      </Button>
    </>
  )

  return (
    <Overlay isOpen={overlay} overlayContent={OverlayContent} className={classes.overlayContent}>
      <Box className={classes.preview}>
        <Video.TriggerEl handleClick={goNextStep} />
      </Box>
      <Features />
    </Overlay>
  )
}

const HeadingActions = (
  <Box display="flex" alignItems="center" justifyContent="flex-end">
    <HelpLinkButton hint="Learn more about introductions" to={{ pathname: 'https://help.dotalign.com' }} disablePadding size="small" />
  </Box>
)

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Introductions',
    content: <Content />,
    headingActions: HeadingActions,
    target: `.${introductionsDemoTarget}`,
    placement: 'center'
  }
]

export default steps
