import React from 'react'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import CompanyData from '_core/components/market-data/CompanyData'
import ReportIncorrectData from '_core/components/market-data/Report'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'
import Widget from '_core/components/Widget'

interface CompanyMarketDataProps {
  company: CompanyMarketDataType | null
}

const CompanyMarketData = ({ company }: CompanyMarketDataProps) => (
  <>
    {(!company || company.id) && (
      <>
        <Widget>
          <UnknownProfileSummary title={company?.name || ''} url={company?.website} loading={!company?.name} />
        </Widget>
        <Widget>
          <Heading underlined title="Details" icon={['fas', 'chart-column']} count={0} />
          <CompanyData data={company} />
        </Widget>
      </>
    )}
    {company && !company.id && (
      <Widget>
        <Empty title="No data found" />
      </Widget>
    )}
    <ReportIncorrectData marketData={JSON.stringify(company)} />
  </>
)

export default CompanyMarketData
