import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Empty from '_core/components/Empty'
import Filters, { Controller } from '_core/components/filters/Search'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Preview from '_core/components/Preview'
import RecentSearches from '_core/components/RecentSearches'
import SearchInput from '_core/components/SearchInput'
import Results from '_core/components/SearchResult'
import Tops from '_core/components/TopPeopleCompanies'
import Widget from '_core/components/Widget'

import useLatestSearchValue from '_core/hooks/useLatestSearchValue'
import useSearchEndpoints from '_core/hooks/useSearchEndpoints'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanel from '_core/hooks/useSidepanel'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles<{ isMarketDataEnabled: boolean }>()((theme, { isMarketDataEnabled }) => ({
  root: {
    background: theme.palette.background.light,
    '& .MuiGrid-container': {
      width: '100%',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${isMarketDataEnabled ? '210px' : '170px'})`
      }
    },
    '& .MuiGrid-item': {
      padding: '0!important',
      height: 'inherit'
    }
  },
  results: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'inherit'
    },
    '& .infinite-scroll-component': {
      overflow: 'initial !important'
    }
  },
  preview: {
    flex: 1,
    borderLeft: '1px solid #f2f2f2'
  },
  searchWidget: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    flexWrap: 'wrap',
    display: 'flex',
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 12
  },
  input: {
    marginRight: theme.spacing(1)
  },
  content: {
    maxHeight: 220
  }
}))

type SearchProps = {
  filtersLoading: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  queryParams: ModifiedSearchPageParams
  isMarketDataEnabled: boolean
}

const DotAlignSearch = ({ filtersLoading, handleChange, queryParams, isMarketDataEnabled }: SearchProps) => {
  const searchWrapperRef = useRef<HTMLDivElement>(null)
  const wide = useWide()
  const [opened, setOpen] = useState<boolean>(wide)
  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)
  const { openUrl } = useSidepanel('preview')
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const controllerRef = useRef<HTMLDivElement | null>(null)
  const { entities = [], keyword } = queryParams
  const { inputValue, inputValueRef } = useLatestSearchValue()
  const { teamNumber } = teamContextValue
  const { classes } = useStyles({ isMarketDataEnabled })
  const companiesActive = entities?.includes('company')
  const peopleActive = entities?.includes('person')

  const topsUrls = useMemo(
    () => [
      { url: `/companies?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=${companiesActive ? (peopleActive ? 5 : 10) : 0}`, key: 'companies' },
      { url: `/people?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=${peopleActive ? (companiesActive ? 5 : 10) : 0}`, key: 'people' }
    ],
    [teamNumber, companiesActive, peopleActive]
  )

  useEffect(() => {
    setMobileHeader('Search')
  }, [setMobileHeader])

  useEffect(() => {
    openUrl('/blank')
  }, [inputValue, entities.length])

  useEffect(() => {
    searchWrapperRef.current && searchWrapperRef.current.scrollTo(0, 0)
  }, [entities.length, inputValue])

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  const onResultsLoading = (loading: boolean) => {
    setContentLoading(loading)
  }

  const Result = (
    <div className={classes.results} id="search_results" ref={searchWrapperRef}>
      {((!inputValue && typeof inputValue === 'string') || (typeof inputValue !== 'string' && !keyword)) && (
        <>
          <Box className={classes.content}>
            <DynamicEntity url={`/savedsearches`} list={true} component={RecentSearches} empty={''} id="recent-searches" />
          </Box>
          <DynamicEntity urls={teamNumber ? topsUrls : null} component={Tops} empty="" id="top" />
        </>
      )}

      {!!(inputValue || (typeof inputValue !== 'string' && keyword)) && <List inputValue={inputValue} onLoading={onResultsLoading} />}
    </div>
  )

  return (
    <>
      <Wide>
        <Box className={classes.root}>
          <SearchInput ref={inputValueRef} placeholder="Search for people and companies" autoFocus disabled={filtersLoading} variant="underlined" />
          <Columns>
            <Column md={3}>
              <Filters handleChange={handleChange} contentLoading={contentLoading} opened={opened} disabled={filtersLoading || contentLoading} />
            </Column>
            <Column md={5.5}>{Result}</Column>
            <Column md={3.5} className={classes.preview}>
              <Preview />
            </Column>
          </Columns>
        </Box>
      </Wide>
      <Narrow>
        <Box height={1}>
          <Widget scope="list" className={classes.searchWidget}>
            <SearchInput
              ref={inputValueRef}
              placeholder="Search for people and companies"
              disabled={filtersLoading}
              className={classes.input}
              variant="collapsed"
              opened
              autoFocus
            />
            <Controller disabled={filtersLoading || contentLoading} opened={opened} toggleOpen={toggleOpen} ref={controllerRef} />
          </Widget>
          <Filters
            handleChange={handleChange}
            contentLoading={contentLoading}
            opened={opened}
            disabled={filtersLoading || contentLoading}
            anchorEl={controllerRef.current}
          />
          {Result}
        </Box>
      </Narrow>
    </>
  )
}

const entitiesMap: { [key in SearchEntities]: Entities } = {
  company: 'companies',
  person: 'people'
}

const List = ({ inputValue, onLoading }: { inputValue?: string; onLoading: (val: boolean) => void }) => {
  const wide = useWide()
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const { entities } = queryParams
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { openUrl, openedUrl } = useSidepanel('preview')

  const urls = useMemo(
    () =>
      entities?.map((e: SearchEntities) => {
        const entity = entitiesMap[e]
        return {
          url: `/${entity}?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=10`,
          key: entity,
          merge: true
        }
      }),
    [teamNumber, JSON.stringify(entities)]
  )

  const { result, loading, more, reload } = useSearchEndpoints(urls, inputValue)
  const dataLength = result ? result.data[0]?.data?.length : 0

  useEffect(() => {
    onLoading(loading)
  }, [loading, onLoading])

  if (!loading && !dataLength) {
    return <Empty title="No results found" subTitle={`No results found for '${inputValue}'`} />
  }

  return (
    <InfiniteScroll
      loading={loading}
      dataLength={dataLength}
      next={more}
      refreshFunction={reload}
      hasMore={result ? result.are_more : false}
      {...(wide && { scrollableTarget: 'search_results' })}
    >
      <Results items={result ? result.data : undefined} loading={loading} openUrl={openUrl} {...(wide && { openedUrl })} />
    </InfiniteScroll>
  )
}

export default DotAlignSearch
